import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';

const AUTHORITY_URL = '/api/authority';

export const initial = {
  authorityGroup: {
    authorGroupSeq: 0,
    groupNm: '',
    groupDc: '',
    groupMemberCount: 0,
  },

  authorityGroupList: [], // 그룹별 멤버수 있는 목록
  authorityGroupMemberList: {
    // 그룹별 멤버 목록
    data: [],
    pageable: {
      totalCount: 0,
      pageNumber: 1,
      pageSize: 10,
      totalPage: 0,
    },
  },
  authorityList: [], // 기본 그룹 목록

  authorityByMenu: {
    // 그룹별 메뉴 목록
    menuSeq: 0, // 메뉴 SEQ
    menuNm: '서비스관리', // 메뉴명
    menuLevel: 0, // 메뉴 레벨
    upperMenuSeq: 0, // 상위 메뉴 SEQ
    inqireAuthor: 0, // 조회권한
    updtAuthor: 0, // 수정 권한
  },
  authorityByMenuList: [],
};
export const initialPageOptions = {
  number: 1,
};

export const groupValidationSchema = yup.object({
  groupNm: yup.string().required('그룸명은 필수 값 입니다.'),
  groupDc: yup.string().required('그룹설명은 필수 값 입니다.'),
});

export function createAuthorityStore(rootStore) {
  return makeAutoObservable({
    authorityGroup: initial.authorityGroup,
    authorityGroupList: initial.authorityGroupList,
    authorityGroupMemberList: initial.authorityGroupMemberList,
    authorityList: initial.authorityList,
    authorityByMenuList: initial.authorityByMenuList,
    pageOptions: initialPageOptions,
    alertMessage: '',
    selectedGroupSeq: null,

    async getAuthorityGroupList() {
      try {
        const { data } = await authApi.get(`${AUTHORITY_URL}`, null, null);
        runInAction(() => {
          this.authorityGroupList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveAuthorityGroup({ groupDc, groupNm }) {
      try {
        await authApi.post(`${AUTHORITY_URL}`, { groupDc, groupNm });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getAuthorityGroupMemberList(authorGroupSeq) {
      try {
        const res = await authApi.get(
          `${AUTHORITY_URL}/group/${authorGroupSeq}`,
          { ...this.pageOptions },
          null,
        );

        runInAction(() => {
          this.authorityGroupMemberList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteAuthorityGroupMember({ memberSeq }) {
      try {
        await authApi.delete(`${AUTHORITY_URL}/group/${memberSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateAuthorityGroupMember({ memberSeq, authorGroupSeq }) {
      try {
        await authApi.patch(`${AUTHORITY_URL}/group/${memberSeq}`, {
          authorGroupSeq,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getAllAuthorityGroupList() {
      try {
        const { data } = await authApi.get(
          `${AUTHORITY_URL}/group/summary`,
          null,
          null,
        );

        runInAction(() => {
          this.authorityList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getAuthorityByMenu(authorSeq) {
      try {
        const { data } = await authApi.get(
          `${AUTHORITY_URL}/menu/${authorSeq}`,
          null,
          null,
        );

        runInAction(() => {
          this.authorityByMenuList = this.transAuthorityByMenuArr(data);
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateAuthorityByMenu(authorSeq, params) {
      try {
        await authApi.patch(`${AUTHORITY_URL}/menu/${authorSeq}`, params);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    setDetailPageOptions(value, init) {
      runInAction(() => {
        this.pageOptions = init
          ? {
              ...initialPageOptions,
              ...value,
            }
          : {
              ...this.pageOptions,
              ...value,
            };
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },

    transAuthorityByMenuArr(authorityByMenuList) {
      const menuArr = [];
      for (let i = 0; i < authorityByMenuList.length; i++) {
        menuArr.push(authorityByMenuList[i]);
        if (authorityByMenuList[i].childMenus.length > 0) {
          for (let j = 0; j < authorityByMenuList[i].childMenus.length; j++) {
            menuArr.push(authorityByMenuList[i].childMenus[j]);
            if (authorityByMenuList[i].childMenus[j].childMenus.length > 0) {
              for (
                let k = 0;
                k < authorityByMenuList[i].childMenus[j].childMenus.length;
                k++
              ) {
                menuArr.push(
                  authorityByMenuList[i].childMenus[j].childMenus[k],
                );
              }
            }
          }
        }
      }
      return menuArr;
    },
  });
}
