import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';

const ITEM_URL = '/api/statistics/item';

export const initial = {
  itemList: {
    data: [],
    pageable: null,
  },
};

export function createItemStore(rootStore) {
  return makeAutoObservable({
    itemList: initial.itemList,

    // 코드값
    categoryList: [], // 카테고리 목록
    customerList: [], // 업체목록
    opetrMemberList: [], // 배정 담당자 목록

    alertMessage: '',

    async getItemList(options) {
      try {
        const res = await authApi.get(ITEM_URL, { ...options }, null);
        runInAction(() => {
          this.itemList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 카테고리 목록
    async getCategoryList() {
      try {
        const { data } = await authApi.get(
          `${ITEM_URL}/support/categoryList`,
          null,
          null,
        );
        runInAction(() => {
          this.categoryList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 업체목록
    async getCustomerList() {
      try {
        const { data } = await authApi.get(
          `${ITEM_URL}/support/customerList`,
          null,
          null,
        );
        runInAction(() => {
          this.customerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 배정 담당자 목록
    async getOpetrMemberList() {
      try {
        const { data } = await authApi.get(
          `${ITEM_URL}/support/opetrMemberList`,
          null,
          null,
        );
        runInAction(() => {
          this.opetrMemberList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
