import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

const ROUTE_URL = '/api/schedule/route';

export const initial = {
  route: {
    enterpriseSeq: null,
    enterpriseNm: null,
    zip: null,
    adres: null,
    detailAdres: null,
    latitude: null,
    longitude: null,
    routeList: [],
  },
};

export function createRouteStore(rootStore) {
  return makeAutoObservable({
    route: initial.route,

    enterpriseList: [],

    alertMessage: '',

    async getRouteList({ year, month, dayOfMonth }) {
      try {
        const { data } = await authApi.get(
          ROUTE_URL,
          {
            year,
            month,
            dayOfMonth,
          },
          null,
        );

        runInAction(() => {
          this.route = data;
        });
        return true;
      } catch (e) {
        runInAction(() => {
          this.alertMessage =
            e?.data?.data?.alertMessage ||
            '문제가 발생했습니다. 잠시 후 다시 시도해주세요.';
        });
        return false;
      }
    },

    async getCenterRouteList(enterpriseSeq, { year, month, dayOfMonth }) {
      try {
        const { data } = await authApi.get(
          `${ROUTE_URL}/center/${enterpriseSeq}`,
          {
            year,
            month,
            dayOfMonth,
          },
          null,
        );

        runInAction(() => {
          this.route = data;
        });
        return true;
      } catch (e) {
        runInAction(() => {
          this.alertMessage =
            e?.data?.data?.alertMessage ||
            '문제가 발생했습니다. 잠시 후 다시 시도해주세요.';
        });
        return false;
      }
    },

    async getEnterpriseList() {
      try {
        const { data } = await authApi.get(
          '/api/schedule/support/enterprise',
          null,
          null,
        );

        runInAction(() => {
          this.enterpriseList = data;
        });

        return true;
      } catch (e) {
        runInAction(() => {
          this.alertMessage =
            e?.data?.data?.alertMessage ||
            '문제가 발생했습니다. 잠시 후 다시 시도해주세요.';
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
