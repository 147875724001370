import { lazy } from 'react';

/**
 * Layout 목록
 */
const TabLayout = lazy(() =>
  import(/* webpackChunkName: "MainLayout" */ '@templates/TabLayout'),
);

/**
 * Routing Page 목록
 */
const GenerativeBbsPage = lazy(() =>
  import(
    /* webpackChunkName: "GenerativeBbsPage" */ '@pages/common/generative-bbs'
  ),
);

const GeneralBoardViewPage = lazy(() =>
  import(
    /* webpackChunkName: "GeneralBoardViewPage" */ '@pages/common/generative-bbs/general/GeneralBoardViewPage'
  ),
);

const GeneralBoardFormPage = lazy(() =>
  import(
    /* webpackChunkName: "GeneralBoardFormPage" */ '@pages/common/generative-bbs/general/GeneralBoardFormPage'
  ),
);

const routes = [
  {
    name: '생성형 게시판',
    path: '/generative-bbs/post/:generativeBbsSeq',
    component: GenerativeBbsPage,
    layout: TabLayout,
    isAuth: true,
    isPc: true,
    isMobile: false,
    isBookmarked: true,
    permissions: [],
  },
  {
    name: '일반형 게시판 상세',
    path: '/generative-bbs/post/:generativeBbsSeq/general/:generativeBbsPostSeq',
    component: GeneralBoardViewPage,
    layout: TabLayout,
    isAuth: true,
    isPc: true,
    isMobile: false,
    isBookmarked: true,
    permissions: [],
  },
  {
    name: '일반형 게시판 등록',
    path: '/generative-bbs/post/:generativeBbsSeq/general/form',
    component: GeneralBoardFormPage,
    layout: TabLayout,
    isAuth: true,
    isPc: true,
    isMobile: false,
    isBookmarked: true,
    permissions: [],
  },
  {
    name: '일반형 게시판 수정',
    path: '/generative-bbs/post/:generativeBbsSeq/general/form/:generativeBbsPostSeq',
    component: GeneralBoardFormPage,
    layout: TabLayout,
    isAuth: true,
    isPc: true,
    isMobile: false,
    isBookmarked: true,
    permissions: [],
  },
];

export default routes;
