import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import { isEmpty } from '@helpers/StringHelper';

const ASSIGNMENT_AREA_URL = '/api/asign/area';

export const initial = {
  areaCenterList: {
    data: [],
    pageable: {
      totalCount: 1,
      pageNumber: 1, // 페이지 번호
      pageSize: 10,
      totalPage: 1,
    },
  },
  areaCenter: {
    areaAsignSeq: 0, // 지역배정번호
    stateCounty: null, // 시, 도
    signgu: null, // 시군구
    enterpriseNm: null, // 대리점(센터)
    nm: null, // 담당자 명
  },
};

export function createAreaStore(rootStore) {
  return makeAutoObservable({
    areaCenter: initial.areaCenter,
    areaCenterList: initial.areaCenterList,
    centerList: null,
    ctprvnList: [],
    sigunguList: [],
    searchMemberList: [],
    assignMemberList: [],

    async getAreaCenterList(options) {
      try {
        const res = await authApi.get(
          ASSIGNMENT_AREA_URL,
          {
            ...options,
          },
          null,
        );
        runInAction(() => {
          this.areaCenterList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        this.alertMessage = alertMessage;
        return false;
      }
    },

    async saveMember(areaAsignSeqList, memberSeq) {
      try {
        await authApi.put(`${ASSIGNMENT_AREA_URL}`, {
          areaAsignSeqList,
          memberSeq,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        this.alertMessage = alertMessage;
        return false;
      }
    },

    async excelUpload(file) {
      try {
        const formData = new FormData();

        formData.append('multipartFile', file);

        await authApi.fileUpload(
            `${ASSIGNMENT_AREA_URL}/upload`,
            formData,
        );
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCenterList() {
      try {
        const { data } = await authApi.get(
          `${ASSIGNMENT_AREA_URL}/support/centerNmList`,
          null,
          null,
        );

        runInAction(() => {
          this.centerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        this.alertMessage = alertMessage;
        return false;
      }
    },

    async getCtprvnList() {
      try {
        const { data } = await authApi.get(
          `${ASSIGNMENT_AREA_URL}/support/ctprvnList`,
          null,
          null,
        );
        runInAction(() => {
          this.ctprvnList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        this.alertMessage = alertMessage;
        return false;
      }
    },

    async getSignguList(ctprvn) {
      try {
        if (!ctprvn) {
          runInAction(() => {
            this.signguList = [];
          });
          return true;
        }
        const { data } = await authApi.get(
            `${ASSIGNMENT_AREA_URL}/support/signguList`,
            { ctprvn },
            null,
        );
        runInAction(() => {
          this.signguList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
          this.sigunguList = [];
        });
        return false;
      }
    },

    async getPageOptionsMember(enterpriseSeq) {
      try {
        if (!isEmpty(enterpriseSeq)) {
          const { data } = await authApi.get(
            `${ASSIGNMENT_AREA_URL}/support/listMember`,
            { enterpriseSeq },
            null,
          );
          runInAction(() => {
            this.searchMemberList = data;
          });
        } else {
          runInAction(() => {
            this.searchMemberList = [];
          });
        }

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        this.alertMessage = alertMessage;
        return false;
      }
    },

    async getAssignMember(enterpriseSeq) {
      try {
        const { data } = await authApi.get(
          `${ASSIGNMENT_AREA_URL}/support/listMember`,
          { enterpriseSeq },
          null,
        );
        runInAction(() => {
          this.assignMemberList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        this.alertMessage = alertMessage;
        return false;
      }
    },
  });
}
