import { lazy } from 'react';

/**
 * Layout 목록
 */
const MainLayout = lazy(() =>
  import(/* webpackChunkName: "MinimalLayout" */ '@templates/MainLayout'),
);

const SimpleLayout = lazy(() =>
  import(/* webpackChunkName: "MinimalLayout" */ '@templates/SimpleLayout'),
);

const TabLayout = lazy(() =>
  import(/* webpackChunkName: "TabLayout" */ '@templates/TabLayout'),
);

const MinimalLayout = lazy(() =>
  import(/* webpackChunkName: "MinimalLayout" */ '@templates/MinimalLayout'),
);

/**
 * Routing Page 목록
 */

// 상담접수(안씀) 페이지
const ConsultationFormPage = lazy(() =>
  import(
    /* webpackChunkName: "ConsultationFormPage" */ '@pages/user/service-info/reception/consultation'
  ),
);

// 상담접수 목록 페이지
const InstallationPage = lazy(() =>
  import(
    /* webpackChunkName: "InstallationPage" */ '@pages/user/service-info/reception/installation'
  ),
);

// 상담접수 폼 페이지
const InstallationFormPage = lazy(() =>
  import(
    /* webpackChunkName: "InstallationListPage" */ '@pages/user/service-info/reception/installation/InstallationListPage'
  ),
);

// 접수내역(설치A/S)
// 미결서비스조회 페이지
const OutStandingListPage = lazy(() =>
  import(
    /* webpackChunkName: "OutStandingListPage" */ '@pages/user/service-info/reception-log/outstanding'
  ),
);

// 서비스 수정 폼 페이지
const ModifyFormPage = lazy(() =>
  import(
    /* webpackChunkName: "ModifyFormPage" */ 'src/pages/user/service-info/reception-log/form/ModifyFormPage'
  ),
);
// 서비스 완료 폼 페이지
const CompleteFormPage = lazy(() =>
  import(
    /* webpackChunkName: "CompleteFormPage" */ 'src/pages/user/service-info/reception-log/form/CompleteFormPage'
  ),
);

// AS처리완료조회 페이지
const CompleteListPage = lazy(() =>
  import(
    /* webpackChunkName: "CompleteListPage" */ '@pages/user/service-info/reception-log/complete'
  ),
);

// 전체접수 조회 페이지
const AllReceptionListPage = lazy(() =>
  import(
    /* webpackChunkName: "AllReceptionListPage" */ '@pages/user/service-info/reception-log/all-reception'
  ),
);

// 고객관리
const CustomerManageListPage = lazy(() =>
  import(
    /* webpackChunkName: "CustomerManageListPage" */ '@pages/user/service-info/customer'
  ),
);

// 접수상세(모바일전용)
const ReceptionLogMobileViewPage = lazy(() =>
  import(
    /* webpackChunkName: "ReceptionLogMobileViewPage" */ '@pages/user/service-info/reception-log/ReceptionLogMobileViewPage'
  ),
);

// 결제완료(모바일전용)
const PaymentResultPage = lazy(() =>
  import(
    /* webpackChunkName: "ReceptionLogMobileViewPage" */ '@pages/user/service-info/reception-log/form/PaymentResultPage'
  ),
);

const routes = [
  {
    name: '상담접수(안씀)',
    path: '/service-info/reception/consultation',
    component: ConsultationFormPage,
    layout: TabLayout,
    isAuth: true,
    isPc: true,
    isMobile: false,
    isBookmarked: true,
    permissions: [],
  },
  {
    name: '상담접수',
    path: '/service-info/reception/installation',
    component: InstallationPage,
    layout: TabLayout,
    isAuth: true,
    isPc: true,
    isMobile: false,
    isBookmarked: true,
    permissions: [],
  },
  {
    name: '상담접수',
    path: '/service-info/reception/installation/form',
    component: InstallationFormPage,
    layout: TabLayout,
    isAuth: true,
    isPc: true,
    isMobile: false,
    isBookmarked: true,
    permissions: [],
  },
  {
    name: '미결서비스조회 페이지',
    path: '/service-info/reception-log/outstanding',
    component: OutStandingListPage,
    layout: TabLayout,
    isAuth: true,
    isPc: true,
    isMobile: false,
    isBookmarked: true,
    permissions: [],
  },
  {
    name: '접수내역(설치A/S) 수정폼',
    path: '/service-info/reception-log/modify/:serviceInfoSeq',
    component: ModifyFormPage,
    layout: MainLayout,
    isAuth: true,
    isPc: true,
    isMobile: false,
    isBookmarked: true,
    permissions: [],
  },
  {
    name: '접수내역(설치A/S) 완료 폼',
    path: '/service-info/reception-log/complete/:serviceInfoSeq',
    component: CompleteFormPage,
    layout: MainLayout,
    isAuth: true,
    isPc: true,
    isMobile: false,
    isBookmarked: true,
    permissions: [],
  },
  {
    name: 'AS처리완료조회 페이지',
    path: '/service-info/reception-log/complete',
    component: CompleteListPage,
    layout: TabLayout,
    isAuth: true,
    isPc: true,
    isMobile: false,
    isBookmarked: true,
    permissions: [],
  },
  {
    name: '전체접수 조회 페이지',
    path: '/service-info/reception-log/all-reception',
    component: AllReceptionListPage,
    layout: TabLayout,
    isAuth: true,
    isPc: true,
    isMobile: false,
    isBookmarked: true,
    permissions: [],
  },
  {
    name: '고객관리',
    path: '/service-info/customer',
    component: CustomerManageListPage,
    layout: MainLayout,
    isAuth: true,
    isPc: true,
    isMobile: false,
    isBookmarked: true,
    permissions: [],
  },

  // 모바일용
  {
    name: '접수내역(설치A/S) 수정폼',
    path: '/service-info/reception-log/all-reception/modify/:serviceInfoSeq',
    component: ModifyFormPage,
    layout: SimpleLayout,
    isAuth: true,
    isPc: false,
    isMobile: true,
    isBookmarked: false,
    permissions: [],
  },
  {
    name: '접수내역(설치A/S) 완료 폼',
    path: '/service-info/reception-log/all-reception/complete/:serviceInfoSeq',
    component: CompleteFormPage,
    layout: SimpleLayout,
    isAuth: true,
    isPc: false,
    isMobile: true,
    isBookmarked: false,
    permissions: [],
  },
  {
    name: 'AS처리완료조회 페이지',
    path: '/service-info/reception-log/complete',
    component: CompleteListPage,
    layout: MainLayout,
    isAuth: true,
    isPc: false,
    isMobile: true,
    isBookmarked: false,
    permissions: [],
  },
  {
    name: '전체접수 조회 페이지',
    path: '/service-info/reception-log/all-reception',
    component: AllReceptionListPage,
    layout: MainLayout,
    isAuth: true,
    isPc: false,
    isMobile: true,
    isBookmarked: false,
    permissions: [],
  },
  {
    name: '완료조회 페이지 상세',
    path: '/service-info/reception-log/complete/view/:serviceInfoSeq',
    component: ReceptionLogMobileViewPage,
    layout: MainLayout,
    isAuth: true,
    isPc: false,
    isMobile: true,
    isBookmarked: false,
    permissions: [],
  },
  {
    name: '전체접수 조회 페이지 상세',
    path: '/service-info/reception-log/all-reception/view/:serviceInfoSeq',
    component: ReceptionLogMobileViewPage,
    layout: MainLayout,
    isAuth: true,
    isPc: false,
    isMobile: true,
    isBookmarked: false,
    permissions: [],
  },
  {
    name: '결제완료페이지',
    path: '/service-info/reception-log/all-reception/payment/:serviceInfoSeq',
    component: PaymentResultPage,
    layout: MinimalLayout,
    isAuth: true,
    isPc: false,
    isMobile: true,
    isBookmarked: false,
    permissions: [],
  },
];

export default routes;
