import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';

export const initial = {
  generateBoardList: [],

  generateBoard: {
    generativeBbsSeq: 1,
    generativeBbsType: '',
    subject: '',
    postCount: 1,
    commentCount: 8,
    regDt: '',
    useAt: 0,
  },
};

export const generateBoardValidationSchema = yup.object({
  subject: yup.string().required('게시판명은 필수 값 입니다.'),
  description: yup.string().required('설명은 필수 값 입니다.'),
  generativeBbsType: yup.string().required('유형은 필수 선택 값 입니다.'),
  isAnonymous: yup
    .string()
    .when('generativeBbsType', (generativeBbsType, schema) =>
      generativeBbsType == 'FEED'
        ? schema.required('익명설정은 여부는 필수 선택 값 입니다.')
        : schema,
    ),
  // isShare: yup
  //   .string()
  //   .when('generativeBbsType', (generativeBbsType, schema) =>
  //     generativeBbsType == 'FEED'
  //       ? schema.required('공유 여부는 필수 선택 값 입니다.')
  //       : schema,
  //   ),
  isPublic: yup
    .string()
    .when('generativeBbsType', (generativeBbsType, schema) =>
      generativeBbsType == 'FEED'
        ? schema.required('공개 여부는 필수 선택 값 입니다.')
        : schema,
    ),
  isComment: yup.string().required('댓글 여부는 필수 선택 값 입니다.'),
  isLike: yup
    .string()
    .when('generativeBbsType', (generativeBbsType, schema) =>
      generativeBbsType == 'FEED'
        ? schema.required('좋아요 여부는 필수 선택 값 입니다.')
        : schema,
    ),
});

export function createGenerateBoardStore(rootStore) {
  return makeAutoObservable({
    generateBoardList: initial.generateBoardList,
    generateBoard: initial.generateBoard,

    alertMessage: '',

    async getGenerateBoardList() {
      try {
        const { data } = await authApi.get('/api/generative-bbs', null, null);

        runInAction(() => {
          this.generateBoardList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveGenerateBoard({
      description,
      generativeBbsType,
      isAnonymous,
      isComment,
      isLike,
      isPublic,
      isShare,
      subject,
    }) {
      try {
        await authApi.post('/api/generative-bbs', {
          description,
          generativeBbsType,
          isAnonymous,
          isComment,
          isLike,
          isPublic,
          isShare,
          subject,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateGenerateBoard(generativeBbsSeq, { useAt }) {
      try {
        await authApi.patch(`/api/generative-bbs/${generativeBbsSeq}`, {
          useAt,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
