import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

import * as yup from 'yup';

const PUSH_ALARM_URL = '/api/alarm/push';

export const initial = {
  pushAlarmList: {
    data: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },

  pushAlarm: {
    alarmSeq: 0,

    groupSeCode: null,
    sndngPrventSeq: null,
    sndngTrgetMemberSeq: null,
    sndngSe: null,
    sndngDateTime: null,
    sndngDate: null,
    sndngTime: null,
    sj: '',
    cn: '',

    sndngDt: '',
    sndngPrventNm: '',
    sndngTrgetMemberNm: '',
  },
};

export const pushAlarmValidationSchema = yup.object({
  sj: yup.string().required('제목은 필수 입력 항목 입니다.'),
  cn: yup.string().required('내용은 필수 입력 항목 입니다.'),
});

export function createPushAlarmStore() {
  return makeAutoObservable({
    pushAlarmList: initial.pushAlarmList,
    alarm: initial.pushAlarm,

    // 코드값
    headOfficeList: [],
    centerList: [],
    customerList: [],
    engineerList: [],

    sndngTargetList: null, // 발송대상 목록

    alertMessage: '',

    async getAlarmList(options) {
      try {
        const res = await authApi.get(PUSH_ALARM_URL, { ...options }, null);

        runInAction(() => {
          this.pushAlarmList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getAlarm(alarmSeq) {
      try {
        const { data } = await authApi.get(
          `${PUSH_ALARM_URL}/${alarmSeq}`,
          null,
          null,
        );
        runInAction(() => {
          this.alarm = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async sendAlarm({
      cn, // 내용
      groupSeCode, // 그룹구분코드(1:전체, 2:본사, 3:고객사, 4:대리점(센터), 5:엔지니어)
      sj, // 제목
      sndngDate, // 발송날짜(날짜)
      sndngSe, // 발송시기(구분) (1:즉시, 2:예약)
      sndngTime, // 발송날짜(시간)
      sndngTargetRequests, // 발송대상
    }) {
      try {
        await authApi.post(PUSH_ALARM_URL, {
          cn,
          groupSeCode,
          sj,
          sndngDate,
          sndngSe,
          sndngTime,
          sndngTargetRequests,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getSdndngTarget(groupSeCode) {
      try {
        const { data } = await authApi.get(
          `${PUSH_ALARM_URL}/support/sndng-target`,
          {
            groupSeCode,
          },
        );

        runInAction(() => {
          this.sndngTargetList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 본사 직원
    async getHeadOffice() {
      try {
        const { data } = await authApi.get(
          `${PUSH_ALARM_URL}/support/head-office`,
          null,
          null,
        );

        runInAction(() => {
          this.headOfficeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCenterList() {
      try {
        const { data } = await authApi.get(
          `${PUSH_ALARM_URL}/support/center`,
          null,
          null,
        );

        runInAction(() => {
          this.centerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCustomerList() {
      try {
        const { data } = await authApi.get(
          `${PUSH_ALARM_URL}/support/customer`,
          null,
          null,
        );

        runInAction(() => {
          this.customerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getEngineerList(centerSeq) {
      try {
        const { data } = await authApi.get(
          `${PUSH_ALARM_URL}/support/engineer`,
          { centerSeq },
          null,
        );

        runInAction(() => {
          this.engineerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
