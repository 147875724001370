import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

import * as yup from 'yup';
import { isEmpty, onlyNumber } from '@helpers/StringHelper';

const INSTALLATION_URL = '/api/service-info/reception/installation';

export const initial = {
  installationList: {
    data: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },

  consultation: {
    customerNm: '', // 고객명
    cttpc: '', // 연락처
    zip: '', // 우편번호
    adres: '', // 주소
    detailAdres: '', // 상세주소
    signguCode: 0, // 시군구코드
    legaldongCode: 0, // 법정동코드
    rceptDt: '', // 접수일자
    rceptSe: '',
    setleSe: '2', // 결제구분
    setleAmount: '', // 결제금액
    categorySeq: '', // 카테고리 코드
    supplierSeq: '', // 업체

    // 수량 추가 필요

    // 관할대리점(센터) 필드 추가 필요
    inqryRceptCn: '', // 상세내용
    cnsltRepairCn: '',
    partclrMatter: '', // 특이사항
    cmptncEnterpriseSeq: '',
    opetrMemberSeq: '', // 처리자
    resveDt: '', // 예약일자
    resveTime: '', //예약시간sss

    serialNo: '',
    purchsDt: '', // 구입일자
    qy: '',
    imageFile: '',
    modelList: [],
    imageList: [],

    itemSeqs: [],
    useQys: [],
    modelNm: '',

    rceptTyCode: '', // 접수유형
    defectSymptmsCode: '', // 고장증상
  },

  modelList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  pageOptions: {
    enterpriseSeq: null, // 센터 번호
    searchAddr: null, // 주소검색
    number: 1,
  },

  modelOptions: {
    categorySeq: null,
    brandCodeSeq: null,
    enterpriseSeq: null,
  },
};

export const installationValidationSchema = yup.object({
  customerNm: yup.string().required('고객명은 필수 입력 값 입니다.'),
  cttpc: yup
    .string()
    .test({
      message: '연락처를 확인해주세요.',
      test: (value) => {
        if (!onlyNumber(value.replaceAll('-', ''))) {
          return false;
        }
        return true;
      },
    })
    .required('연락처는 필수 입력 값 입니다.'),
  zip: yup.string().required('주소는 필수 입력 값 입니다.'),
  adres: yup.string().required('주소는 필수 입력 값 입니다.'),
  detailAdres: yup.string().required('주소는 필수 입력 값 입니다.'),
  rceptSe: yup.string().required('접수구분은 필수 선택 값 입니다.'),
  // setleSe: yup.string().required('결제 구분은 필수 선택 값 입니다.'),
  setleAmount: yup.string().when('setleSe', (setleSe = '', schema) => {
    if (setleSe == '2') {
      return yup
        .number()
        .min(1, '현장결제 경우 결제 비용은 필수 입력 사항입니다.')
        .required('현장결제 경우 결제 비용은 필수 입력 사항입니다.');
    }
    return schema.nullable();
  }),
  // categorySeq: yup.string().required('카테고리는 필수 선택 값 입니다.'),
  // supplierSeq: yup.string().required('업체는 필수 선택 값 입니다.'),
  modelNm: yup.string().when(['itemSeqs', 'addModel'], (values, schema) => {
    const [itemSeqs, addModel] = values;
    if (itemSeqs.length === 0 && (!addModel?.itemSeq || !addModel?.useQy)) {
      return yup.string().required('모델을 하나 이상 추가 해주세요.');
    }
    return schema.nullable();
  }),
  // serialNo: yup.string().required('시리얼넘버는 필수 입력 값 입니다.'),
  // purchsDt: yup.string().required('구입일자는 필수 선택 값 입니다.'),
  // inqryRceptCn: yup.string().required('상세내용은 필수 입력 값 입니다.'),
  // partclrMatter: yup.string().required('특이사항은 필수 입력 값 입니다.'),
  // cmptncEnterpriseSeq: yup
  //   .string()
  //   .required('관할 대리점(센터)는 필수 선택 값 입니다.'),
  opetrMemberSeq: yup
    .string()
    .when(['cmptncEnterpriseSeq'], (values, schema) => {
      const [cmptncEnterpriseSeq] = values;
      if (!isEmpty(cmptncEnterpriseSeq)) {
        return yup
          .string()
          .required('처리센터 선택시 처리자는 필수 선택 값 입니다.');
      }
      return schema.nullable();
    }),
  // itemSeqs: yup
  //   .array()
  //   .of(yup.object().required('모델명은 필수 선택 값 입니다.')),
  // useQys: yup
  //   .array()
  //   .of(
  //     yup
  //       .number()
  //       .required('수량은 필수 입력 값 입니다.')
  //       .min(1, '최소 1개 이상 입력해주세요'),
  //   ),
  // imageFile: yup.string().when('imageList', (imageList, schema) => {
  //   if (imageList[0].length === 0) {
  //     return yup.string().required('이미지 파일을 하나 이상 추가 해주세요.');
  //   }
  //
  //   return schema.nullable();
  // }),
  // defectSymptmsCode: yup.string().required('고장증상은 필수 선택 값 입니다.'),
  rceptTyCode: yup.string().required('접수유형은 필수 선택 값 입니다.'),
});

export const installationValidationAlert = (values) => {
  let error = {};

  if (isEmpty(values.customerNm) || values.customerNm === '') {
    error.customerNm = '고객명은 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.cttpc) || values.cttpc === '') {
    error.cttpc = '연락처는 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.zip) || values.zip === '') {
    error.zip = '주소는 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.adres) || values.adres === '') {
    error.adres = '주소는 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.detailAdres) || values.detailAdres === '') {
    error.detailAdres = '주소는 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.rceptSe) || values.rceptSe === '') {
    error.rceptSe = '접수구분은 필수 선택 값 입니다.';
    return error;
  }

  if (isEmpty(values.rceptTyCode) || values.rceptTyCode === '') {
    error.rceptTyCode = '접수유형은 필수 선택 값 입니다.';
    return error;
  }

  // if (isEmpty(values.setleSe) || values.setleSe === '') {
  //   error.setleSe = '결제 구분은 필수 선택 값 입니다.';
  //   return error;
  // }

  const {
    itemSeqs,
    addModel,
    modelNm,
    setleSe,
    setleAmount,
    cmptncEnterpriseSeq,
    opetrMemberSeq,
  } = values;

  if (setleSe === '2') {
    if (!setleAmount || Number(setleAmount) < 1) {
      error.setleAmount = '현장결제 경우 결제 비용은 필수 입력 사항입니다.';
      return error;
    }
  }

  if (itemSeqs.length === 0 && (!addModel?.itemSeq || !addModel?.useQy)) {
    if (!modelNm) {
      error.modelNm = '모델을 하나 이상 추가 해주세요.';
      return error;
    }
  }

  if (cmptncEnterpriseSeq && cmptncEnterpriseSeq.length > 0) {
    if (!opetrMemberSeq) {
      error.opetrMemberSeq = '처리센터 선택시 처리자는 필수 선택 값 입니다.';
    }
  }

  return error;
};

export function createInstallationStore(root) {
  return makeAutoObservable({
    installationList: initial.installationList,

    consultation: initial.consultation,

    searchCustomerList: [], // 고객 조회

    searchServiceInfoList: [], // 서비스이력 조회
    serviceLogList: [], // 서비스 이력
    consultLogList: [], //상담 내역

    searchInfo: null, // 등록정보 조회

    modelList: initial.modelList, // 모델명 조회

    // 코드
    centerList: [], // 센터 목록

    categoryCodeList: [], // 카테고리 목록
    brandCodeList: [], // 브랜드 목록
    customerList: [], // 고객사 목록
    opetrMemberList: [], // 처리자 목록
    defectSymptmsCodeList: [], // 고장증상 목록
    rceptTyCodeList: [], // 접수유형 목록

    searchModelList: [], // 모델

    reserveTimeList: [],

    pageOptions: initial.pageOptions,
    modelOptions: initial.modelOptions,

    alertMessage: '',

    async getInstallationList() {
      try {
        const res = await authApi.get(
          INSTALLATION_URL,
          { ...this.pageOptions },
          null,
        );
        runInAction(() => {
          this.installationList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getSearchCustomerList({ customerNm, cttpc }) {
      try {
        const { data } = await authApi.get(
          `${INSTALLATION_URL}/searchCustomerList`,
          {
            customerNm,
            cttpc,
          },
          null,
        );
        runInAction(() => {
          this.searchCustomerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 서비스 이력 조회
    async getSearchServiceInfoList({ customerNm, cttpc }) {
      try {
        const { data } = await authApi.get(
          `${INSTALLATION_URL}/searchServiceInfoList`,
          {
            customerNm,
            cttpc,
          },
          null,
        );
        runInAction(() => {
          // serviceSe 1:상담, 2:접수
          this.serviceLogList = data.filter((item) => item.serviceSe === 2);
          this.consultLogList = data.filter((item) => item.serviceSe === 1);
          this.searchServiceInfoList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getConsultation({ serviceInfoSeq }) {
      try {
        const { data } = await authApi.get(
          `${INSTALLATION_URL}/${serviceInfoSeq}`,
          null,
          null,
        );
        runInAction(() => {
          this.consultation = {
            ...initial.consultation,
            ...data,
            itemSeqs: data.modelList.map((item) => ({
              itemSeq: item.itemSeq,
              modelNm: item.modelNm,
            })),
            useQys: data.modelList.map((item) => item.useQy),
            resveDt: null,
            imageList: [],
          };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveConsultation(params, deleteFileSeq) {
      try {
        console.log('saveConsultation: ', { ...params });
        const { data } = await authApi.post(INSTALLATION_URL, {
          ...params,
          resveDt: null,
        });

        const images = params.imageList.filter((file) => !file?.atchmnflSeq);
        if (images.length > 0) {
          await this.fileUpload(data, images);
        }
        if (deleteFileSeq.length > 0) {
          await this.fileDelete(data, deleteFileSeq);
        }
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveMultiConsultation(params) {
      try {
        await authApi.post(`${INSTALLATION_URL}/multi`, params);
        return {
          success: true,
        };
      } catch ({ data }) {
        return data;
      }
    },

    async uploadReceptionExcel(excelFile) {
      try {
        const formData = new FormData();
        formData.append('multipartFile', excelFile);

        await authApi.fileUpload(`${INSTALLATION_URL}/upload`, formData);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getSearchInfo(legaldongCode, signguCode) {
      try {
        const { data } = await authApi.get(
          `${INSTALLATION_URL}/searchInfo`,
          {
            legaldongCode,
            signguCode,
          },
          null,
        );
        runInAction(() => {
          this.searchInfo = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getSearchModelList(enterpriseSeq) {
      try {
        const { data } = await authApi.get(
          `${INSTALLATION_URL}/searchModelList`,
          {
            enterpriseSeq,
          },
          null,
        );
        runInAction(() => {
          this.searchModelList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 코드 조회
    async getCenterList() {
      try {
        const { data } = await authApi.get(
          `${INSTALLATION_URL}/support/centerList`,
          null,
          null,
        );
        runInAction(() => {
          this.centerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCategoryCodeList() {
      try {
        const { data } = await authApi.get(
          `${INSTALLATION_URL}/support/categoryCodeList`,
          null,
          null,
        );
        runInAction(() => {
          this.categoryCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getBrandCodeList() {
      try {
        const { data } = await authApi.get(
          `${INSTALLATION_URL}/support/brandCodeList`,
          null,
          null,
        );
        runInAction(() => {
          this.brandCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCustomerList() {
      try {
        const { data } = await authApi.get(
          `${INSTALLATION_URL}/support/customerList`,
          null,
          null,
        );
        runInAction(() => {
          this.customerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getOpetrMemberList(enterpriseSeq) {
      try {
        let params = {};

        if (enterpriseSeq) {
          params = { enterpriseSeq };
        }

        const { data } = await authApi.get(
          `${INSTALLATION_URL}/support/opetrMemberList`,
          params,
          null,
        );
        runInAction(() => {
          this.opetrMemberList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getOpetrMemberResveDtList({ opetrMemberSeq, resveDt }) {
      try {
        const { data } = await authApi.get(
          `${INSTALLATION_URL}/opetrMemberResveDtList`,
          {
            opetrMemberSeq,
            resveDt,
          },
          null,
        );
        runInAction(() => {
          this.reserveTimeList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 고장증상
    async getDefectSymptmsCodeList(itemSeqs) {
      try {
        if (itemSeqs && itemSeqs.length > 0) {
          const { data } = await authApi.get(
            `${INSTALLATION_URL}/support/defectSymptmsCodeList`,
            {
              itemSeq: itemSeqs.join(','),
            },
            null,
          );
          runInAction(() => {
            this.defectSymptmsCodeList = data;
          });
        } else {
          runInAction(() => {
            this.defectSymptmsCodeList = [];
          });
        }
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.defectSymptmsCodeList = [];
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 접수유형
    async getRceptTyCodeList(rceptSe) {
      try {
        if (rceptSe) {
          const { data } = await authApi.get(
            `${INSTALLATION_URL}/support/rceptTyCodeList/${rceptSe}`,
            null,
            null,
          );
          runInAction(() => {
            this.rceptTyCodeList = data;
          });
        } else {
          runInAction(() => {
            this.rceptTyCodeList = [];
          });
        }
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
          this.rceptTyCodeList = [];
        });
        return false;
      }
    },

    setOpetrMemberResveDtList(reserveTime) {
      runInAction(() => {
        this.reserveTimeList = reserveTime;
      });
    },

    async fileUpload(serviceInfoSeq, files) {
      const formData = new FormData();

      files.map((file) => {
        formData.append('multipartFiles', file);
      });
      await authApi.fileUpload(
        `${INSTALLATION_URL}/${serviceInfoSeq}/atchmnfl`,
        formData,
      );
    },

    async fileDelete(serviceInfoSeq, params) {
      await authApi.delete(`${INSTALLATION_URL}/atchmnfl`, {
        atchmnflSeqs: params,
      });
    },

    initialData() {
      runInAction(() => {
        this.consultation = initial.consultation;
        this.searchCustomerList = [];
        this.searchServiceInfoList = [];
        this.serviceLogList = [];
        this.consultLogList = [];
      });
    },

    setPageOptions(value, init) {
      runInAction(() => {
        this.pageOptions = init
          ? {
              ...initial.pageOptions,
              ...value,
            }
          : {
              ...this.pageOptions,
              ...value,
            };
      });
    },

    setModelOptions(value, init) {
      runInAction(() => {
        this.modelOptions = init
          ? {
              ...initial.modelOptions,
              ...value,
            }
          : {
              ...this.modelOptions,
              ...value,
            };
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
