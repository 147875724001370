import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

import * as yup from 'yup';
import { isEmpty, toHtml } from '@helpers/StringHelper';

const NOTICE_URL = '/api/community/notice';

export const initial = {
  noticeList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },
  fixedNoticeList: [],

  notice: {
    bbsSeq: '',
    writerMemberSeq: '',
    isEditable: 0,
    upendFixingAt: 0,
    bbsSj: '',
    bbsCn: '',
    regDt: '',
    atchmnfls: [],
    bbsAnswers: [],
  },
};

export const bbsValidationSchema = yup.object({
  bbsSj: yup.string().required('제목은 필수 입력 값 입니다.'),
  bbsCn: yup.string()
    .required('내용은 필수 입력 값 입니다.')
    .test('is-not-empty', '내용은 필수 입력 값 입니다.', (value) => {
      const isEmptyOrWhitespace = !value || value.trim() === '' || value === '<p><br></p>';
      return !isEmptyOrWhitespace;
    }),
});

export const bbsValidationAlert = (values) => {

  let error = {};

  if (isEmpty(values.bbsSj) || values.bbsSj === '') {
    error.bbsSj = '제목은 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.bbsCn) || values.bbsCn === '' || values.bbsCn.trim() === '' || values.bbsCn === '<p><br></p>') {
    error.bbsCn = '내용은 필수 입력 값 입니다.';
    return error;
  }

  return error;
};

export function createCommunityNoticeStore() {
  return makeAutoObservable({
    list: initial.noticeList,
    fixedList: initial.fixedNoticeList,
    data: initial.notice,

    alertMessage: '',

    async getBbsList(options) {
      try {
        const res = await authApi.get(
          NOTICE_URL,
          {
            ...options,
          },
          null,
        );
        runInAction(() => {
          this.list = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getFixedBbsList() {
      try {
        const { data } = await authApi.get(`${NOTICE_URL}/fixed`, null, null);
        runInAction(() => {
          this.fixedList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getBbs(bbsSeq) {
      try {
        const { data } = await authApi.get(
          `${NOTICE_URL}/${bbsSeq}`,
          null,
          null,
        );
        runInAction(() => {
          this.data = {
            ...data,
            bbsCn: toHtml(data.bbsCn),
          };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveBbs(
      { bbsSj, bbsCn, upendFixingAt, url, changeFileNms },
      noticeFile,
    ) {
      try {
        const {
          data: { bbsSeq },
        } = await authApi.post(NOTICE_URL, {
          bbsSj,
          bbsCn,
          upendFixingAt,
          url,
          changeFileNms,
        });

        if (noticeFile.length > 0) {
          await this.bbsFileUpload(bbsSeq, noticeFile);
        }

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteBbs(bbsSeq) {
      try {
        await authApi.delete(`${NOTICE_URL}/${bbsSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateBbs(
      bbsParam,
      { bbsSj, bbsCn, upendFixingAt, url, changeFileNms },
      noticeFiles,
      deleteFileSeq,
    ) {
      try {
        const {
          data: { bbsSeq },
        } = await authApi.patch(`${NOTICE_URL}/${bbsParam}`, {
          bbsSj,
          bbsCn,
          upendFixingAt,
          url,
          changeFileNms,
        });

        if (noticeFiles.length > 0) {
          await this.bbsFileUpload(bbsParam, noticeFiles);
        }

        if (deleteFileSeq.length > 0) {
          await this.bbsFileDelete(bbsParam, deleteFileSeq);
        }

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveBbsComment(bbsSeq, { bbsAnswerCn }) {
      try {
        await authApi.post(`${NOTICE_URL}/${bbsSeq}/answer`, { bbsAnswerCn });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async bbsFileUpload(bbsSeq, noticeFiles) {
      const formData = new FormData();

      // 다중 파일 업로드
      noticeFiles.map((noticeFile) => {
        formData.append('multipartFiles', noticeFile);
      });

      await authApi.fileUploadPost(
        `${NOTICE_URL}/${bbsSeq}/atchmnfl`,
        formData,
      );
    },

    /**
     * 첨부파일 삭제
     * @param bbsSeq
     * @param params
     * @returns {Promise<boolean>}
     */
    async bbsFileDelete(bbsSeq, params) {
      try {
        await authApi.delete(`/api/community/common/bbs/${bbsSeq}/atchmnfl`, {
          atchmnflSeqs: params,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
