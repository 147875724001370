import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';

const CODE_URL = '/api/materialcode/code';

export const initial = {
  parentCodeList: [],
  childCodeList: [],
  code: {
    codeSeq: null,
    codeId: null,
    codeNm: '',
    parntsCodeSeq: null,
    useAt: null,
  },
};

export const codeValidationSchema = yup.object({
  // 제품명, 브랜드, 카테고리, 공급처, 공급가, 소비자가
  codeNm: yup.string().required('코드명은 필수 값 입니다.'),
});

export function createCodeStore(rootStore) {
  return makeAutoObservable({
    parentCodeList: initial.parentCodeList,
    childCodeList: initial.childCodeList,
    code: initial.code,

    alertMessage: '',

    async getParentCodeList() {
      try {
        const { data } = await authApi.get(CODE_URL, null, null);
        runInAction(() => {
          this.parentCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveParentCode(params) {
      try {
        await authApi.post(CODE_URL, params);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateChildCode(codeSeq, params) {
      try {
        await authApi.patch(`${CODE_URL}/${codeSeq}`, params);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteChildCode(codeSeq) {
      try {
        await authApi.delete(`${CODE_URL}/${codeSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getChildCodeList(parentCodeSeq) {
      try {
        const { data } = await authApi.get(
          `${CODE_URL}/${parentCodeSeq}`,
          null,
          null,
        );
        runInAction(() => {
          this.childCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveChildCode(parentCodeSeq, params) {
      try {
        await authApi.post(`${CODE_URL}/${parentCodeSeq}`, params);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
