import React, {
  lazy,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

import routes from '@router/module';

/**
 * Error 페이지
 */
import Error404Page from '@pages/common/error/Error404Page';
import Error500Page from '@pages/common/error/Error500Page';

/**
 * Utils
 */
import { isMobile } from '@helpers/EnvHelper';
import { getSessionStorage } from '@helpers/StorageHelper';
import { LOGIN_MEMBER_INFO } from '@const/env';
import { useMobxStores } from '@stores/index';

/**
 * Router 목록
 */
const PublicRouter = lazy(() =>
  import(/* webpackChunkName: "PublicRouter" */ '@router/PublicRouter'),
);

const PrivateRouter = lazy(() =>
  import(/* webpackChunkName: "PrivateRouter" */ '@router/PrivateRouter'),
);

/**
 * Component
 */
const Loading = lazy(() =>
  import(/* webpackChunkName: "PrivateRouter" */ '@molecules/common/Loading'),
);

function Router() {
  const mobile = isMobile();
  const { globalStore } = useMobxStores();

  const getInitialData = useCallback(async () => {
    await globalStore.getMenu();
    await globalStore.getBookmarkMenu();
  }, [globalStore.memberInfo]);

  useEffect(() => {
    if (globalStore.memberInfo) {
      getInitialData().then((res) => {});
    }
  }, [globalStore.memberInfo]);

  return (
    <BrowserRouter>
      {/*<Loading/>*/}
      <React.Suspense fallback={<></>}>
        <Routes>
          {routes.map((route, idx) => {
            if (!isMobile() && route.isPc) {
              // PC
              return (
                <Route
                  key={{ idx }}
                  element={route?.isAuth ? <PrivateRouter /> : <PublicRouter />}
                >
                  <Route element={<route.layout menu={globalStore.menuList} />}>
                    <Route path={route.path} element={<route.component />} />
                  </Route>
                </Route>
              );
            }

            if (isMobile() && route.isMobile) {
              // mobile
              return (
                <Route
                  key={{ idx }}
                  element={route?.isAuth ? <PrivateRouter /> : <PublicRouter />}
                >
                  <Route element={<route.layout menu={globalStore.menuList} />}>
                    <Route path={route.path} element={<route.component />} />
                  </Route>
                </Route>
              );
            }
          })}
          <Route path="/*" element={<Error404Page />} />
          <Route path="/error" element={<Error500Page />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default observer(Router);
