import { lazy } from 'react';

/**
 * Layout 목록
 */
const MainLayout = lazy(() =>
  import(/* webpackChunkName: "MainLayout" */ '@templates/MainLayout'),
);

/**
 * Routing Page 목록
 */

// 방문일정리스트
const VisitListPage = lazy(() =>
  import(
    /* webpackChunkName: "VisitListPage" */ 'src/pages/user/schedule/visit'
  ),
);

const VisitMobileViewPage = lazy(() =>
  import(
    /* webpackChunkName: "VisitMobileViewPage" */ 'src/pages/user/schedule/visit/VisitMobileView'
  ),
);

// 캘린더
const CalendarViewPage = lazy(() =>
  import(
    /* webpackChunkName: "CalendarViewPage" */ 'src/pages/user/schedule/calendar'
  ),
);
// 동선보기
const RouteViewPage = lazy(() =>
  import(
    /* webpackChunkName: "RouteViewPage" */ 'src/pages/user/schedule/route'
  ),
);

const getRoutes = () => {
  const routes = [
    {
      name: '방문일정리스트',
      path: '/schedule/visit',
      component: VisitListPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '방문일정리스트-상세',
      path: '/schedule/visit/view/:serviceInfoSeq',
      component: VisitMobileViewPage,
      layout: MainLayout,
      isAuth: true,
      isPc: false,
      isMobile: true,
      isBookmarked: false,
      permissions: [],
    },
    {
      name: '캘린더',
      path: '/schedule/calendar',
      component: CalendarViewPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '동선보기',
      path: '/schedule/route',
      component: RouteViewPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
  ];

  return routes;
};

export default getRoutes();
