import { replace as _replace, isEmpty as _isEmpty } from 'lodash';

export const isNumber = (str) => {
  return !Number.isNaN(Number(str));
};

export const onlyNumber = (value) => {
  if (!value) {
    return '';
  }
  if (typeof value === 'number') {
    return String(value);
  }
  return value?.replace(/[^0-9.]/g, '')?.replace(/(\..*)\./g, '$1');
};

/**
 * 숫자 3자리수 콤마
 * @param number
 * @returns {string}
 */
export const numberStringWithComma = (value) => {
  if (typeof value === 'string') {
    value = value.replaceAll(',', '');
  }

  if (!isNumber(value) || isEmpty(value) || Number.isNaN(value)) {
    return '0';
  }
  const regx = /\B(?=(\d{3})+(?!\d))/g;
  return value.toString().replace(regx, ',');
};

export const numberStringWithCommaEmptyZero = (value) => {
  if (typeof value === 'string') {
    value = value.replaceAll(',', '');
  }
  value = Number(onlyNumber(value));
  const regx = /\B(?=(\d{3})+(?!\d))/g;
  return value.toString().replace(regx, ',');
};

export const numberStringWithPhone = (value) => {
  return onlyNumber(value).replace(/^(\d{3})(\d{3,4})(\d{4,})$/, `$1-$2-$3`);
};

/**
 * unescape
 * @param str
 * @returns {string}
 */
export const toHtml = (str) => {
  let newStr = str;
  newStr = _replace(newStr, /&amp;/g, '&');
  newStr = _replace(newStr, /&gt;/g, '>');
  newStr = _replace(newStr, /&lt;/g, '<');
  newStr = _replace(newStr, /\n/g, '<br />');
  return newStr;
};

/**
 * str문자열을 maxByte 보다 작은 만큼 잘라서 반환
 * @param str
 * @param maxByte
 * @returns {*}
 */
export const checkByte = (str, maxByte) => {
  const strLength = str.length;

  let rByte = 0;
  let rLength = 0;
  let oneChar = '';
  for (let i = 0; i < strLength; i += 1) {
    oneChar = str.charAt(i);
    if (window.escape(oneChar).length > 4) {
      rByte += 2;
    } else {
      rByte += 1;
    }
    if (rByte <= maxByte) {
      rLength = i + 1;
    }
  }

  let str2 = '';
  if (rByte > maxByte) {
    str2 = str.substring(0, rLength);
    return checkByte(str2, maxByte);
  }
  return str;
};

export const getStringBytes = (str) => {
  let character;
  let charBytes = 0;

  for (let i = 0; i < str.length; i += 1) {
    character = str.charAt(i);

    if (escape(character).length > 4) charBytes += 2;
    else charBytes += 1;
  }

  return charBytes;
}

export const isEmpty = (str) => {
  if (str === undefined || str === null || str === '' || str.length === 0) {
    return true;
  }
  return false;
};

export const isBlank = (str) => {
  if (str === undefined || str === null || str === '' || str.length === 0 || str.trim().length === 0) {
    return true;
  }
  return false;
};


export const getQuerySetUrl = (form, isPaging = true) => {
  let toUrl = isPaging ? '?pageNumber=1' : '?';
  let count = 0;
  for (const key in form) {
    if (!_isEmpty(form[key])) {
      if (!isPaging && count === 0) {
        toUrl += `${key}=${form[key]}`;
      } else if (key !== 'pageNumber') {
        toUrl += `&${key}=${form[key]}`;
      }
      count++;
    }
  }
  return toUrl;
};

export const lpadStr = (value, padLength, padString) => {
  if (typeof value !== 'string') {
    value = String(value);
  }
  return value.padStart(padLength, padString ?? '0');
};

export const rpadStr = (value, padLength, padString) => {
  if (typeof value !== 'string') {
    value = String(value);
  }
  return value.padEnd(padLength, padString ?? '0');
};

export const getSearchForm = (query, filter = false) => {
  let newForm = {};
  for (const key in query) {
    if (filter) {
      if (!_isEmpty(_toString(query[key]))) {
        newForm[key] = query[key];
      }
    } else {
      newForm[key] = query[key];
    }
  }
  return newForm;
};