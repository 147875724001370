import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import { getRandomNumber } from '@helpers/NumberHelper';

const CALENDAR_URL = '/api/schedule/calendar';

export const initial = {
  scheduleList: [],
  schedule: {
    serviceInfoSeq: null,
    resveDate: '',
    resveTime: '',
    customerNm: '',
    rceptSe: null,
    cttpc: '',
    modelNms: '',
    zip: '',
    adres: '',
    detailAdres: '',
    inqryRceptCn: null,
  },
  dayScheduleList: [],
};

// const SCHEDULE_COLOR = ['#faad14', '#52c41a', '#f5222d', '#8c8c8c', '#22336d', '#a5a5a5'];
const SCHEDULE_COLOR = ['#22336d', '#a5a5a5'];

const getScheduleColor = (index) => {
  return SCHEDULE_COLOR[getRandomNumber(0, index % SCHEDULE_COLOR.length)];
};

export function createCalendarStore(rootStore) {
  return makeAutoObservable({
    scheduleList: initial.scheduleList,
    dayScheduleList: initial.dayScheduleList,
    schedule: initial.schedule,

    alertMessage: '',

    async getScheduleList({ year, month }) {
      try {
        const { data } = await authApi.get(
          CALENDAR_URL,
          {
            year,
            month,
          },
          null,
        );

        runInAction(() => {
          this.scheduleList = data.map((schedule, index) => ({
            id: schedule.serviceInfoSeq,
            description: schedule.inqryRceptCn,
            start: `${schedule.resveDate} ${schedule.resveTime}`,
            color: [5,6].includes(schedule.serviceSttus) ? SCHEDULE_COLOR[1] : SCHEDULE_COLOR[0],
            title: schedule.inqryRceptCn,
          }));
        });
        return true;
      } catch (e) {
        runInAction(() => {
          this.alertMessage =
            e?.data?.data?.alertMessage ||
            '문제가 발생했습니다. 잠시 후 다시 시도해주세요.';
        });
        return false;
      }
    },

    async getDaySchedule({ year, month, dayOfMonth }) {
      try {
        const { data } = await authApi.get(
          `${CALENDAR_URL}/date`,
          {
            year,
            month,
            dayOfMonth,
          },
          null,
        );

        runInAction(() => {
          this.scheduleList = data;
        });

        return true;
      } catch (e) {
        runInAction(() => {
          this.alertMessage =
            e?.data?.data?.alertMessage ||
            '문제가 발생했습니다. 잠시 후 다시 시도해주세요.';
        });
        return false;
      }
    },

    async getScheduleInfo(serviceInfoSeq) {
      try {
        const { data } = await authApi.get(
          `${CALENDAR_URL}/${serviceInfoSeq}`,
          null,
          null,
        );

        runInAction(() => {
          this.schedule = data;
        });

        return true;
      } catch (e) {
        runInAction(() => {
          this.alertMessage =
            e?.data?.data?.alertMessage ||
            '문제가 발생했습니다. 잠시 후 다시 시도해주세요.';
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
