import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

const MY_PAGE_ALARM_URL = '/api/mypage/alarm';

export const initial = {
  smsAlarmList: {
    data: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },

  pushAlarmList: {
    data: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },
};

export function createMyPageAlarmStore(rootStore) {
  return makeAutoObservable({
    smsAlarmList: initial.smsAlarmList,
    pushAlarmList: initial.pushAlarmList,

    alertMessage: '',

    async getSmsAlarmList(options) {
      try {
        const res = await authApi.get(
          `${MY_PAGE_ALARM_URL}/sms`,
          { ...options },
          null,
        );

        runInAction(() => {
          this.smsAlarmList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getPushAlarmList(options) {
      try {
        const res = await authApi.get(
          `${MY_PAGE_ALARM_URL}/push`,
          { ...options },
          null,
        );

        runInAction(() => {
          this.pushAlarmList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
