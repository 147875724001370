import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';
import { toHtml } from '@helpers/StringHelper';

const PRODUCT_MANUAL_URL = '/api/community/product-manual';

export const initial = {
  productManualList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  productManual: {
    bbsSeq: '',
    writerMemberSeq: '',
    isEditable: 0,
    upendFixingAt: 0,
    bbsSj: '',
    bbsCn: '',
    regDt: '',
    atchmnfls: [],
    bbsAnswers: [],
    file: '',
    url: '',
    itemSeq: null,
    modelNm: null,
  },

  modelList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  modelOptions: {
    categorySeq: null,
    brandCodeSeq: null,
    searchKeyword: null,
    number: 1,
    modelNm: null,
  },
};

export const productManualValidationSchema = yup.object({
  bbsSj: yup.string().required('제목은 필수 입력 값 입니다.'),
  // url: yup.string().required('URL 필수 입력 값 입니다.'),
  itemSeq: yup.number().required('모델은 필수 선택 값 입니다.'),
  modelNm: yup.string().required('모델은 필수 선택 값 입니다.'),
});

export function createCommunityManualStore() {
  return makeAutoObservable({
    productManualList: initial.productManualList,
    productManual: initial.productManual,

    modelOptions: initial.modelOptions,

    modelList: initial.modelList, // 모델명 조회

    alertMessage: '',

    async getProductManualList(options) {
      try {
        const res = await authApi.get(PRODUCT_MANUAL_URL, { ...options }, null);

        runInAction(() => {
          this.productManualList = res;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getProductManual(bbsSeq) {
      try {
        const { data } = await authApi.get(
          `${PRODUCT_MANUAL_URL}/${bbsSeq}`,
          null,
          null,
        );

        runInAction(() => {
          this.productManual = {
            ...data,
            bbsCn: toHtml(data.bbsCn),
          };
        });

        return true;
      } catch (e) {
        runInAction(() => {
          this.alertMessage =
            e?.data?.data?.alertMessage ??
            '문제가 발생했습니다. 잠시 다시 시도해주세요.';
        });
        return false;
      }
    },

    async saveProductManual(
      { bbsSj, bbsCn, upendFixingAt, url, changeFileNms, itemSeq },
      productManualFile,
    ) {
      try {
        const {
          data: { bbsSeq },
        } = await authApi.post(PRODUCT_MANUAL_URL, {
          bbsSj,
          bbsCn,
          upendFixingAt,
          url,
          changeFileNms,
          itemSeq,
        });

        if (productManualFile) {
          await this.productManualFileUpload(bbsSeq, productManualFile);
        }

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateProductManual(
      bbsSeq,
      { bbsSj, bbsCn, url, changeFileNms, itemSeq },
      productManualFile,
      deleteFileSeq,
    ) {
      try {
        await authApi.patch(`${PRODUCT_MANUAL_URL}/${bbsSeq}`, {
          bbsSj,
          bbsCn,
          upendFixingAt: 0,
          url,
          changeFileNms,
          itemSeq,
        });

        if (deleteFileSeq.length > 0) {
          await this.productManualFileDelete(bbsSeq, deleteFileSeq);
        }

        if (productManualFile && !productManualFile?.atchmnflSeq) {
          await this.productManualFileUpload(bbsSeq, productManualFile);
        }

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteProductManual(bbsSeq) {
      try {
        await authApi.delete(`${PRODUCT_MANUAL_URL}/${bbsSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async productManualFileUpload(bbsSeq, productManualFile) {
      const formData = new FormData();

      formData.append('multipartFiles', productManualFile);

      await authApi.fileUpload(
        `${PRODUCT_MANUAL_URL}/${bbsSeq}/atchmnfl`,
        formData,
      );
    },

    async productManualFileDelete(bbsSeq, params) {
      try {
        await authApi.delete(`/api/community/common/bbs/${bbsSeq}/atchmnfl`, {
          atchmnflSeqs: params,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getSearchModelList() {
      try {
        const res = await authApi.get(
          `${PRODUCT_MANUAL_URL}/support/search`,
          {
            ...this.modelOptions,
          },
          null,
        );
        runInAction(() => {
          this.modelList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    setModelOptions(value, init) {
      runInAction(() => {
        this.modelOptions = init
          ? {
              ...initial.modelOptions,
              ...value,
            }
          : {
              ...this.modelOptions,
              ...value,
            };
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
