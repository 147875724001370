import React from 'react';
import ReactDOM from 'react-dom/client';

// third party
import { StoreProvider } from '@stores/index';

// project imports
import App from './App';

// style + assets + font
import './assets/scss/style.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <>
    <StoreProvider>
      <App />
    </StoreProvider>
  </>,
  // </React.StrictMode>
);
