import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

const ORDER_RETURN_URL = '/api/item/order-return';

export const initial = {
  orderReturnList: {
    data: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },

  orderReturn: {
    orderOfeSeq: '',
    enterpriseNm: '',
    chargerNm: '',
    applcntMemberNm: '',
    reqstTySe: '',
    se: '',
    pblicteDt: '',
    orderDt: '',
    opetrMemberNm: '',
    processDt: '',
    orderOfeSttus: '',
    list: [],
  },
};

// 자재반납관리
export function createMaterialOrderReturnStore(rootStore) {
  return makeAutoObservable({
    orderReturnList: initial.orderReturnList,
    orderReturn: initial.orderReturn,

    applyMemberList: [], // 신청자 목록

    alertMessage: '',

    async getOrderReturnList(options) {
      try {
        const res = await authApi.get(ORDER_RETURN_URL, { ...options }, null);
        runInAction(() => {
          this.orderReturnList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getOrderReturn(orderOfeSeq) {
      try {
        const { data } = await authApi.get(
          `${ORDER_RETURN_URL}/${orderOfeSeq}`,
          null,
          null,
        );

        runInAction(() => {
          this.orderReturn = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateOrderReturn({ orderOfeSeq, orderOfeSttus }) {
      try {
        await authApi.put(`${ORDER_RETURN_URL}/change`, {
          orderOfeSeq,
          orderOfeSttus,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteOrderReturn(orderOfeSeq) {
      try {
        await authApi.delete(`${ORDER_RETURN_URL}/${orderOfeSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getApplyMemberList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_RETURN_URL}/support/applcntMemberList`,
          null,
          null,
        );

        runInAction(() => {
          this.applyMemberList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
