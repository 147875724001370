import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';

const FEE_URL = '/api/statistics/fee';

export const initial = {
  centerList: {
    data: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },

  fee: {
    enterpriseSeq: null,
    ctmmnySe: null, // 고객사 구분(1:고객사, 2:제조사, 3:공급처)
    enterpriseNm: null, // 센터명
    rprsntvNm: null, // 대표자명
    telno: null, // 전화번호
    zip: null, // 우편번호
    adres: null, // 주소
    detailAdres: null, // 상세 주소
    signguCode: null, // 시군구 코드
    legaldongCode: null, // 법정동 코드
    estblDt: null, // 개설 날짜
    endDt: null, // 종료 날짜
    mnfcturEnterpriseSeq: null, // 제조사 업체번호
    evlScore: null, // 평가 점수
    fileList: [], // 첨부파일
    feeCtmmnyList: [], // 제조사별 수수료 리스트
    evaluationFile: null,
  },
};

export function createFeeStore(rootStore) {
  return makeAutoObservable({
    centerList: initial.centerList,
    fee: initial.fee,

    alertMessage: '',

    async getCenterList(options) {
      try {
        const res = await authApi.get(FEE_URL, { ...options }, null);
        runInAction(() => {
          this.centerList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getFee(enterpriseSeq) {
      try {
        const { data } = await authApi.get(
          `${FEE_URL}/${enterpriseSeq}`,
          null,
          null,
        );
        runInAction(() => {
          let file = null;

          if (data.fileList.length > 0) {
            file = {
              atchmnflSeq: data.fileList[0].atchmnflSeq,
              name: data.fileList[0].orginlFileNm,
              fileLink: data.fileList[0].link,
            };
          }

          this.fee = {
            ...data,
            evaluationFile: file,
          };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateFee(enterpriseSeq, params, evaluationFile, deleteFileSeq) {
      try {
        if (evaluationFile) {
          await this.fileUpload(enterpriseSeq, evaluationFile);
        }
        if (deleteFileSeq.length > 0) {
          await this.fileDelete(deleteFileSeq);
        }
        await authApi.put(`${FEE_URL}/${enterpriseSeq}`, { ...params });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async fileUpload(enterpriseSeq, file) {
      const formData = new FormData();
      formData.append('multipartFiles', file);
      await authApi.fileUpload(
        `${FEE_URL}/${enterpriseSeq}/atchmnfl`,
        formData,
      );
    },

    async fileDelete(deleteFileSeq) {
      await authApi.delete(`${FEE_URL}/atchmnfl`, deleteFileSeq);
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
