import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

import * as yup from 'yup';
import { onlyNumber } from '@helpers/StringHelper';
import { MEMBER_ID_REGEX } from '@const/env';

const EMPLOYEE_MANAGE_URL = '/api/employee';

export const initial = {
  employeeList: {
    data: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },

  employee: {
    memberSe: '',
    nm: '',
    id: '',
    password: '',
    moblphonNo: '',
    email: '',
    enterpriseSeq: '',
    enterpriseNm: '',
    ecnyDt: '',
    authorGroupNm: '',
    authorGroupSeq: '',
    actvtySe: 0,
    zip: '',
    adres: '',
    detailAdres: '',
    signguCode: '',
    legaldongCode: '',
    partclrMatter: '',
  },
};

export const employeeValidationSchema = yup.object({
  // 구분, 성명, 아이디, 비밀번호, 핸드폰번호, 소속센터, 소속팀, 담당업무, 입사일, 사용자등급
  memberSe: yup.string().required('구분은 필수 선택 값 입니다.'),
  nm: yup.string().required('이름은 필수 입력 값 입니다.'),
  id: yup
    .string()
    .matches(
      MEMBER_ID_REGEX,
      '아이디는 영어, 숫자 조합 6자리 이상이어야 합니다.',
    )
    .required('아이디는 필수 입력 값 입니다.'),
  moblphonNo: yup
    .string()
    .test({
      message: '핸드폰 번호는 필수 입력 값 입니다.',
      test: (value) => {
        if (!onlyNumber(value.replaceAll('-', ''))) {
          return false;
        }
        return true;
      },
    })
    .required('핸드폰 번호는 필수 입력 값 입니다.'),
  email: yup
    .string()
    .matches(
      /^[0-9A-Z]([-_\.]?[0-9A-Z])*@[0-9A-Z]([-_\.]?[0-9A-Z])*\.[A-Z]{2,6}$/i,
      '이메일 형식을 확인해주세요',
    )
    .nullable(),
  enterpriseSeq: yup.string().required('소속센터는 필수 선택 값 입니다.'),
  ecnyDt: yup.string().required('입사일은 필수 선택 값 입니다.'),
  authorGroupSeq: yup.string().required('사용자등급은 필수 선택 값 입니다.'),
});

export function createEmployeeManageStore() {
  return makeAutoObservable({
    employeeList: initial.employeeList,
    employee: initial.employee,

    // 코드값
    chrgJobList: [], // 담당업무
    psitnTeamList: [], // 소속팀
    clsfList: [], // 직급코드
    enterpriseList: [], // 소속센터
    rspofcList: [], // 직책코드
    authorGroupList: [], // 사용자등급

    alertMessage: '',

    async getEmployeeList(options) {
      try {
        const res = await authApi.get(
          `${EMPLOYEE_MANAGE_URL}`,
          { ...options },
          null,
        );
        runInAction(() => {
          this.employeeList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getEmployee(memberSeq) {
      try {
        const { data } = await authApi.get(
          `${EMPLOYEE_MANAGE_URL}/${memberSeq}`,
          null,
          null,
        );

        runInAction(() => {
          this.employee = {
            ...data,
            password: '',
          };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveEmployee(params) {
      try {
        await authApi.post(`${EMPLOYEE_MANAGE_URL}`, { ...params });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateEmployee(memberSeq, params) {
      try {
        await authApi.patch(`${EMPLOYEE_MANAGE_URL}/${memberSeq}`, {
          ...params,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteEmployee(memberSeq) {
      try {
        await authApi.delete(`${EMPLOYEE_MANAGE_URL}/${memberSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getEnterpriseList() {
      try {
        const { data } = await authApi.get(
          `${EMPLOYEE_MANAGE_URL}/api/support/enterprise`,
          null,
        );
        runInAction(() => {
          this.enterpriseList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getAuthorGroupList() {
      try {
        const { data } = await authApi.get(
          `${EMPLOYEE_MANAGE_URL}/api/support/author-groups`,
        );
        runInAction(() => {
          this.authorGroupList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
