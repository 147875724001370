import { lazy } from 'react';

/**
 * Layout 목록
 */
const MainLayout = lazy(() =>
  import(/* webpackChunkName: "MainLayout" */ '@templates/MainLayout'),
);

/**
 * Routing Page 목록
 */
// 고객사 정산 리스트 페이지
const CustomerSettlementListPage = lazy(() =>
  import(
    /* webpackChunkName: "CustomerSettlementListPage" */ 'src/pages/user/settlement/customer'
  ),
);

// 고객사 정산 상세 페이지
const CustomerSettlementViewPage = lazy(() =>
  import(
    /* webpackChunkName: "CustomerSettlementViewPage" */ 'src/pages/user/settlement/customer/CustomerSettlementViewPage'
  ),
);

// 센터 정산 리스트 페이지
const CenterSettlementListPage = lazy(() =>
  import(
    /* webpackChunkName: "CenterSettlementListPage" */ 'src/pages/user/settlement/center'
  ),
);

// 센터 정산 상세 페이지
const CenterSettlementViewPage = lazy(() =>
  import(
    /* webpackChunkName: "CenterSettlementViewPage" */ 'src/pages/user/settlement/center/CenterSettlementViewPage'
  ),
);

// 센터 정산 모바일 상세 페이지
const CenterSettlementViewMobilePage = lazy(() =>
  import(
    /* webpackChunkName: "CenterSettlementViewMobilePage" */ 'src/pages/user/settlement/center/CenterSettlementViewMobilePage'
  ),
);

// 고객사 정산 모바일 상세 페이지
const CustomerSettlementViewMobilePage = lazy(() =>
  import(
    /* webpackChunkName: "CustomerSettlementViewMobilePage" */ 'src/pages/user/settlement/customer/CustomerSettlementViewMobilePage'
  ),
);

const getRoutes = () => {
  const routes = [
    {
      name: '고객사 정산내역 목록',
      path: '/settlement/customerSettlement',
      component: CustomerSettlementListPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '고객사 정산내역 상세',
      path: '/settlement/customerSettlement/view',
      component: CustomerSettlementViewPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '센터 정산내역 목록',
      path: '/settlement/centerSettlement',
      component: CenterSettlementListPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '센터 정산내역 상세',
      path: '/settlement/centerSettlement/view',
      component: CenterSettlementViewPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },

    {
      name: '센터 정산내역 모바일 상세',
      path: '/settlement/centerSettlement/view',
      component: CenterSettlementViewMobilePage,
      layout: MainLayout,
      isAuth: true,
      isPc: false,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '고객사 정산내역 모바일 상세',
      path: '/settlement/customerSettlement/view',
      component: CustomerSettlementViewMobilePage,
      layout: MainLayout,
      isAuth: true,
      isPc: false,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
  ];

  return routes;
};

export default getRoutes();
