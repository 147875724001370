import { lazy } from 'react';

/**
 * Layout 목록
 */
const MainLayout = lazy(() =>
  import(/* webpackChunkName: "MainLayout" */ '@templates/MainLayout'),
);

const TabLayout = lazy(() =>
  import(/* webpackChunkName: "TabLayout" */ '@templates/TabLayout'),
);

/**
 * Routing Page 목록
 */

// 사용자유형 권한관리
const AuthorityPage = lazy(() =>
  import(/* webpackChunkName: "AuthorityPage" */ '@pages/admin/authority'),
);

// 권한그룹 관리
const AuthoritySystemPage = lazy(() =>
  import(/* webpackChunkName: "AuthorityPage" */ '@pages/admin/authority/system'),
);

const getRoutes = () => {
  const routes = [
    {
      name: '사용자유형 권한관리',
      path: '/authority',
      component: AuthorityPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '권한그룹',
      path: '/authority/system',
      component: AuthoritySystemPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
  ];

  return routes;
};

export default getRoutes();
