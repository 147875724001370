import Router from '@router/index';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMobxStores } from '@stores/index';
import { CircularProgress } from '@mui/material';
import { observer } from 'mobx-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import typography from './assets/typography';
import component from './assets/component';

const themes = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  mixins: {
    toolbar: {
      minHeight: '48px',
      padding: '16px',
      '@media (min-width: 600px)': {
        minHeight: '48px',
      },
    },
  },
  typography: typography,
  components: component(),
});

function App() {
  const { globalStore } = useMobxStores();

  return (
    <>
      {globalStore.isLoading && (
        <div className="dimmed3">
          <CircularProgress aria-label="progress" className="progress" />
        </div>
      )}
      <ThemeProvider theme={themes}>
        <Router />
      </ThemeProvider>
    </>
  );
}

export default observer(App);
