import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

const ORDER_OFE_URL = '/api/item/order-ofe';

export const initial = {
  orderOfeList: {
    data: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },

  orderOfe: {
    orderOfeSeq: '',
    enterpriseNm: '',
    chargerNm: '',
    applcntMemberNm: '',
    reqstTySe: '',
    se: '',
    pblicteDt: '',
    orderDt: '',
    opetrMemberNm: '',
    processDt: '',
    orderOfeSttus: '',
    list: [],
  },
};

// 발주서관리
export function createMaterialOrderOfeStore(rootStore) {
  return makeAutoObservable({
    orderOfeList: initial.orderOfeList,
    orderOfe: initial.orderOfe,

    applyMemberList: [], // 신청자 목록

    alertMessage: '',

    // 리스트 데이터
    async getOrderOfeList(options) {
      try {
        const res = await authApi.get(ORDER_OFE_URL, { ...options }, null);
        runInAction(() => {
          this.orderOfeList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.orderOfeList = initial.orderOfeList;
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 상세
    async getOrderOfe(orderOfeSeq) {
      try {
        const { data } = await authApi.get(
          `${ORDER_OFE_URL}/${orderOfeSeq}`,
          null,
          null,
        );
        runInAction(() => {
          this.orderOfe = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.orderOfe = initial.orderOfe;
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 상태 변경
    async updateOrderOfe(params) {
      try {
        await authApi.put(`${ORDER_OFE_URL}/change`, params);

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteOrderOfe(orderOfeSeq) {
      try {
        await authApi.delete(`${ORDER_OFE_URL}/${orderOfeSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 신청자 목록
    async getApplyMemberList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_OFE_URL}/support/applcntMemberList`,
          null,
          null,
        );

        runInAction(() => {
          this.applyMemberList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
