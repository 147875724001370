import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';

const MODEL_DEFECT_URL = '/api/statistics/model-defect';

export const initial = {
  modelDefectList: {
    data: [],
    pageable: null,
  },
};

export function createModelDefectStore(rootStore) {
  return makeAutoObservable({
    modelDefectList: initial.modelDefectList,

    // 코드값
    modelList: [], // 모델목록

    categoryList: [], // 카테고리 목록
    centerList: [], // 센터목록

    alertMessage: '',

    async getServiceInfoList(options) {
      try {
        const res = await authApi.get(
          MODEL_DEFECT_URL,
          { ...options },
          null,
        );
        runInAction(() => {
          this.modelDefectList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 모델
    async getModelList(categorySeq, enterpriseSeq) {
      try {
        const { data } = await authApi.get(
          `${MODEL_DEFECT_URL}/searchModelList`,
          {
            categorySeq,
            enterpriseSeq,
          },
          null,
        );
        runInAction(() => {
          this.modelList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 카테고리 목록
    async getCategoryList() {
      try {
        const { data } = await authApi.get(
          `${MODEL_DEFECT_URL}/support/categoryList`,
          null,
          null,
        );
        runInAction(() => {
          this.categoryList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 센터 목록
    async getCenterList() {
      try {
        const { data } = await authApi.get(
          `${MODEL_DEFECT_URL}/support/centerList`,
          null,
          null,
        );
        runInAction(() => {
          this.centerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
