import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';

const MY_PAGE_INQUIRY_URL = '/api/mypage/qna';

export const initial = {
  inquiryList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  inquiry: {
    bbsSeq: '',
    writerMemberSeq: '',
    bbsSj: '',
    bbsCn: '',
    regDt: '',
    atchmnfls: [],
    bbsAnswers: [],
    changeFileNms: [],
  },

  atchmnfls: {
    atchmnflSeq: 25,
    tableNm: '',
    seq: 4,
    fileDc: '',
    fileSe: '',
    orginlFileNm: '',
    changeFileNm: '',
    flpth: '',
    fileMg: '',
    deleteAt: '',
    atchmnflLink: '',
  },
  bbsAnswers: {
    bbsAnswerSeq: '',
    bbsAnswerCn: '',
    wrterMemberSeq: '',
    wrterMemberNm: '',
    writngDt: '',
  },
};

export const bbsValidationSchema = yup.object({
  bbsSj: yup.string().required('제목은 필수 입력 값 입니다.'),
  bbsCn: yup.string().required('내용은 필수 입력 값 입니다.'),
});

export function createMyPageInquiryStore(rootStore) {
  return makeAutoObservable({
    inquiryList: initial.inquiryList,
    inquiry: initial.inquiry,

    alertMessage: '',

    async getInquiryList(options) {
      try {
        const res = await authApi.get(
          MY_PAGE_INQUIRY_URL,
          { ...options },
          null,
        );

        runInAction(() => {
          this.inquiryList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getInquiry(bbsSeq) {
      try {
        const { data } = await authApi.get(
          `${MY_PAGE_INQUIRY_URL}/${bbsSeq}`,
          null,
          null,
        );
        runInAction(() => {
          this.inquiry = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveInquiry({ bbsCn, bbsSj, upendFixingAt = 0, url }) {
      try {
        await authApi.post(MY_PAGE_INQUIRY_URL, {
          bbsSj,
          bbsCn,
          upendFixingAt,
          url,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateInquiry(bbsSeq, { bbsSj, bbsCn, changeFileNms }) {
      try {
        await authApi.patch(`${MY_PAGE_INQUIRY_URL}/${bbsSeq}`, {
          bbsSj,
          bbsCn,
          changeFileNms,
          upendFixingAt: 0,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteInquiry(bbsSeq) {
      try {
        await authApi.delete(`${MY_PAGE_INQUIRY_URL}/${bbsSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
