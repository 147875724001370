import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

import * as yup from 'yup';

const ORDER_CREATE_URL = '/api/item/order-create';

export const initial = {
  orderCreateList: [],

  orderCreate: {
    itemSe: '',
    itemSeq: '',
    prdno: '',
    prdnm: '',
    enterpriseNm: '',
    chargerNm: '',
    categoryNm: '',
    holdQy: '',
    headOfficeHoldQy: '',
    sfinvc: '',
    suplyUntpc: '',
    cnsmrPc: '',
    prdctnDt: '',
    assrncPd: '',
    actvtySe: '',
    se: '',
    partclrMatter: '',
    imageLink: '',
    modelList: [],
  },
};

export const orderCreateValidationSchema = yup.object({});

// 발주신청
export function createMaterialOrderCreateStore(rootStore) {
  return makeAutoObservable({
    orderCreateList: initial.orderCreateList,
    orderCreate: initial.orderCreate,

    selectedOrderCreateItemSeq: null,

    supplierList: [],

    alertMessage: '',

    async getOrderCreateList(enterpriseSeq) {
      try {
        const { data } = await authApi.get(
          ORDER_CREATE_URL,
          { enterpriseSeq },
          null,
        );

        runInAction(() => {
          this.orderCreateList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getOrderCreate() {
      try {
        if (!this.selectedOrderCreateItemSeq) {
          return false;
        }

        const { data } = await authApi.get(
          `${ORDER_CREATE_URL}/${this.selectedOrderCreateItemSeq}`,
          null,
          null,
        );

        let modelList = [];

        for (let i = 0; i < 5; i++) {
          const check = data.modelList.filter((model) => {
            return model.sn === i + 1;
          });
          if (check.length > 0) {
            modelList[i] = check[0];
          } else {
            modelList[i] = null;
          }
        }
        runInAction(() => {
          this.orderCreate = { ...data, modelList };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async registerOrderCreate(params) {
      try {
        await authApi.post(ORDER_CREATE_URL, params);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getSupplierList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_CREATE_URL}/support/supplierList`,
          null,
          null,
        );

        runInAction(() => {
          this.supplierList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    setSelectedOrderCreate(itemSeq) {
      runInAction(() => {
        this.selectedOrderCreateItemSeq = itemSeq;
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
