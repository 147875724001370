import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';

const RECEPTION_URL = '/api/statistics/reception';

export const initial = {
  areaList: null,
  dayList: null,
  modelList: null,
};

export function createReceptionStore(rootStore) {
  return makeAutoObservable({
    areaList: initial.areaList, // 지역 목록
    dayList: initial.dayList, // 일일목록
    modelList: initial.modelList, // 모델 목록

    // 코드값
    areaCodeList: null, // 지역코드목록
    categoryList: null, // 카테고리 목록
    customerList: null, // 업체목록
    opetrMemberList: null, // 배정 담당자 목록

    alertMessage: '',

    async getAreaList(options) {
      try {
        const { data } = await authApi.get(
          `${RECEPTION_URL}/area`,
          { ...options },
          null,
        );
        runInAction(() => {
          this.areaList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async getDayList(options) {
      try {
        const { data } = await authApi.get(
          `${RECEPTION_URL}/day`,
          { ...options },
          null,
        );
        runInAction(() => {
          this.dayList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getModelList(options) {
      try {
        const { data } = await authApi.get(
            `${RECEPTION_URL}/model`,
            { ...options },
            null,
        );
        runInAction(() => {
          this.modelList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 지역 코드 목록
    async getAreaCodeList() {
      try {
        const { data } = await authApi.get(
          `${RECEPTION_URL}/support/areaList`,
          null,
          null,
        );
        runInAction(() => {
          this.areaCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 업체목록
    async getCustomerList() {
      try {
        const { data } = await authApi.get(
          `${RECEPTION_URL}/support/customerList`,
          null,
          null,
        );
        runInAction(() => {
          this.customerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 배정 담당자 목록
    async getOpetrMemberList() {
      try {
        const { data } = await authApi.get(
          `${RECEPTION_URL}/support/opetrMemberList`,
          null,
          null,
        );
        runInAction(() => {
          this.opetrMemberList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 카테고리 목록
    async getCategoryList() {
      try {
        const { data } = await authApi.get(
            `${RECEPTION_URL}/support/categoryList`,
            null,
            null,
        );
        runInAction(() => {
          this.categoryList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
