import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

const INVENTORY_URL = '/api/item/invntry';

export const initial = {
  inventoryList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  inventory: {
    itemSe: '',
    itemSeq: '',
    prdno: '',
    prdnm: '',
    enterpriseNm: '',
    chargerNm: '',
    categoryNm: '',
    holdQy: '',
    centerHoldQy: '',
    headOfficeHoldQy: '',
    sfinvc: '',
    suplyUntpc: '',
    cnsmrPc: '',
    prdctnDt: '',
    assrncPd: '',
    actvtySe: '',
    partclrMatter: '',
    imageLink: '',
    modelList: [],
    holdQyByCenter: [],
  },
};

// 보유재고 API
export function createMaterialInventoryStore(rootStore) {
  return makeAutoObservable({
    // 목록
    inventoryList: initial.inventoryList,
    inventory: initial.inventory,

    // 코드값
    enterpriseList: [],
    categoryList: [],
    centerList: [],

    // 선택된 inventory
    selectedInventory: null,

    alertMessage: '',

    async getInventoryList(options) {
      try {
        const res = await authApi.get(
          INVENTORY_URL,
          {
            ...options,
          },
          null,
        );
        runInAction(() => {
          this.inventoryList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getInventory(itemSeq) {
      try {
        const { data } = await authApi.get(
          `${INVENTORY_URL}/${itemSeq}`,
          null,
          null,
        );
        runInAction(() => {
          this.inventory = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.inventory = initial.inventory;
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async inventoryOrder({ itemSeq, reqstQy }) {
      try {
        await authApi.post(`${INVENTORY_URL}/order`, { itemSeq, reqstQy });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateSafeInventory(params) {
      try {
        //   "sfinvcList": "[{itemSeq:1,sfinvc:5},{itemSeq:2,sfinvc:10}]"
        await authApi.put(`${INVENTORY_URL}/sfinvc`, { list: params });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 카테고리 목록
    async getCategoryList() {
      try {
        const { data } = await authApi.get(
          `${INVENTORY_URL}/support/categoryList`,
          null,
          null,
        );

        runInAction(() => {
          this.categoryList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 업체목록
    async getEnterpriseList() {
      try {
        const { data } = await authApi.get(
          `${INVENTORY_URL}/support/customerList`,
          null,
          null,
        );
        runInAction(() => {
          this.enterpriseList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 센터 목록
    async getCenterList() {
      try {
        const { data } = await authApi.get(
          `${INVENTORY_URL}/support/centerList`,
          null,
          null,
        );

        runInAction(() => {
          this.centerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    setSelectedInventory(inventory) {
      runInAction(() => {
        this.selectedInventory = inventory;
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
