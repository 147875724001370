import { lazy } from 'react';

/**
 * Layout 목록
 */
const TabLayout = lazy(() =>
  import(/* webpackChunkName: "TabLayout" */ '@templates/TabLayout'),
);

/**
 * Routing Page 목록
 */
// 업체관리 > 고객사관리
const CustomerManagePage = lazy(() =>
  import(
    /* webpackChunkName: "CustomerManagePage" */ '@pages/admin/enterprise/customer'
  ),
);

// 업체관리 > 대리점(센터)관리
const CenterManagePage = lazy(() =>
  import(
    /* webpackChunkName: "CenterManagePage" */ '@pages/admin/enterprise/center'
  ),
);

const getRoutes = () => {
  const routes = [
    {
      name: '고객사관리',
      path: '/enterprise/customer',
      component: CustomerManagePage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '대리점(센터)관리',
      path: '/enterprise/center',
      component: CenterManagePage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
  ];

  return routes;
};

export default getRoutes();
