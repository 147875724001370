import { lazy } from 'react';

/**
 * Layout 목록
 */
const TabLayout = lazy(() =>
  import(/* webpackChunkName: "TabLayout" */ '@templates/TabLayout'),
);

/**
 * Routing Page 목록
 */
// 메뉴관리
// 메뉴등록관리
const MenuManager = lazy(() =>
  import(
    /* webpackChunkName: "MenuManager" */ '@pages/admin/menu/menuRegManager'
  ),
);
// 생성형게시판
const GeneratedBoardPage = lazy(() =>
  import(
    /* webpackChunkName: "GeneratedBoardPage" */ '@pages/admin/menu/generatedBoard'
  ),
);

const getRoutes = () => {
  const routes = [
    {
      name: '메뉴등록관리',
      path: '/menu/manager',
      component: MenuManager,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '생성형게시판',
      path: '/menu/generative',
      component: GeneratedBoardPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
  ];

  return routes;
};

export default getRoutes();
