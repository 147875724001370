import { lazy } from 'react';

/**
 * Layout 목록
 */
const TabLayout = lazy(() =>
  import(/* webpackChunkName: "TabLayout" */ '@templates/TabLayout'),
);
const MainLayout = lazy(() =>
  import(/* webpackChunkName: "MainLayout" */ '@templates/MainLayout'),
);

/**
 * Routing Page 목록
 */
// 분류/업체/카테고리
const ClassificationManagePage = lazy(() =>
  import(
    /* webpackChunkName: "ClassificationManagePage" */ '@pages/admin/material-code/classification'
  ),
);

// 제품리스트관리
const ModelPage = lazy(() =>
  import(
    /* webpackChunkName: "ModelPage" */ '@pages/admin/material-code/model'
  ),
);
// 자재관리
const MaterialPage = lazy(() =>
  import(
    /* webpackChunkName: "MaterialManagePage" */ '@pages/admin/material-code/material'
  ),
);
// 코드관리
const CodeManagePage = lazy(() =>
  import(
    /* webpackChunkName: "CodeManagePage" */ '@pages/admin/material-code/code'
  ),
);

// 공임비관리
const AdditionalManagePage = lazy(() =>
  import(
    /* webpackChunkName: "AdditionalManagePage" */ '@pages/admin/material-code/additional/AdditionalManagePage'
  ),
);

// 급지비용관리
const AreaGradeManagePage = lazy(() =>
  import(
    /* webpackChunkName: "AreaGradeManagePage" */ '@pages/admin/material-code/area/AreaGradeManagePage'
  ),
);

const getRoutes = () => {
  const routes = [
    {
      name: '분류/업체/카테고리',
      path: '/materialcode/classification',
      component: ClassificationManagePage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '제품리스트관리',
      path: '/materialcode/model',
      component: ModelPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '자재관리',
      path: '/materialcode/material',
      component: MaterialPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '제품리스트관리-Mobile',
      path: '/materialcode/model',
      component: ModelPage,
      layout: MainLayout,
      isAuth: true,
      isPc: false,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '자재관리-Mobile',
      path: '/materialcode/material',
      component: MaterialPage,
      layout: MainLayout,
      isAuth: true,
      isPc: false,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '코드관리',
      path: '/materialcode/code',
      component: CodeManagePage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '공임비관리',
      path: '/materialcode/additional',
      component: AdditionalManagePage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '급지비용관리',
      path: '/materialcode/area-grade',
      component: AreaGradeManagePage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
  ];

  return routes;
};

export default getRoutes();
