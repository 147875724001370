import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

const ALL_RECEPTION_URL = '/api/service-info/reception-log/all-reception';

export const initial = {
  receptionList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 20,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  reception: {
    rceptSeq: '',
  },

  listCount: {
    asCount: 0, // AS건
    instCount: 0, // 설치,점건건
  },
};

export function createAllReceptionStore(rootStore) {
  return makeAutoObservable({
    receptionList: initial.receptionList,
    reception: initial.reception,

    listCount: initial.listCount,

    customerCenterList: [], // 고객사

    async getReceptionList({
      supplierEnterpriseSeq,
      rcepterEnterpriseSeq,
      rcepterMemberNm,
      rceptSe,
      cmptncEnterpriseSeq,
      opetrMemberSeq,
      opetrMemberNm,
      serviceSttus,
      rceptDtSt,
      rceptDtEd,
      processDtSt,
      processDtEd,
      modelNm,
      customerNm,
      cttpc,
      enterpriseNm,
      adres,
      number,
      size,
      rceptSeq,
    }) {
      try {
        const res = await authApi.get(
          ALL_RECEPTION_URL,
          {
            supplierEnterpriseSeq:
              typeof supplierEnterpriseSeq === 'object'
                ? supplierEnterpriseSeq?.join(',')
                : supplierEnterpriseSeq,
            rcepterEnterpriseSeq:
              typeof rcepterEnterpriseSeq === 'object'
                ? rcepterEnterpriseSeq?.join(',')
                : rcepterEnterpriseSeq,
            rcepterMemberNm: rcepterMemberNm?.[0] ?? null,
            rceptSe: rceptSe ? rceptSe.join(',') : null,
            cmptncEnterpriseSeq:
              typeof cmptncEnterpriseSeq === 'object'
                ? cmptncEnterpriseSeq?.join(',')
                : cmptncEnterpriseSeq,
            opetrMemberSeq: opetrMemberSeq ? opetrMemberSeq.join(',') : null,
            opetrMemberNm: opetrMemberNm ? opetrMemberNm.join(',') : null,
            serviceSttus: serviceSttus ? serviceSttus.join(',') : null,
            rceptDtSt: rceptDtSt?.[0] ?? null,
            rceptDtEd: rceptDtEd?.[0] ?? null,
            processDtSt: processDtSt?.[0] ?? null,
            processDtEd: processDtEd?.[0] ?? null,
            modelNm: modelNm?.[0] ?? null,
            customerNm: customerNm?.[0] ?? null,
            cttpc: cttpc?.[0] ?? null,
            enterpriseNm: enterpriseNm?.[0] ?? null,
            adres: adres?.[0] ?? null,
            number: typeof number === 'object' ? number?.[0] ?? 1 : number ?? 1,
            size: typeof size === 'object' ? size?.[0] ?? 20 : size ?? 20,
            rceptSeq: rceptSeq?.[0] ?? null,
          },
          null,
        );

        runInAction(() => {
          this.receptionList = res;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.receptionList = initial.receptionList;
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getReception(serviceInfoSeq) {
      try {
        const { data } = await authApi.get(
          `${ALL_RECEPTION_URL}/${serviceInfoSeq}`,
          null,
          null,
        );

        runInAction(() => {
          this.reception = data;
        });

        return data;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateCompleteCancel(params) {
      try {
        await authApi.put(`${ALL_RECEPTION_URL}/cancel`, params);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateDeposit(serviceInfoSeq) {
      try {
        await authApi.put(`${ALL_RECEPTION_URL}/deposit`, { serviceInfoSeq });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getListCount({
      supplierEnterpriseSeq,
      rcepterEnterpriseSeq,
      rcepterMemberNm,
      rceptSe,
      cmptncEnterpriseSeq,
      opetrMemberSeq,
      serviceSttus,
      rceptDtSt,
      rceptDtEd,
      processDtSt,
      processDtEd,
      modelNm,
      customerNm,
      cttpc,
      enterpriseNm,
      adres,
      number,
      size,
      rceptSeq,
    }) {
      try {
        const { data } = await authApi.get(
          `${ALL_RECEPTION_URL}/list-count`,
          {
            supplierEnterpriseSeq:
              typeof supplierEnterpriseSeq === 'object'
                ? supplierEnterpriseSeq?.join(',')
                : supplierEnterpriseSeq,
            rcepterEnterpriseSeq:
              typeof rcepterEnterpriseSeq === 'object'
                ? rcepterEnterpriseSeq?.join(',')
                : rcepterEnterpriseSeq,
            rcepterMemberNm: rcepterMemberNm?.[0] ?? null,
            rceptSe: rceptSe ? rceptSe.join(',') : null,
            cmptncEnterpriseSeq:
              typeof cmptncEnterpriseSeq === 'object'
                ? cmptncEnterpriseSeq?.join(',')
                : cmptncEnterpriseSeq,
            opetrMemberSeq: opetrMemberSeq ? opetrMemberSeq.join(',') : null,
            serviceSttus: serviceSttus ? serviceSttus.join(',') : null,
            rceptDtSt: rceptDtSt?.[0] ?? null,
            rceptDtEd: rceptDtEd?.[0] ?? null,
            processDtSt: processDtSt?.[0] ?? null,
            processDtEd: processDtEd?.[0] ?? null,
            modelNm: modelNm?.[0] ?? null,
            customerNm: customerNm?.[0] ?? null,
            cttpc: cttpc?.[0] ?? null,
            enterpriseNm: enterpriseNm?.[0] ?? null,
            adres: adres?.[0] ?? null,
            number: typeof number === 'object' ? number?.[0] ?? 1 : number ?? 1,
            size: typeof size === 'object' ? size?.[0] ?? 20 : size ?? 20,
            rceptSeq: rceptSeq?.[0] ?? null,
          },
          null,
        );

        runInAction(() => {
          this.listCount = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
          this.listCount = initial.listCount;
        });
        return false;
      }
    },

    /**
     * 모바일에서 전화 문자 버튼 클릭시 미컨택인 건은 컨택으로 변경
     * @param params
     * @returns {Promise<boolean>}
     */
    async updateContact(serviceInfoSeq) {
      try {
        await authApi.put(
          `${ALL_RECEPTION_URL}/${serviceInfoSeq}/contact`,
          null,
        );
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateServiceCancel(serviceInfoSeq, customerCanclPrvonsh) {
      try {
        await authApi.put(`${ALL_RECEPTION_URL}/service-cancel`, {
          serviceInfoSeq,
          customerCanclPrvonsh,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCustomerCenterList() {
      try {
        const { data } = await authApi.get(
          `${ALL_RECEPTION_URL}/support/customerList`,
          null,
          null,
        );

        runInAction(() => {
          this.customerCenterList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.customerCenterList = [];
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
