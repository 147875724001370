import { lazy } from 'react';

/**
 * Layout 목록
 */
const MainLayout = lazy(() =>
  import(/* webpackChunkName: "MainLayout" */ '@templates/MainLayout'),
);

/**
 * Routing Page 목록
 */
// 자재관리
// 보유재고리스트
const inventoryListPage = lazy(() =>
  import(
    /* webpackChunkName: "InventoryListPage" */ 'src/pages/user/material-management/inventory'
  ),
);

// 주문/반납
const OrderListPage = lazy(() =>
  import(
    /* webpackChunkName: "OrderListPage" */ 'src/pages/user/material-management/order'
  ),
);

// 주문/반납 내역
const OrderLogListPage = lazy(() =>
  import(
    /* webpackChunkName: "OrderLogListPage" */ 'src/pages/user/material-management/order-log'
  ),
);

// 주문/반납 내역 상세
const OrderLogViewPage = lazy(() =>
  import(
    /* webpackChunkName: "OrderLogViewPage" */ 'src/pages/user/material-management/order-log/OrderLogViewPage'
  ),
);

// 발주신청
const OrderCreatePage = lazy(() =>
  import(
    /* webpackChunkName: "OrderCreatePage" */ 'src/pages/user/material-management/order-create'
  ),
);

// 발주서 관리
const OrderOfeManagePage = lazy(() =>
  import(
    /* webpackChunkName: "OrderOfeManagePage" */ 'src/pages/user/material-management/order-ofe'
  ),
);

// 실입고목록
const OrderActualListPage = lazy(() =>
  import(
    /* webpackChunkName: "OrderActualListPage" */ 'src/pages/user/material-management/order-actual'
  ),
);

// 자재반납신청
const OrderReturnCreatePage = lazy(() =>
  import(
    /* webpackChunkName: "OrderReturnCreatePage" */ 'src/pages/user/material-management/order-return-create'
  ),
);

// 자재반납관리
const OrderReturnManagePage = lazy(() =>
  import(
    /* webpackChunkName: "OrderReturnManagePage" */ 'src/pages/user/material-management/order-return'
  ),
);

// 센터출고관리
const CenterOrderManagePage = lazy(() =>
  import(
    /* webpackChunkName: "CenterOrderManagePage" */ 'src/pages/user/material-management/center-order'
  ),
);

// 센터반납관리
const CenterReturnManagePage = lazy(() =>
  import(
    /* webpackChunkName: "CenterReturnManagePage" */ 'src/pages/user/material-management/center-return-order'
  ),
);

const getRoutes = () => {
  const routes = [
    {
      name: '보유재고리스트',
      path: '/item/invntry',
      component: inventoryListPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '주문/반납',
      path: '/item/order',
      component: OrderListPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '주문/반납 내역',
      path: '/item/order-log',
      component: OrderLogListPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '주문/반납 내역 상세',
      path: '/item/order-log/view/:itemSeq',
      component: OrderLogViewPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '발주신청',
      path: '/item/order-create',
      component: OrderCreatePage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '발주서관리',
      path: '/item/order-ofe',
      component: OrderOfeManagePage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '실입고목록',
      path: '/item/order-actual',
      component: OrderActualListPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '자재반납신청',
      path: '/item/order-return-create',
      component: OrderReturnCreatePage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '자재반납관리',
      path: '/item/order-return',
      component: OrderReturnManagePage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '센터출고관리',
      path: '/item/center-order',
      component: CenterOrderManagePage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '센터반납관리',
      path: '/item/center-return-order',
      component: CenterReturnManagePage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
  ];
  return routes;
};

export default getRoutes();
