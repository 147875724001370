import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

const ORDER_RETURN_CREATE_URL = '/api/item/order-return-create';

export const initial = {
  orderReturnCreateList: [],
  orderReturnCreate: {
    itemSe: '',
    itemSeq: '',
    prdno: '',
    prdnm: '',
    enterpriseNm: '',
    chargerNm: '',
    categoryNm: '',
    holdQy: '',
    headOfficeHoldQy: '',
    sfinvc: '',
    suplyUntpc: '',
    cnsmrPc: '',
    prdctnDt: '',
    assrncPd: '',
    actvtySe: '',
    partclrMatter: '',
    imageLink: '',
    modelList: [],
  },

  pageOptions: {
    enterpriseSeq: null,
    number: 1,
  },
};

// 자재반납신청
export function createMaterialOrderReturnCreateStore(rootStore) {
  return makeAutoObservable({
    orderReturnCreateList: initial.orderReturnCreateList,
    orderReturnCreate: initial.orderReturnCreate,

    supplierList: [], // 공급처/담당자 목록
    returnReasonList: [], // 반납사유코드 목록

    selectedOrderReturnCreateItemSeq: null,

    pageOptions: initial.pageOptions,

    alertMessage: '',

    async getOrderReturnCreateList(enterpriseSeq) {
      try {
        const { data } = await authApi.get(
          ORDER_RETURN_CREATE_URL,
          { enterpriseSeq },
          null,
        );
        runInAction(() => {
          this.orderReturnCreateList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getOrderReturnCreate() {
      try {
        if (!this.selectedOrderReturnCreateItemSeq) {
          return false;
        }

        const { data } = await authApi.get(
          `${ORDER_RETURN_CREATE_URL}/${this.selectedOrderReturnCreateItemSeq}`,
          null,
          null,
        );

        let modelList = [];
        for (let i = 0; i < 5; i++) {
          const check = data.modelList.filter((model) => {
            return model.sn === i + 1;
          });
          if (check.length > 0) {
            modelList[i] = check[0];
          } else {
            modelList[i] = null;
          }
        }

        runInAction(() => {
          this.orderReturnCreate = { ...data, modelList };
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async registerOrderReturnCreate(params) {
      try {
        await authApi.post(ORDER_RETURN_CREATE_URL, { ...params });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getSupplierList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_RETURN_CREATE_URL}/support/supplierList`,
          null,
          null,
        );

        runInAction(() => {
          this.supplierList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getReturnReasonCodeList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_RETURN_CREATE_URL}/support/rturnPrvonshCodeList`,
          null,
          null,
        );

        runInAction(() => {
          this.returnReasonList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    setSelectedOrderReturnCreate(itemSeq) {
      runInAction(() => {
        this.selectedOrderReturnCreateItemSeq = itemSeq;
      });
    },

    setPageOptions(value, init) {
      runInAction(() => {
        this.pageOptions = init
          ? {
              ...initial.pageOptions,
              ...value,
            }
          : {
              ...this.pageOptions,
              ...value,
            };
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
