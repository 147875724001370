import { isEmpty } from '@helpers/StringHelper';
import { Navigate } from 'react-router-dom';
import { getSessionStorage } from '@helpers/StorageHelper';
import { ACCESS_TOKEN } from '@const/env';

import { Link } from 'react-router-dom';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';

// project imports
import AnimateButton from '@ui-component/AnimateButton';

// assets
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';

import imageBackground from '@images/maintenance/img-error-bg.svg';
import imageDarkBackground from '@images/maintenance/img-error-bg-dark.svg';
import imageBlue from '@images/maintenance/img-error-blue.svg';
import imageText from '@images/maintenance/img-error-text.svg';
import imagePurple from '@images/maintenance/img-error-purple.svg';

// styles
const CardMediaWrapper = styled('div')({
  maxWidth: 720,
  margin: '0 auto',
  position: 'relative',
});

const ErrorWrapper = styled('div')({
  maxWidth: 350,
  margin: '0 auto',
  textAlign: 'center',
});

const ErrorCard = styled(Card)({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const CardMediaBlock = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  animation: '3s bounce ease-in-out infinite',
});

const CardMediaBlue = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  animation: '15s wings ease-in-out infinite',
});

const CardMediaPurple = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  animation: '12s wings ease-in-out infinite',
});

function Error404Page() {
  const accessToken = getSessionStorage(ACCESS_TOKEN);

  const theme = useTheme();

  if (isEmpty(accessToken)) {
    return <Navigate to="/login" replace="true" />;
  }

  return (
    <ErrorCard>
      <CardContent>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <CardMediaWrapper>
              <CardMedia
                component="img"
                image={
                  theme.palette.mode === 'dark'
                    ? imageDarkBackground
                    : imageBackground
                }
                title="Slider5 image"
              />
              <CardMediaBlock src={imageText} title="Slider 1 image" />
              <CardMediaBlue src={imageBlue} title="Slider 2 image" />
              <CardMediaPurple src={imagePurple} title="Slider 3 image" />
            </CardMediaWrapper>
          </Grid>
          <Grid item xs={12}>
            <ErrorWrapper sx={{ maxWidth: '800px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    sx={{ fontSize: { xs: '20px', md: '30px' } }}
                  >
                    서비스 이용에 불편을 드려 죄송합니다.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    요청하신 페이지를 바르게 표시할 수 없습니다.
                    <br /> 아래와 같은 경우 이 페이지가 표시됩니다.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    sx={{
                      border: '1px solid #333',
                      padding: '30px 10px',
                      maxWidth: '500px',
                      margin: '0 auto',
                      fontSize: { xs: '13px', md: '16px' }
                    }}
                  >
                    1. 원하시는 페이지를 찾을 수 없거나 다른 메뉴로 이동한 경우
                    <br />
                    2. 서비스 수행 중 일시적인 장애가 발생한 경우
                    <br />
                    3. 이용 폭주로 서비스가 지연되는 경우
                    <br />
                    4. 해당 페이지에 대한 접근이 허가되지 않은 경우
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <AnimateButton>
                    <Button
                      variant="contained"
                      size="large"
                      component={Link}
                      to={'/'}
                    >
                      <HomeTwoToneIcon sx={{ fontSize: '1.3rem', mr: 0.75 }} />{' '}
                      메인으로
                    </Button>
                  </AnimateButton>
                </Grid>
              </Grid>
            </ErrorWrapper>
          </Grid>
        </Grid>
      </CardContent>
    </ErrorCard>
  );
}

export default Error404Page;
