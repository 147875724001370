import { lazy } from 'react';

/**
 * Layout 목록
 */
const SimpleLayout = lazy(() =>
  import(/* webpackChunkName: "MainLayout" */ '@templates/SimpleLayout'),
);


/**
 * Routing Page 목록
 */

// 설정
const SettingPage = lazy(() =>
  import(/* webpackChunkName: "SettingPage" */ 'src/pages/user/setting'),
);

const getRoutes = () => {
  const routes = [
    {
      name: '설정',
      path: '/setting',
      component: SettingPage,
      layout: SimpleLayout,
      isAuth: true,
      isPc: false,
      isMobile: true,
      isBookmarked: false,
      permissions: [],
    },
  ];

  return routes;
};

export default getRoutes();
