import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

const ORDER_URL = '/api/item/order';

export const initial = {
  orderList: {
    data: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },

  order: {
    categoryNm: '',
    cnsmrPc: '',
    enterpriseNm: '',
    centerHoldQy : '',
    headOfficeHoldQy: '',
    holdQy: '',
    imageLink: '',
    itemSe: '',
    itemSeq: '',
    modelNm: '',
    prdnm: '',
    prdno: '',
  },
};

export const itemSeNm = {
  1: '제품',
  2: '부품',
  3: '소모품',
};

// 주문/반납
export function createMaterialOrderStore(rootStore) {
  return makeAutoObservable({
    orderList: initial.orderList,

    // centerList: [], // 센터 목록
    enterpriseList: [], // 업체 목록
    categoryList: [], // 카테고리 목록
    returnPrvonshCodeList: [], // 반납사유 목록
    materialList: [], // 자재코드(모델명) 목록

    alertMessage: '',

    // 주문목록
    async getOrderList(options) {
      try {
        const res = await authApi.get(ORDER_URL, { ...options }, null);
        runInAction(() => {
          this.orderList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        this.alertMessage = alertMessage;
      }
    },

    // 주문 등록/반품등록
    async registerOrder({ itemSeq, memo, orderSe, reqstQy, rturnPrvonshCode }) {
      try {
        await authApi.post(ORDER_URL, [
          { itemSeq, memo, orderSe, reqstQy, rturnPrvonshCode },
        ]);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 카테고리 목록
    async getCategoryList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_URL}/support/categoryList`,
          null,
          null,
        );

        runInAction(() => {
          this.categoryList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    // 센터 목록 > 미사용
    async getCenterList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_URL}/support/centerList`,
          null,
          null,
        );

        runInAction(() => {
          this.centerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    // 업체목록
    async getEnterpriseList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_URL}/support/customerList`,
          null,
          null,
        );
        runInAction(() => {
          this.enterpriseList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    // 반납 사유 코드 목록
    async getReturnPrvonshCodeList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_URL}/support/rturnPrvonshCodeList`,
          null,
          null,
        );
        runInAction(() => {
          this.returnPrvonshCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
