import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';
import {toHtml} from "@helpers/StringHelper";

const QNA_URL = '/api/community/qna';

export const initial = {
  qnaList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  qna: {
    bbsSeq: '',
    writerMemberSeq: '',
    isEditable: 0,
    upendFixingAt: 0,
    bbsSj: '',
    bbsCn: '',
    regDt: '',
    atchmnfls: [],
    bbsAnswers: [],
  },
};

export const qnaValidationSchema = yup.object({
  bbsSj: yup.string().required('제목은 필수 입력 값 입니다.'),
  bbsCn: yup.string().required('내용은 필수 입력 값 입니다.'),
});

export function createCommunityQnaStore() {
  return makeAutoObservable({
    qnaList: initial.qnaList,
    qna: initial.qna,

    alertMessage: '',

    async getQnaList(options) {
      try {
        const res = await authApi.get(QNA_URL, { ...options }, null);

        runInAction(() => {
          this.qnaList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getQna(bbsSeq) {
      try {
        const { data } = await authApi.get(`${QNA_URL}/${bbsSeq}`, null, null);

        runInAction(() => {
          this.qna = {
            ...data,
            bbsCn : toHtml(data.bbsCn)
          };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveQna({ bbsSj, bbsCn, changeFileNms, upendFixingAt, url }) {
      try {
        await authApi.post(QNA_URL, {
          bbsSj,
          bbsCn,
          changeFileNms,
          upendFixingAt,
          url,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateQna(
      bbsSeq,
      { bbsSj, bbsCn, changeFileNms, upendFixingAt, url },
    ) {
      try {
        await authApi.patch(`${QNA_URL}/${bbsSeq}`, {
          bbsSj,
          bbsCn,
          changeFileNms,
          upendFixingAt,
          url,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteQna(bbsSeq) {
      try {
        await authApi.delete(`${QNA_URL}/${bbsSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveQnaComment(bbsSeq, { bbsAnswerCn }) {
      try {
        await authApi.post(`${QNA_URL}/${bbsSeq}`, { bbsAnswerCn });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
