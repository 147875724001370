/**
 * local storage 정보 저장
 * @param key
 * @param value
 */
export const setLocalStorage = (key, value) => {
    if (value && typeof value === 'string') {
        localStorage.setItem(key, value);
    } else {
        localStorage.setItem(key, JSON.stringify(value));
    }
}

/**
 * local storage 정보 꺼내오기
 * @param key
 * @returns {any|string}
 */
export const getLocalStorage = (key) => {
    const data = localStorage.getItem(key) ?? null;
    try {
        return JSON.parse(data);
    } catch(e) {
        return data;
    }
}


/**
 * local storage정보 저장, 유효 기간 설정
 * @param key
 * @param value
 * @param expireTime
 */
export const setLocalStorageWithExpireTime = (key, value, expireTime = (24 * 60 * 60 * 1000)) => {
    const expire = Date.now() + expireTime;
    const obj = {
        value, expireTime
    }
    localStorage.setItem(key, JSON.stringify(obj));
}

/**
 * local storage 정보 꺼내오기, 유효기간 만료시 local storage에 저장된 정보 삭제
 * @param key
 * @returns {*|null}
 */
export const getLocalStorageWithExpireTime = (key) => {
    const obj = JSON.parse(localStorage.getItem(key));
    if(Date.now() > obj?.expire) {
        removeLocalStorage(key);
        return null;
    }
    try {
        return JSON.parse(obj?.value);
    } catch(e) {
        return obj?.value;
    }
}

/**
 * local storage 정보 삭제
 * @param key
 */
export const removeLocalStorage = (key) => {
    localStorage.removeItem(key);
}

/**
 * session storage 정보 저장
 * @param key
 * @param value
 */
export const setSessionStorage = (key, value) => {
    if(value && typeof value === 'string') {
        sessionStorage.setItem(key, value);
    } else {
        sessionStorage.setItem(key, JSON.stringify(value));
    }
}


/**
 * session storage 정보 꺼내오기
 * @param key
 * @returns {any|string}
 */
export const getSessionStorage = (key) => {
    const data = sessionStorage.getItem(key);
    try {
        return JSON.parse(data);
    } catch(e) {
        return data;
    }
}

/**
 * session storage 정보 삭제
 * @param key
 */
export const removeSessionStorage = (key) => {
    sessionStorage.removeItem(key);
}
