import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';

const AREA_GRADE_URL = '/api/area-grade';

export const initial = {
  areaGradeList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 0,
      totalPage: 0,
    },
  },

  areaGrade: {
    areaGradeSeq: 0,
    ctprvn: '',
    signgu: '',
    emd: '',
    areaGradeCodeNm: '',
    ct: 0,
    useAt: 0,
  },
};

export const areaGradeValidationSchema = yup.object({
  areaGradeSeq: yup.string().required('코드ID는 필수 값 입니다.'),
  useAt: yup.string().required('사용여부는 필수 선택 값 입니다.'),
  areaGradeCodeNm: yup
    .string()
    .required('급지내용은 필수 값 입니다.')
    .trim('급지내용은 필수 값 입니다.'),
  ct: yup.string().required('금액은 필수 값 입니다.'),
});

export function createAreaGradeStore(rootStore) {
  return makeAutoObservable({
    areaGradeList: initial.areaGradeList,
    areaGrade: initial.areaGrade,

    customerList: [], // 고객사 목록
    codeList: [],
    ctprvnList: [],
    signguList: [],
    emdList: [],

    alertMessage: '',

    async getAreaGradeList(options) {
      try {
        const res = await authApi.get(AREA_GRADE_URL, { ...options }, null);

        runInAction(() => {
          this.areaGradeList = res;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async deleteAreaGrade(areaGradeSeq) {
      try {
        await authApi.delete(`${AREA_GRADE_URL}/${areaGradeSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveAreaGrade({
      codeId,
      useAt,
      ctprvn,
      signgu,
      emd,
      areaGradeCodeNm,
      ct,
    }) {
      try {
        await authApi.post(AREA_GRADE_URL, {
          codeId,
          useAt,
          ctprvn,
          signgu,
          emd,
          areaGradeCodeNm,
          ct,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateAreaGrade(areaGradeSeq, { enterpriseSeq, areaGradeCodeNm, ct, useAt }) {
      try {
        await authApi.patch(`${AREA_GRADE_URL}/${areaGradeSeq}`, {
          enterpriseSeq,
          areaGradeCodeNm,
          ct,
          useAt,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async excelUpload(enterpriseSeq, file) {
      try {
        const formData = new FormData();

        formData.append('multipartFile', file);

        await authApi.fileUpload(`${AREA_GRADE_URL}/${enterpriseSeq}/upload`, formData);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCodeList() {
      try {
        const { data } = await authApi.get(
          `${AREA_GRADE_URL}/code`,
          null,
          null,
        );
        runInAction(() => {
          this.codeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCustomerList() {
      try {
        const { data } = await authApi.get(
          `${AREA_GRADE_URL}/support/customerList`,
          null,
          null,
        );
        runInAction(() => {
          this.customerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.customerList = [];
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCtprvnList() {
      try {
        const { data } = await authApi.get(
          `${AREA_GRADE_URL}/ctprvn`,
          null,
          null,
        );
        runInAction(() => {
          this.ctprvnList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getSignguList(ctprvn) {
      try {
        if (!ctprvn) {
          runInAction(() => {
            this.signguList = [];
          });
          return true;
        }
        const { data } = await authApi.get(
          `${AREA_GRADE_URL}/signgu`,
          { ctprvn },
          null,
        );
        runInAction(() => {
          this.signguList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getEmdList(signgu) {
      try {
        if (!signgu) {
          runInAction(() => {
            this.emdList = [];
          });
          return true;
        }
        const { data } = await authApi.get(
          `${AREA_GRADE_URL}/emd`,
          { signgu },
          null,
        );
        runInAction(() => {
          this.emdList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },

    setInitialSignguList() {
      runInAction(() => {
        this.signguList = [];
      });
    },

    setInitialEmdList() {
      runInAction(() => {
        this.emdList = [];
      });
    },
  });
}
