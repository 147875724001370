import { makeAutoObservable, runInAction } from 'mobx';

import * as yup from 'yup';
import { authApi } from '@helpers/AxiosHelper';
import { onlyNumber } from '@helpers/StringHelper';
import { MEMBER_PASSWORD_REGEX } from '@const/env';

const MYPAGE_URL = '/api/mypage';

const initial = {
  member: {
    memberSeq: '',
    id: '',
    nm: '',
    moblphonNo: '',
    email: '',
    psitnEnterpriseCode: '',
    psitnEnterpriseSeq: '',
    psitnEnterpriseName: '',
    ecnyDt: '',
    zip: '',
    adres: '',
    detailAdres: '',
    signguCode: '',
    legaldongCode: '',
    partclrMatter: '',
  },
};

export const memberValidationSchema = yup.object({
  // 이름, 핸드폰번호, 소속센터, 소속팀, 담당업무
  nm: yup.string().required('이름은 필수 입력 값 입니다.'),
  moblphonNo: yup
    .string()
    .test({
      message: '핸드폰 번호는 필수 입력 값 입니다.',
      test: (value) => {
        if (!onlyNumber(value.replaceAll('-', ''))) {
          return false;
        }
        return true;
      },
    })
    .required('핸드폰 번호는 필수 입력 값 입니다.'),
  psitnEnterpriseCode: yup
    .string()
    .required('소속 센터는 필수 선택 값 입니다.'),
});

export const passwordValidationSchema = yup.object({
  currentPassword: yup.string().required('현재 비밀번호는 필수 값 입니다.'),
  changePassword: yup
    .string()
    .matches(
      MEMBER_PASSWORD_REGEX,
      '비밀번호는 특수문자/문자/숫자 포함 형태의 8~15자리 이어야 합니다.',
    )
    .oneOf(
      [yup.ref('confirmChangePassword'), null],
      '비밀번호를 다시 확인해주세요.',
    )
    .required('변경할 비밀번호는 필수 값 입니다.'),
  confirmChangePassword: yup
    .string()
    .oneOf([yup.ref('changePassword'), null], '비밀번호를 다시 확인해주세요.')
    .required('변경 비밀번호 확인은 필수 값 입니다.'),
});

export function createPersonalInfoStore(rootStore) {
  return makeAutoObservable({
    member: initial.member,
    enterpriseList: [],
    psitnTeamList: [],
    chrgJobList: [],
    clsfList: [],
    rspofcList: [],

    alertMessage: '',

    async getMemberInfo() {
      // 회원정보 상세
      try {
        const { data } = await authApi.get(`${MYPAGE_URL}/update`, null, null);
        runInAction(() => {
          this.member = {
            ...data,
            psitnEnterpriseCode: data?.psitnEnterpriseSeq,
          };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateMemberInfo(params) {
      // 회원 정보 업데이트
      try {
        await authApi.patch(`${MYPAGE_URL}/update`, { ...params });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getEnterpriseList() {
      // 소속 센터
      try {
        const { data } = await authApi.get(
          `${MYPAGE_URL}/support/enterprise`,
          null,
          null,
        );
        runInAction(() => {
          this.enterpriseList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updatePassword({ changePassword, currentPassword }) {
      try {
        await authApi.put(`${MYPAGE_URL}/password`, {
          changePassword,
          currentPassword,
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async withdrawalMember() {
      try {
        await authApi.delete(`${MYPAGE_URL}/delete`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
