import { lazy } from 'react';

/**
 * Layout 목록
 */
const TabLayout = lazy(() =>
  import(/* webpackChunkName: "TabLayout" */ '@templates/TabLayout'),
);

/**
 * Routing Page 목록
 */
// 배정관리
// 배정관리 > 지역배정
const AreaListPage = lazy(() =>
  import(
    /* webpackChunkName: "AreaListPage" */ '@pages/admin/assignment/area/AreaListPage'
  ),
);

// 배정관리 > 품목배정
const ProductAssignment = lazy(() =>
  import(
    /* webpackChunkName: "ProductAssignment" */ '@pages/admin/assignment/product'
  ),
);

const getRoutes = () => {
  const routes = [
    {
      name: '지역배정',
      path: '/asign/area',
      component: AreaListPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '품목배정',
      path: '/asign/prdlst',
      component: ProductAssignment,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
  ];

  return routes;
};

export default getRoutes();
