// router module import
import mypage from '@router/module/user/mypage'; // 마이페이지
import setting from '@router/module/user/setting'; // 설정
import service from '@router/module/user/service'; // 서비스관리
import schedule from '@router/module/user/schedule'; // 스케쥴관리
import item from '@router/module/user/item'; // 자재관리
import community from '@router/module/user/community'; // 커뮤니티
import settlement from '@router/module/user/settlement'; // 정산

const getRoutes = () => {
  const routes = [
    ...mypage,
    ...service,
    ...schedule,
    ...item,
    ...community,
    ...settlement,
    ...setting,
  ];

  return routes;
};

export default getRoutes();
