import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import { isEmpty } from '@helpers/StringHelper';

const ORDER_ACTUAL_URL = '/api/item/order-actual';

export const initial = {
  orderActualList: {
    data: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },
};

// 실입고목록
export function createMaterialOrderActualStore(rootStore) {
  return makeAutoObservable({
    orderActualList: initial.orderActualList,

    supplierList: [], // 출고업체 목록

    alertMessage: '',

    async getOrderActualList(options) {
      try {
        const res = await authApi.get(
          ORDER_ACTUAL_URL,
          {
            ...options,
            prdno: isEmpty(options.prdno) ? null : options.prdno,
            prdnm: isEmpty(options.prdnm) ? null : options.prdnm,
          },
          null,
        );

        runInAction(() => {
          this.orderActualList = res;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getSupplierList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_ACTUAL_URL}/support/supplierList`,
          null,
          null,
        );

        runInAction(() => {
          this.supplierList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteOrderActuals(orderActuals) {
      try {
        await authApi.delete(ORDER_ACTUAL_URL, orderActuals);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
