import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

import * as yup from 'yup';
import {toHtml} from "@helpers/StringHelper";

const REFERENCE_URL = '/api/community/reference';

export const initial = {
  referenceList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  reference: {
    bbsSeq: '',
    writerMemberSeq: '',
    isEditable: 0,
    upendFixingAt: 0,
    bbsSj: '',
    bbsCn: '',
    regDt: '',
    atchmnfls: [],
    bbsAnswers: [],
  },
};

export const referenceValidationSchema = yup.object({
  bbsSj: yup.string().required('제목은 필수 입력 값 입니다.'),
  bbsCn: yup.string().required('내용은 필수 입력 값 입니다.'),
});

export function createCommunityReferenceStore() {
  return makeAutoObservable({
    referenceList: initial.referenceList,
    reference: initial.reference,

    alertMessage: '',

    async getReferenceList(options) {
      try {
        const res = await authApi.get(REFERENCE_URL, { ...options }, null);
        runInAction(() => {
          this.referenceList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getReference(bbsSeq) {
      try {
        const { data } = await authApi.get(
          `${REFERENCE_URL}/${bbsSeq}`,
          null,
          null,
        );
        runInAction(() => {
          this.reference = {
            ...data,
            bbsCn : toHtml(data.bbsCn)
          };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveReference(
      { bbsSj, bbsCn, upendFixingAt, url, changeFileNms },
      referenceFile,
    ) {
      try {
        const {
          data: { bbsSeq },
        } = await authApi.post(REFERENCE_URL, {
          bbsSj,
          bbsCn,
          changeFileNms,
          upendFixingAt: 0,
          url: null,
        });

        if (referenceFile.length > 0) {
          await this.referenceFileUpload(bbsSeq, referenceFile);
        }

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteReference(bbsSeq) {
      try {
        await authApi.delete(`${REFERENCE_URL}/${bbsSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateReference(
      bbsParam,
      { bbsSj, bbsCn, upendFixingAt, url, changeFileNms },
      referenceFiles,
      deleteFileSeq,
    ) {
      try {
        await authApi.patch(`${REFERENCE_URL}/${bbsParam}`, {
          bbsSj,
          bbsCn,
          changeFileNms,
          upendFixingAt: 0,
          url: null,
        });

        if (referenceFiles.length > 0) {
          await this.referenceFileUpload(bbsParam, referenceFiles);
        }

        if (deleteFileSeq.length > 0) {
          await this.referenceFileDelete(bbsParam, deleteFileSeq);
        }

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveReferenceComment(bbsSeq, { bbsAnswerCn }) {
      try {
        await authApi.post(`${REFERENCE_URL}/${bbsSeq}/answer`, {
          bbsAnswerCn,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async referenceFileUpload(bbsSeq, referenceFiles) {
      const formData = new FormData();

      // 다중 파일 업로드
      referenceFiles.map((referenceFile) => {
        formData.append('multipartFiles', referenceFile);
      });

      await authApi.fileUpload(`${REFERENCE_URL}/${bbsSeq}/atchmnfl`, formData);
    },

    async referenceFileDelete(bbsSeq, params) {
      await authApi.delete(`/api/community/common/bbs/${bbsSeq}/atchmnfl`, {
        atchmnflSeqs: params,
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
