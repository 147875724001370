import { lazy } from 'react';

/**
 * Layout 목록
 */
const SimpleLayout = lazy(() =>
  import(/* webpackChunkName: "SimpleLayout" */ '@templates/SimpleLayout'),
);

/**
 * Routing Page 목록
 */
// 관리자 메인 화면
const DashboardPage = lazy(() =>
  import(/* webpackChunkName: "DashboardPage" */ '@pages/common/dashboard'),
);
const routes = [
  {
    name: '메인 화면',
    path: '/',
    component: DashboardPage,
    layout: SimpleLayout,
    isAuth: true,
    isPc: true,
    isMobile: true,
    isBookmarked: false,
    permissions: [],
  },
];

export default routes;
