import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

const ORDER_LOG_URL = '/api/item/order-log';

export const initial = {
  orderLogList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  orderLogCount: {
    orderCount: 0,
    returnCount: 0,
  },

  orderList: [], // 주문 목록
  returnList: [], // 반납 목록

  orderLogDetailList: {
    // 상세페이지 목록
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },
};

// 주문/반납 내역
export function createMaterialOrderLogStore(rootStore) {
  return makeAutoObservable({
    orderLogList: initial.orderLogList,

    orderLogCount: initial.orderLogCount,

    orderList: initial.orderList,
    returnList: initial.returnList,

    orderLogDetailList: initial.orderLogDetailList,

    enterpriseList: [], // 업체 목록
    categoryList: [], // 카테고리 목록
    returnPrvonshCodeList: [], // 반납사유 코드 목록
    unDlivyPrvonshCodeList: [], // 미출고 사유 코드 목록
    opetrMemberList: [], // 신청자리스트

    alertMessage: '',

    // 목록 조회
    async getOrderLogList(options) {
      try {
        const res = await authApi.get(
          ORDER_LOG_URL,
          {
            ...options,
          },
          null,
        );

        runInAction(() => {
          this.orderLogList = res;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getListInfoCount() {
      try {
        const { data } = await authApi.get(
          `${ORDER_LOG_URL}/listInfoCount`,
          null,
          null,
        );

        runInAction(() => {
          this.orderLogCount = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getOrderList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_LOG_URL}/orderList`,
          null,
          null,
        );

        runInAction(() => {
          this.orderList = data ?? [];
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getReturnList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_LOG_URL}/returnList`,
          null,
          null,
        );

        runInAction(() => {
          this.returnList = data ?? [];
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 상태 변경 처리
    async updateOrderLog(params) {
      // [
      //   {
      //     "confmQy": 10,
      //     "memo": "메모내용",
      //     "orderSeq": 1,
      //     "orderSttus": 1,
      //     "rturnPrvonshCode": 93,
      //     "unDlivyPrvonshCode": 96
      //   }
      // ]
      try {
        await authApi.put(`${ORDER_LOG_URL}/change`, params);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getOrderLogDetailList(itemSeq, options) {
      try {
        const res = await authApi.get(
          `${ORDER_LOG_URL}/${itemSeq}`,
          { ...options },
          null,
        );

        runInAction(() => {
          this.orderLogDetailList = res;
        });

        return true;
      } catch (e) {
        runInAction(() => {
          this.alertMessage =
            e?.data?.data?.alertMessage ||
            '데이터 조회 중 문제가 발생했습니다. 잠시 후 다시 시도해주세요';
        });
        return false;
      }
    },

    // 상세 변경 처리
    // 상세 코드 데이터

    // 업체목록
    async getEnterpriseList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_LOG_URL}/support/customerList`,
          null,
          null,
        );
        runInAction(() => {
          this.enterpriseList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 카테고리 목록
    async getCategoryList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_LOG_URL}/support/categoryList`,
          null,
          null,
        );

        runInAction(() => {
          this.categoryList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async getReturnPrvonshCodeList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_LOG_URL}/support/rturnPrvonshCodeList`,
          null,
          null,
        );
        runInAction(() => {
          this.returnPrvonshCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async getUnDlivyPrvonshCodeList() {
      try {
        const { data } = await authApi.get(
          `${ORDER_LOG_URL}/support/unDlivyPrvonshCodeList`,
          null,
          null,
        );

        runInAction(() => {
          this.unDlivyPrvonshCodeList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getOpetrMemberList(enterpriseSeq) {
      try {
        const { data } = await authApi.get(
          `${ORDER_LOG_URL}/support/opetrMemberList`,
          { enterpriseSeq },
          null,
        );
        runInAction(() => {
          this.opetrMemberList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
