import axios from 'axios';

import { rootStore } from '@stores/';
import {
  ACCESS_TOKEN,
  AUTO_LOGIN,
  LOGIN_MEMBER_INFO,
  REFRESH_TOKEN,
  TOKEN_PREFIX,
} from '@const/env';
import {
  getSessionStorage,
  removeLocalStorage,
  removeSessionStorage,
} from '@helpers/StorageHelper';

let isTokenRefreshing = false; // 토큰 재발행 상태값
let refreshSubscribers = []; // 토큰 재발행중 지연되었던 요청 저장

/**
 * AccessToken 재발행중 지연되었던 요청 진행
 * @param accessToken
 */
const onTokenRefreshed = (accessToken) => {
  refreshSubscribers.map((callback) => callback(accessToken));
  refreshSubscribers = [];
};

/**
 * AccessToken 재발행중 지연되었던 요청 저장
 * @param callback
 */
const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

export const createInstance = () => {
  const config = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 100000,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return axios.create(config);
};

/**
 * Axios 통신 Success Handler
 * @param res
 * @param callback
 * @returns {*}
 */
const successHandler = (response) => {
  // console.log('successHandler : {}', response);
  const {
    data: { data, pageable },
  } = response;
  return { data, pageable };
};

/**
 * Axios 통신 Error Handler
 * @param error
 * @param callback
 * @returns {*}
 */
const errorHandler = (error) => {
  const basicError = {
    config: error?.config,
    data: {
      success: false,
      data: {
        alertMessage: '문제가 발생했습니다. 잠시 후 다시 시도해주세요.',
        code: 'ERR_NETWORK',
        message: 'Network Error',
        path: error.config.url,
      },
    },
    headers: null,
    request: error?.request,
    status: 500,
    statusText: 'Internal Server Error',
  };

  const errorResponse = error?.response?.data;
  if (!errorResponse) {
    // Network에러
    throw basicError;
  }
  throw { ...basicError, ...error.response };
};

/**
 * Axios Interceptor 설정
 * @param axiosInstance
 * @param isAuth
 * @param isLoading
 */
const axiosInstanceInterceptors = (axiosInstance, isAuth, isLoading) => {
  loadingShow(isLoading, true);

  // Request 요청 전 Interceptor 처리
  axiosInstance.interceptors.request.use(
    async (config) => {
      // console.log('[axiosInterceptor] [request] : {}', config);
      // 인증 여부
      if (isAuth) {
        // 토큰 정보 추출
        const accessToken = getSessionStorage(ACCESS_TOKEN);

        config.withCredentials = true;
        config.headers.Authorization = `${TOKEN_PREFIX} ${accessToken}`;
      } else {
        config.withCredentials = false;
      }
      return config;
    },
    (error) => {
      // console.error('[error] [axiosInterceptor] [request]', error);
      return Promise.reject(error);
    },
  );

  // Response 응답 전 Interceptor 처리
  axiosInstance.interceptors.response.use(
    async (response) => {
      // console.log('[axiosInterceptor] [response] : {}', response);
      return response;
    },
    async (error) => {
      // console.log('[error] [axiosInterceptor] [response] : {}', error);
      const { config, response } = error;

      // response === undefined 면 network error
      const status = response?.status;

      if (status === 401) {
        // 인증 실패로 인해 Error 발생시

        const originalRequest = config;

        // Token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
        // 이렇게 하지 않을경우 토큰 재발행 중복적으로 호출됨
        const retryOriginalRequest = new Promise((resolve) => {
          addRefreshSubscriber((accessToken) => {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            // resolve(authApi[originalRequest.method](originalRequest));
            resolve(axios(originalRequest));
          });
        });

        if (!isTokenRefreshing) {
          // Refresh Token 재발행 요청 중
          isTokenRefreshing = true;
          const { loginStore } = rootStore;

          // 토큰 재발행 요청
          const result = await loginStore.tokenReissue();

          if (!result) {
            removeSessionStorage(ACCESS_TOKEN);
            removeSessionStorage(LOGIN_MEMBER_INFO);
            removeLocalStorage(REFRESH_TOKEN);
            removeLocalStorage(AUTO_LOGIN);
            alert('문제가 발생 했습니다. 다시 로그인 해주세요.');
            location.href = '/login';
            return Promise.reject(error);
          }
          isTokenRefreshing = false;

          const { accessToken } = loginStore.auth;

          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

          // 갱신된 accessToken을 이용해 지연되었던 요청 진행
          onTokenRefreshed(accessToken);
        }
        return retryOriginalRequest;
      }
      return Promise.reject(error);
    },
  );
};

/**
 * Axios
 * @type {{patch: ((function(*, *, *, *, *): Promise<*|undefined>)|*), post: ((function(*, *, *, *, *): Promise<*|undefined>)|*), get: ((function(*, *, *, *, *, *): Promise<*|undefined>)|*), delete: ((function(*, *, *, *, *): Promise<*|undefined>)|*)}}
 */
const axiosApiCall = {
  get: async (url, params, options, isLoading, isAuth) => {
    try {
      const axiosInstance = createInstance();
      axiosInstanceInterceptors(axiosInstance, isAuth, isLoading);
      const response = await axiosInstance.get(url, {
        params,
        responseType: 'json',
        responseEncoding: 'utf8',
        ...options,
      });

      const {
        status,
        data: { success },
      } = response;
      if (!status || status < 200 || status >= 300 || !success) {
        // console.log(response);
        // throw Object.assign(new Error(), {
        //   ...response,
        //   status: 500,
        //   statusText: 'Internal Server Error',
        // });
        throw response;
      } else {
        return successHandler(response);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      loadingShow(isLoading);
    }
  },

  post: async (url, params, isLoading, isAuth) => {
    try {
      const axiosInstance = createInstance();
      axiosInstanceInterceptors(axiosInstance, isAuth, isLoading);
      const response = await axiosInstance.post(url, params);
      const {
        status,
        data: { success },
      } = response;
      if (!status || status < 200 || status >= 300 || !success) {
        throw response;
      }
      return successHandler(response);
    } catch (error) {
      errorHandler(error);
    } finally {
      loadingShow(isLoading);
    }
  },

  patch: async (url, params, isLoading, isAuth) => {
    try {
      const axiosInstance = createInstance();
      axiosInstanceInterceptors(axiosInstance, isAuth, isLoading);
      const response = await axiosInstance.patch(url, params);
      const {
        status,
        data: { success },
      } = response;
      if (!status || status < 200 || status >= 300 || !success) {
        // throw Object.assign(new Error(), {
        //   ...response,
        //   status: 500,
        //   statusText: 'Internal Server Error',
        // });
        throw response;
      }
      return successHandler(response);
    } catch (error) {
      errorHandler(error);
    } finally {
      loadingShow(isLoading);
    }
  },

  put: async (url, params, isLoading, isAuth) => {
    try {
      const axiosInstance = createInstance();
      axiosInstanceInterceptors(axiosInstance, isAuth, isLoading);
      const response = await axiosInstance.put(url, params);
      const {
        status,
        data: { success },
      } = response;
      if (!status || status < 200 || status >= 300 || !success) {
        // throw Object.assign(new Error(), {
        //   ...response,
        //   status: 500,
        //   statusText: 'Internal Server Error',
        // });
        throw response;
      }
      return successHandler(response);
    } catch (error) {
      errorHandler(error);
    } finally {
      loadingShow(isLoading);
    }
  },

  delete: async (url, params, isLoading, isAuth) => {
    try {
      const axiosInstance = createInstance();
      axiosInstanceInterceptors(axiosInstance, isAuth, isLoading);
      const response = await axiosInstance.delete(url, { data: params });
      const {
        status,
        data: { success },
      } = response;
      if (!status || status < 200 || status >= 300 || !success) {
        throw Object.assign(new Error(), {
          ...response,
          status: 500,
          statusText: 'Internal Server Error',
        });
      }
      return successHandler(response);
    } catch (error) {
      return errorHandler(error);
    } finally {
      loadingShow(isLoading);
    }
  },
  fileDown: async (url, params, isAuth, isLoading) => {
    try {
      const axiosInstance = createInstance();
      axiosInstanceInterceptors(axiosInstance, isAuth, isLoading);
      const response = await axiosInstance.get(url, {
        params,
        responseType: 'arraybuffer',
      });
      return response;
    } catch (error) {
      return errorHandler(error);
    } finally {
      loadingShow(true);
    }
  },
  fileUploadPost: async (url, params, isAuth, isLoading) => {
    try {
      const axiosInstance = createInstance();
      axiosInstanceInterceptors(axiosInstance, isAuth, isLoading);
      const response = await axiosInstance.post(url, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      const {
        status,
        data: { success },
      } = response;
      if (!status || status < 200 || status >= 300 || !success) {
        throw Object.assign(new Error(), {
          ...response,
          status: 500,
          statusText: 'Internal Server Error',
        });
      }
      return successHandler(response);
    } catch (error) {
      return errorHandler(error);
    } finally {
      loadingShow(isLoading);
    }
  },

  fileUpload: async (url, params, isAuth, isLoading) => {
    try {
      const axiosInstance = createInstance();
      axiosInstanceInterceptors(axiosInstance, isAuth, isLoading);
      const response = await axiosInstance.put(url, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      const {
        status,
        data: { success },
      } = response;
      if (!status || status < 200 || status >= 300 || !success) {
        throw Object.assign(new Error(), {
          ...response,
          status: 500,
          statusText: 'Internal Server Error',
        });
      }
      return successHandler(response);
    } catch (error) {
      return errorHandler(error);
    } finally {
      loadingShow(isLoading);
    }
  },
};

/**
 * Axios 요청(인증 X)
 */
export const defaultApi = {
  get: async (url, params, options, isLoading = true) => {
    try {
      const axiosInstance = createInstance();
      const response = await axiosInstance.get(url, {
        params,
        responseType: 'json',
        responseEncoding: 'utf8',
      });

      const {
        status,
        data: { success },
      } = response;
      if (!status || status < 200 || status >= 300 || !success) {
        // 요청 실패
        throw response;
      }
      return successHandler(response);
    } catch (error) {
      errorHandler(error);
    }
  },

  post: async (url, params, isLoading = true) => {
    try {
      const axiosInstance = createInstance();
      const response = await axiosInstance.post(url, params);
      const {
        status,
        data: { success },
      } = response;
      if (!status || status < 200 || status >= 300 || !success) {
        throw response;
      }
      return successHandler(response);
    } catch (error) {
      errorHandler(error);
    }
  },
};

/**
 * Axios 요청(인증 O)
 */
export const authApi = {
  get: (url, params, options, isLoading = true) =>
    axiosApiCall.get(url, params, options, isLoading, true),

  post: (url, params, isLoading = true) =>
    axiosApiCall.post(url, params, isLoading, true),

  patch: (url, params, isLoading = true) =>
    axiosApiCall.patch(url, params, isLoading, true),

  put: (url, params, isLoading = true) =>
    axiosApiCall.put(url, params, isLoading, true),

  delete: (url, params, isLoading = true) =>
    axiosApiCall.delete(url, params, isLoading, true),

  fileDown: (url, params, isAuth = true, isLoading = true) =>
    axiosApiCall.fileDown(url, params, isAuth, isLoading),

  fileUploadPost: async (url, params, isAuth = true, isLoading = true) =>
    axiosApiCall.fileUploadPost(url, params, isAuth, isLoading),

  fileUpload: async (url, params, isAuth = true, isLoading = true) =>
    axiosApiCall.fileUpload(url, params, isAuth, isLoading),
};

/**
 * Loading 상태 변환
 * @param isLoading : boolean - 로딩 사용유무, true: 사용 false : 미사용
 * @param status : boolean - true: 나타냄, false : 숨김
 */
const loadingShow = (isLoading, status = false) => {
  if (isLoading) {
    const { globalStore } = rootStore;
    globalStore.setLoading(status);
  }
};
