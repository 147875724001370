import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';


const MATERIAL_URL = '/api/materialcode/material'

export const initial = {
  materialList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  material: {
    itemSeq: null,
    enterpriseSeq: null,
    enterpriseNm: null,
    itemSe: null,
    categoryCodeSeq: null,
    categoryCodeNm: null,
    modelNm: null,
    prdno: null,
    prdnm: null,
    mainModelNm: null,
    models: [null, null, null, null, null],
    brandCodeSeq: null,
    brandCodeNm: null,
    suplyUntpc: null,
    cnsmrPc: null,
    servicePc: null,
    prdctnDt: null,
    assrncPd: null,
    partclrMatter: null,
    actvtySe: 0,
    atchmnfl: {},
    model1: null,
  },
};

export const materialValidationSchema = yup.object({
  enterpriseSeq: yup.string().required('공급처는 필수 선택 값 입니다.'),
  itemSe: yup.string().required('자재구분은 필수 선택 값 입니다.'),
  categoryCodeSeq: yup.string().required('카테고리는 필수 선택 값 입니다.'),
  modelNm: yup.string().required('제품명은 필수 입력 값 입니다.'),
  prdno: yup.string().required('품번은 필수 입력 값 입니다.'),
  prdnm: yup.string().required('품명은 필수 입력 값 입니다.'),
  model1: yup.string().required('주모델은 필수 선택 값 입니다.').trim('주모델은 필수 선택 값 입니다.'),
  suplyUntpc: yup.string().required('공급가는 필수 입력 값 입니다.'),
  cnsmrPc: yup.string().required('소비자가는 필수 입력 값 입니다.'),
  servicePc: yup.string().required('공임비는 필수 입력 값 입니다.'),
  actvtySe : yup.string().required('활성화 구분은 필수 선택 값 입니다.')
});

export function createMaterialStore(rootStore) {
  return makeAutoObservable({
    materialList: initial.materialList,
    material: initial.material,

    categoryList: [],
    enterpriseList: [],
    chargerNm: '',
    modelList: [],
    brandList: [],

    alertMessage: '',

    async getMaterialList(options) {
      try {
        const res = await authApi.get(
          MATERIAL_URL,
          { ...options },
          null,
        );

        runInAction(() => {
          this.materialList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.materialList = initial.materialList;
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async getMaterial(itemSeq) {
      try {
        const { data } = await authApi.get(
          `${MATERIAL_URL}/${itemSeq}`,
          null,
          null,
        );

        runInAction(() => {
          let model = {};
          data.models.map((item, index) => {
            model[`model${index+1}`] = item?.itemSeq;
          });
          this.material = { ...data, ...model };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.material = initial.material;
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveMaterial(params, file) {
      try {
        const {
          data: { itemSeq },
        } = await authApi.post(MATERIAL_URL, params);

        if (file) {
          await this.materialFileUpload(itemSeq, file);
        }
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async excelUpload(file) {
      try {
        const formData = new FormData();

        formData.append('multipartFile', file);

        await authApi.fileUpload(
            `${MATERIAL_URL}/upload`,
            formData,
        );
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateMaterial(itemSeq, params, file) {
      try {
        await authApi.patch(`${MATERIAL_URL}/${itemSeq}`, params);

        if (file) {
          await this.materialFileUpload(itemSeq, file);
        }
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteMaterial(itemSeq) {
      try {
        await authApi.delete(`${MATERIAL_URL}/${itemSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async materialFileUpload(itemSeq, params) {
      try {
        const formData = new FormData();
        formData.append('multipartFile', params);

        await authApi.fileUpload(
          `${MATERIAL_URL}/${itemSeq}/image`,
          formData,
        );
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 코드값
    async getCategoryList() {
      try {
        const { data } = await authApi.get(
          `${MATERIAL_URL}/support/category`,
          null,
          null,
        );
        runInAction(() => {
          this.categoryList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async getEnterpriseList() {
      try {
        const { data } = await authApi.get(
          `${MATERIAL_URL}/support/enterprise`,
          null,
          null,
        );
        runInAction(() => {
          this.enterpriseList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getEnterpriseCharger(enterpriseSeq) {
      try {
        const {
          data: { chargerNm },
        } = await authApi.get(
          `${MATERIAL_URL}/support/enterprise-charger`,
          { enterpriseSeq },
          null,
        );
        runInAction(() => {
          this.chargerNm = chargerNm;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getBrandList() {
      try {
        const { data } = await authApi.get(
          `${MATERIAL_URL}/support/brand`,
          null,
          null,
        );
        runInAction(() => {
          this.brandList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getModelList(enterpriseSeq, categoryCodeSeq) {
      try {
        const { data } = await authApi.get(
          `${MATERIAL_URL}/support/model`,
          { enterpriseSeq, categoryCodeSeq },
          null,
        );
        runInAction(() => {
          this.modelList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    initialModelList() {
      runInAction(() => {
        this.modelList = [];
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
