export const API_URL = process.env.REACT_APP_API_URL;
export const PHASE = process.env.REACT_APP_PHASE;

export const ASSETS_COMMON_PATH = `${window.location.origin}/images/common`;

export const TOKEN_PREFIX = 'Bearer';

export const ACCESS_TOKEN = 'access-token';
export const ACCESS_TOKEN_EXPIRES_IN = 10 * 60; // 10분
export const REFRESH_TOKEN = 'refresh-token';
export const REFRESH_TOKEN_EXPIRES_IN = 30 * 24 * 60 * 60; // 30일

export const LOGIN_MEMBER_INFO = 'login-member';
export const LOGIN_MEMBER_AUTH = 'login-member-auth';

export const AUTO_LOGIN = 'auto-login';

// "SUPER_ADMIN", 1, "최고관리자"    // 본사
// "ADMIN", 2, "관리자"                // 본사
// "ENTERPRISE", 3, "고객사/제조사/공급처"
// "CENTER_HEAD", 4, "센터장"  // 센터
// "ENGINEER", 5, "일반사용자" // 센터
export const SUPER_ADMIN_AUTHOR_GROUP_SEQ = [1];
export const ADMIN_AUTHOR_GROUP_SEQ = [1, 2];
export const ENTERPRISE_AUTHOR_GROUP_SEQ = [3];
export const CENTER_HEAD_AUTHOR_GROUP_SEQ = [4];
export const ENGINEER_AUTHOR_GROUP_SEQ = [5];

export const MOBILE_MENU = 'M';
export const PC_MENU = 'PC';

export const MEMBER_ID_REGEX = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{6,16}$/;
export const MEMBER_PASSWORD_REGEX =
  /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/;