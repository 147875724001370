import { lazy } from 'react';

/**
 * Layout 목록
 */
const SimpleLayout = lazy(() =>
  import(/* webpackChunkName: "MainLayout" */ '@templates/SimpleLayout'),
);

/**
 * Routing Page 목록
 */
// 마이페이지
const Mypage = lazy(() =>
  import(/* webpackChunkName: "Mypage" */ '@pages/user/mypage'),
);

const InquiryViewPage = lazy(() =>
  import(
    /* webpackChunkName: "inquiryViewPage" */ '@pages/user/mypage/inquiry/InquiryViewPage'
  ),
);

const InquiryFormPage = lazy(() =>
  import(
    /* webpackChunkName: "inquiryViewPage" */ '@pages/user/mypage/inquiry/InquiryFormPage'
  ),
);
const WithdrawalPage = lazy(() =>
  import(
    /* webpackChunkName: "WithdrawalPage" */ '@pages/user/mypage/personal-info/withdrawal'
  ),
);

const getRoutes = () => {
  const routes = [
    {
      name: '마이페이지',
      path: '/mypage/:tabName',
      component: Mypage,
      layout: SimpleLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '회원탈퇴',
      path: '/mypage/withdrawal',
      component: WithdrawalPage,
      layout: SimpleLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: false,
      permissions: [],
    },
    {
      name: '1:1문의상세',
      path: '/mypage/inquiry/view/:bbsSeq',
      component: InquiryViewPage,
      layout: SimpleLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '1:1 등록',
      path: '/mypage/inquiry/form',
      component: InquiryFormPage,
      layout: SimpleLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '1:1 수정',
      path: '/mypage/inquiry/form/:bbsSeq',
      component: InquiryFormPage,
      layout: SimpleLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
  ];

  return routes;
};

export default getRoutes();
