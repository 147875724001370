import { lazy } from 'react';

/**
 * Layout 목록
 */
const TabLayout = lazy(() =>
  import(/* webpackChunkName: "TabLayout" */ '@templates/TabLayout'),
);

/**
 * Routing Page 목록
 */
// 정산/통계
// 정산자료 리스트 페이지
const SettlementPage = lazy(() =>
  import(
    /* webpackChunkName: "SettlementPage" */ '@pages/admin/statistics/settlement'
  ),
);

// 정산자료 상세 페이지
const SettlementViewPage = lazy(() =>
  import(
    /* webpackChunkName: "SettlementViewPage" */ '@pages/admin/statistics/settlement/SettlementViewPage'
  ),
);

// 수수료관리
const FeeManagePage = lazy(() =>
  import(/* webpackChunkName: "FeeManagePage" */ '@pages/admin/statistics/fee'),
);

// 접수현황 통계
const ReceptionPage = lazy(() =>
  import(
    /* webpackChunkName: "ReceptionPage" */ '@pages/admin/statistics/reception'
  ),
);

// 완료/컨택률 통계
const ServiceInfoPage = lazy(() =>
  import(
    /* webpackChunkName: "ServiceInfoPage" */ '@pages/admin/statistics/service-info'
  ),
);

// 자재사용 통계
const ItemStatisticsPage = lazy(() =>
  import(
    /* webpackChunkName: "ItemStatisticsPage" */ '@pages/admin/statistics/item'
  ),
);

// 모델별부품고장 통계
const ModelDefectListPage = lazy(() =>
  import(
    /* webpackChunkName: "ModelDefectListPage" */ '@pages/admin/statistics/model-defect'
  ),
);

// 자재/코드관리

const getRoutes = () => {
  const routes = [
    {
      name: '정산자료',
      path: '/statistics/settlement',
      component: SettlementPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '정산자료 상세',
      path: '/statistics/settlement/view',
      component: SettlementViewPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '수수료관리',
      path: '/statistics/fee',
      component: FeeManagePage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '접수현황 통계',
      path: '/statistics/reception',
      component: ReceptionPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '완료/컨택률 통계',
      path: '/statistics/service-info',
      component: ServiceInfoPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '자재사용 통계',
      path: '/statistics/item',
      component: ItemStatisticsPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '모델별부품고장 통계',
      path: '/statistics/model-defect',
      component: ModelDefectListPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
  ];

  return routes;
};

export default getRoutes();
