import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import { isEmpty, onlyNumber } from '@helpers/StringHelper';

import * as yup from 'yup';
import { getToday } from '@helpers/DateHelper';
import { isMobile, isMobileApp } from '@helpers/EnvHelper';

const OUTSTANDING_URL = '/api/service-info/reception-log/outstanding';

export const initial = {
  outstandingList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 20,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  outstanding: {
    serviceInfoSeq: null,
    serviceSe: null,
    rceptSeq: '',
    customerNm: '',
    cttpc: '',
    zip: '',
    adres: '',
    detailAdres: '',
    signguCode: null,
    legaldongCode: null,
    rceptCo: null,
    rcepterMemberSeq: null,
    rcepterMemberNm: '',
    rceptDt: '',
    resveDt: '',
    btrps: 0,
    rceptSe: null,
    rceptTyCode: null,
    rceptTyCodeNm: null,
    serviceSttus: null,
    customerCanclPrvonsh: null,
    setleSe: null,
    setleAmount: 0,
    categorySeq: null,
    categoryCodeNm: null,
    supplierSeq: null,
    supplierNm: null,
    ctSe: null,
    ct: 0,
    serialNo: null,
    purchsDt: null,
    defectSymptmsCode: null,
    defectSymptmsCodeNm: null,
    inqryRceptCn: null,
    cnsltRepairCn: null,
    partclrMatter: null,
    cmptncEnterpriseSeq: null,
    cmptncEnterpriseNm: null,
    opetrMemberSeq: null,
    opetrMemberNm: null,
    processTotCt: 0,
    processSetleAmount: 0,
    processBtrps: 0,
    processInstlUntpc: 0,
    processCnsmrPc: 0,
    processAreaGradeCt: 0,
    processAsPc: 0,
    processWorkAmount: 0,
    processAditAmount: 0,
    processWorkAmountCode: [],
    processAditAmountCode: [],
    processAditAmountCodeNm: null,
    processAditTotalAmount: 0,
    processSetleSe: null,
    processEtcamt: 0, // 기타금액
    processCardConfmSeq: null,
    processDscntAditPrvonsh: null,
    processDt: null,
    processSttusCode: null,
    updateLogList: [],

    itemList: [],

    modelList: [],
    itemSeqs: null,
    useQys: [],
    modelNm: '',

    imageList: [],
    imageFile: '',
    signFile: null,
    originalResveDt: null,
  },

  outstandingComplete: {
    // 등록정보
    // modelList: [],
    // processSttusCode: null,
    processDt: getToday(),
    // setleSe: null,
    // imageList: [],
    // imageFile: '',
    // inqryRceptCn: '',
    // partclrMatter: '',
    // cnsltRepairCn: '',

    // 처리자자재
    // itemList: [],

    // 결제금액
    processTotCt: 0, // 총비용
    processSetleAmount: 0, // 결제금액
    processWorkAmountCode: [], // 공임비 코드
    processWorkAmount: 0, // 처리 공임비
    processAditAmountCode: [], // 추가비용 코드
    processAditAmount: 0, // 처리 추가비용
    processAditTotalAmount: 0, // 추가금액(공임비+추가비용)

    processAreaGradeCt: 0, // 급지비용

    // rceptSe 1 - 설치
    processInstlUntpc: 0, // 처리 설치 단가

    // rceptSe 2 - A/S
    processBtrps: 0, // 출장비 상세 btrps
    processAsPc: 0, // 처리 AS단가
    processCnsmrPc: 0, // 처리소비자가(=부품비, searchItemList - cnsmrPc)

    processCardConfmSeq: '',
    processSetleSe: null,
    processDscntAditPrvonsh: null,

    // 결제 필드
    // 현금결제금액
    processCodAmount: 0,
    // 현금영수증 신청 여부
    cashRciptReqstAt: '',
    // 현금영수증 발행 타입(1:개인소득공제용, 2:사업자증빙용)
    cashRciptSe: '',
    // 현금영수증신청번호
    cashRciptSeq: '',
    // 할부기간
    processInstlmtPd: 1,
    // 실제 카드 결제금액
    cardProcessTotCt: 0,
  },

  pageOptions: {},
  itemOptions: {
    searchKeyword: '',
  },
};

export const receptionLogModifyValidationSchema = yup.object({
  // 고객정보
  customerNm: yup.string().required('고객명은 필수 입력 값 입니다.'),
  cttpc: yup.string().required('전화번호는 필수 입력 값 입니다.'),
  zip: yup.string().required('주소는 필수 입력 값 입니다.'),
  adres: yup.string().required('주소는 필수 입력 값 입니다.'),
  detailAdres: yup.string().required('상세주소는 필수 입력 값 입니다.'),

  // 1. 등록정보
  rceptSe: yup.string().required('접수구분은 필수 선택 값 입니다.'),
  rceptTyCode: yup.string().required('접수유형은 필수 선택 값 입니다.'),

  // 모델
  modelNm: yup.string().when(['itemSeqs', 'addModel'], (values, schema) => {
    const [itemSeqs, addModel] = values;
    if (
      (itemSeqs === null || itemSeqs.length === 0) &&
      (!addModel?.itemSeq || !addModel?.useQy)
    ) {
      return yup.string().required('모델을 하나 이상 추가 해주세요.');
    }
    return schema.nullable();
  }),
  // itemSeqs: yup
  //   .array()
  //   .of(yup.object().required('모델명은 필수 선택 값 입니다.')),
  // useQys: yup
  //   .array()
  //   .of(
  //     yup
  //       .number()
  //       .required('수량은 필수 입력 값 입니다.')
  //       .min(1, '최소 1개 이상 입력해주세요'),
  //   ),

  // serialNo: yup.string().required('시리얼넘버는 필수 입력 값 입니다.'),
  // purchsDt: yup.string().required('구입일자는 필수 선택 값 입니다.'),
  serviceSttus: yup.string().required('서비스상태 필수 선택 값 입니다.'),
  setleSe: yup.string().required('결제 구분은 필수 선택 값입니다.'),

  // 이미지 파일
  // imageFile: yup.string().when('imageList', (imageList, schema) => {
  //   if (imageList[0].length === 0) {
  //     return yup.string().required('이미지 파일을 하나 이상 추가 해주세요.');
  //   }
  //
  //   return schema.nullable();
  // }),

  // inqryRceptCn: yup.string().required('상세내용은 필수 입력 값입니다.'),
  // partclrMatter: yup.string().required('특이사항은 필수 입력 값 입니다.'),

  // 2. 이관정보
  cmptncEnterpriseSeq: yup.string().required('이관센터는 필수 선택 값 입니다.'),
  opetrMemberSeq: yup.string().required('처리자는 필수 선택 값 입니다.'),
  customerCanclPrvonsh: yup
    .string()
    .when('serviceSttus', (serviceSttus, schema) => {
      if (serviceSttus == 3) {
        return yup.string().required('취소 사유는 필수 입력 값 입니다.');
      }
      return schema.nullable();
    }),

  // 예약 일자, 예약 시간
  // resveDt: yup.string().required('예약일자는 필수 선택 값 입니다.'),
  // resveHours: yup
  //   .string()
  //   .required('예약시간은 필수 선택 값 입니다.')
  //   .trim('예약시간은 필수 선택 값 입니다.'),
  // resveMinutes: yup
  //   .string()
  //   .required('예약시간은 필수 선택 값 입니다.')
  //   .trim('예약시간은 필수 선택 값 입니다.'),
});

export const receptionLogModifyValidationAlert = (values) => {
  let error = {};

  if (isEmpty(values.customerNm) || values.customerNm === '') {
    error.customerNm = '고객명은 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.cttpc) || values.cttpc === '') {
    error.cttpc = '전화번호는 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.zip) || values.zip === '') {
    error.zip = '주소는 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.adres) || values.adres === '') {
    error.adres = '주소는 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.detailAdres) || values.detailAdres === '') {
    error.detailAdres = '상세주소는 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.rceptSe) || values.rceptSe === '') {
    error.rceptSe = '접수구분은 필수 선택 값 입니다.';
    return error;
  }

  if (isEmpty(values.rceptTyCode) || values.rceptTyCode === '') {
    error.rceptTyCode = '접수유형은 필수 선택 값 입니다.';
    return error;
  }

  if (
    (!values.itemSeqs || values.itemSeqs.length === 0) &&
    (!values.addModel?.itemSeq || !values.addModel?.useQy)
  ) {
    error.modelNm = '모델을 하나 이상 추가 해주세요.';
    return error;
  }

  if (isEmpty(values.serviceSttus) || values.serviceSttus === '') {
    error.serviceSttus = '서비스상태 필수 선택 값 입니다.';
    return error;
  }

  if (isEmpty(values.setleSe) || values.setleSe === '') {
    error.setleSe = '결제 구분은 필수 선택 값입니다.';
    return error;
  }

  if (
    isEmpty(values.cmptncEnterpriseSeq) ||
    values.cmptncEnterpriseSeq === ''
  ) {
    error.cmptncEnterpriseSeq = '이관센터는 필수 선택 값 입니다.';
    return error;
  }

  if (isEmpty(values.opetrMemberSeq) || values.opetrMemberSeq === '') {
    error.opetrMemberSeq = '처리자는 필수 선택 값 입니다.';
    return error;
  }

  if (
    values.serviceSttus === 3 &&
    (!values.customerCanclPrvonsh || values.customerCanclPrvonsh.trim() === '')
  ) {
    error.customerCanclPrvonsh = '취소 사유는 필수 입력 값 입니다.';
    return error;
  }

  return error;
};

export const receptionLogCompleteValidationSchema = yup.object({
  // 1. 등록정보
  // 모델
  modelNm: yup.string().when(['itemSeqs', 'addModel'], (values, schema) => {
    const [itemSeqs, addModel] = values;
    if (
      (itemSeqs === null || itemSeqs.length === 0) &&
      (!addModel?.itemSeq || !addModel?.useQy)
    ) {
      return yup.string().required('모델을 하나 이상 추가 해주세요.');
    }
    return schema;
  }),
  // itemSeqs: yup
  //   .array()
  //   .of(yup.object().required('모델명은 필수 선택 값 입니다.')),
  // useQys: yup
  //   .array()
  //   .of(
  //     yup
  //       .number()
  //       .required('수량은 필수 입력 값 입니다.')
  //       .min(1, '최소 1개 이상 입력해주세요'),
  //   ),
  processSttusCode: yup.string().required('처리코드는 필수 선택 값입니다.'),
  processDt: yup.string().required('처리일자는 필수 선택 값입니다.'),
  setleSe: yup.string().required('결제구분은 필수 선택 값입니다.'),
  // setleAmount: yup.string().when('setleSe', (setleSe = '', schema) => {
  //   if (setleSe == '2') {
  //     return yup
  //       .number()
  //       .min(1, '현장결제 경우 결제 비용은 필수 입력 사항입니다.')
  //       .required('현장결제 경우 결제 비용은 필수 입력 사항입니다.');
  //   }
  //   return schema.nullable();
  // }),

  // 이미지 파일
  // imageFile: yup.string().when('imageList', (imageList, schema) => {
  //   if (imageList[0].length === 0) {
  //     return yup.string().required('이미지 파일을 하나 이상 추가 해주세요.');
  //   }
  //
  //   return schema.nullable();
  // }),

  // inqryRceptCn: yup.string().required('상세내용은 필수 입력 값입니다.'),
  // partclrMatter: yup.string().required('특이사항은 필수 입력 값 입니다.'),
  // cnsltRepairCn: yup.string().required('처리내용은 필수 입력 값 입니다.'),

  // 2. 처리자자재
  itemList: yup
    .array()
    // .min(1, '자재를 추가해주세요.')
    .test({
      message: '유/무상, 수량을 확인해주세요.',
      test: (arr) => arr.every((item) => item.useQy && item.freeSe),
    }),
  // processAditAmountCode: yup
  //   .string()
  //   .required('공임비는 필수 선택 값입니다.'),

  // 3. 결제금액
  // processTotCt: yup.string().required('총비용은 필수 입력 값입니다.'),
  // processSetleAmount: yup.string().required('결제금액은 필수 입력 값입니다.'),
  // processAditAmount: yup.string().required('공임비는 필수 입력 값입니다.'),
  // processSetleSe: yup.string().required('결제구분은 필수 선택 값입니다.'),
  processSetleSe: yup.string().when(['setleSe'], (values, schema) => {
    const [setleSe] = values;
    if (setleSe != 1) {
      return yup.string().required('결제구분은 필수 선택 값입니다.');
    }
    return schema.nullable();
  }),
  // processCardConfmSeq: yup
  //   .string()
  //   .required('카드승인번호는 필수 입력 값입니다.'),
  // processDscntAditPrvonsh: yup
  //   .string()
  //   .required('할인/추가사유는 필수 입력 값입니다.'),

  // 4. 서명
  isSign: yup.boolean().isTrue('서명을 해주세요.'),

  customerCanclPrvonsh: yup
    .string()
    .when('processSttusCode', (processSttusCode, schema) => {
      if (processSttusCode == 89) {
        return yup.string().trim().required('취소 사유는 필수 입력 값 입니다.');
      }
      return schema.nullable();
    }),

  // processInstlmtPd: yup
  //   .string()
  //   .when(['processSetleSe', 'processTotCt'], (values, schema) => {
  //     // if(isMobileApp() && (values[0] == 1 || values[0] == 3) && values[1] >= 50000) {
  //     if ((values[0] == 1 || values[0] == 3) && values[1] >= 50000) {
  //       return yup.string()?.trim().required('할부 개월을 선택해주세요.');
  //     }
  //     return schema.nullable();
  //   }),

  // 현금영수증 신청여부 => 현금, 카드+현금일경우 필수 선택
  cashRciptReqstAt: yup
    .string()
    .when(
      ['processSetleSe', 'processTotCt', 'processCodAmount'],
      (values, schema) => {
        const [processSetleSe, processTotCt, processCodAmount] = values;
        // if(isMobileApp()) {
        if (processSetleSe == 2) {
          return yup.string().required('현금영수증신청 여부를 선택해주세요.');
        }

        if (
          processSetleSe == 3 &&
          Number(processTotCt) - Number(processCodAmount) > 0
        ) {
          return yup.string().required('현금영수증신청 여부를 선택해주세요.');
        }
        // }

        return schema.nullable();
      },
    ),
  // 현금영수증 발행 타입 => 현금영수증 신청 했을 경우 필수 선택
  cashRciptSe: yup.string().when(['cashRciptReqstAt'], (values, schema) => {
    // if(isMobileApp()) {
    const [cashRciptReqstAt] = values;
    if (cashRciptReqstAt == 1) {
      return yup.string().required('현금영수증 신청 유형을 선택해주세요.');
    }

    // }
    return schema.nullable();
  }),
  // 현금영수증신청번호 => 현금 영수증 신청 했을 경우 필수 입력
  cashRciptSeq: yup.string().when(['cashRciptReqstAt'], (values, schema) => {
    // if(isMobileApp()) {
    const [cashRciptReqstAt] = values;
    if (cashRciptReqstAt == 1) {
      return yup.string().required('현금영수증 신청 번호를 입력해주세요.');
    }
    // }
    return schema.nullable();
  }),
  //
});

export const receptionLogCompleteValidationAlert = (values) => {
  let error = {};

  if (
    (!values.itemSeqs || values.itemSeqs.length === 0) &&
    (!values.addModel?.itemSeq || !values.addModel?.useQy)
  ) {
    error.modelNm = '모델을 하나 이상 추가 해주세요.';
    return error;
  }

  if (isEmpty(values.processSttusCode) || values.processSttusCode === '') {
    error.processSttusCode = '처리코드는 필수 선택 값입니다.';
    return error;
  }

  if (isEmpty(values.processDt) || values.processDt === '') {
    error.processDt = '처리일자는 필수 선택 값입니다.';
    return error;
  }

  if (isEmpty(values.setleSe) || values.setleSe === '') {
    error.setleSe = '결제구분은 필수 선택 값입니다.';
    return error;
  }

  if (
    !values.itemList ||
    values.itemList.some((item) => !item.useQy || !item.freeSe)
  ) {
    error.itemList = '유/무상, 수량을 확인해주세요.';
    return error;
  }

  if (
    values.setleSe !== 1 &&
    (!values.processSetleSe || values.processSetleSe.trim() === '')
  ) {
    error.processSetleSe = '결제구분은 필수 선택 값입니다.';
    return error;
  }

  if (isEmpty(values.isSign) || values.isSign === '') {
    error.isSign = '서명을 해주세요.';
    return error;
  }

  if (
    values.processSttusCode === 89 &&
    (!values.customerCanclPrvonsh || values.customerCanclPrvonsh.trim() === '')
  ) {
    error.customerCanclPrvonsh = '취소 사유는 필수 입력 값 입니다.';
    return error;
  }

  if (
    values.processSetleSe === 2 &&
    (!values.cashRciptReqstAt || values.cashRciptReqstAt.trim() === '')
  ) {
    error.cashRciptReqstAt = '현금영수증신청 여부를 선택해주세요.';
    return error;
  }

  if (
    values.processSetleSe === 3 &&
    Number(values.processTotCt) - Number(values.processCodAmount) > 0 &&
    (!values.cashRciptReqstAt || values.cashRciptReqstAt.trim() === '')
  ) {
    error.cashRciptReqstAt = '현금영수증신청 여부를 선택해주세요.';
    return error;
  }

  if (
    values.cashRciptReqstAt === 1 &&
    (!values.cashRciptSe || values.cashRciptSe.trim() === '')
  ) {
    error.cashRciptSe = '현금영수증 신청 유형을 선택해주세요.';
    return error;
  }

  if (
    values.cashRciptReqstAt === 1 &&
    (!values.cashRciptSeq || values.cashRciptSeq.trim() === '')
  ) {
    error.cashRciptSeq = '현금영수증 신청 번호를 입력해주세요.';
    return error;
  }

  return error;
};

export function createOutStandingStore(root) {
  return makeAutoObservable({
    outstandingList: initial.outstandingList,
    outstanding: initial.outstanding,
    outstandingComplete: initial.outstandingComplete,

    rceptTyCodeList: [], // 접수유형 목록
    centerList: [], // 센터 목록
    opetrMemberList: [], // 처리자 목록
    rcepterMemberList: [], // 접수자 목록
    rcepterList: [], // 접수센터 목록

    processAditAmountCodeList: [], // 추가비용 목록
    processWorkAmountCodeList: [], // 공임비 목록

    processSttusCodeList: [], // 처리코드목록

    searchModelList: [], // 모델리스트
    searchItemList: [],

    ct: 0, // 급지비용
    searchRceptCo: 0, // 접수횟수

    reserveTimeList: [],

    // 검색 폼 사용
    //처리,접수센터, 처리자, 접수자
    enterpriseList: [], // 처리,접수센터
    operationMemberList: [], // 처리자
    receptionMemberList: [], // 접수자
    customerCenterList: [], // 고객사

    pageOptions: initial.pageOptions,
    itemOptions: initial.itemOptions,

    alertMessage: '',

    async getOutstandingList({
      supplierEnterpriseSeq,
      rcepterEnterpriseSeq,
      rcepterMemberNm,
      rceptSe,
      cmptncEnterpriseSeq,
      opetrMemberSeq,
      opetrMemberNm,
      serviceSttus,
      rceptDtSt,
      rceptDtEd,
      // processDtSt,
      // processDtEd,
      modelNm,
      customerNm,
      cttpc,
      // enterpriseNm,
      adres,
      number,
      size,
      rceptSeq,
    }) {
      try {
        const res = await authApi.get(
          OUTSTANDING_URL,
          {
            supplierEnterpriseSeq:
              typeof supplierEnterpriseSeq === 'object'
                ? supplierEnterpriseSeq?.join(',')
                : supplierEnterpriseSeq,
            rcepterEnterpriseSeq:
              typeof rcepterEnterpriseSeq === 'object'
                ? rcepterEnterpriseSeq?.join(',')
                : rcepterEnterpriseSeq,
            rcepterMemberNm: rcepterMemberNm?.[0] ?? null,
            rceptSe: rceptSe ? rceptSe.join(',') : null,
            cmptncEnterpriseSeq: cmptncEnterpriseSeq
              ? cmptncEnterpriseSeq.join(',')
              : null,
            opetrMemberSeq: opetrMemberSeq ? opetrMemberSeq.join(',') : null,
            opetrMemberNm: opetrMemberNm ? opetrMemberNm.join(',') : null,
            serviceSttus: serviceSttus ? serviceSttus.join(',') : null,
            rceptDtSt: rceptDtSt?.[0] ?? null,
            rceptDtEd: rceptDtEd?.[0] ?? null,
            // processDtSt: processDtSt?.[0] ?? null,
            // processDtEd: processDtEd?.[0] ?? null,
            modelNm: modelNm?.[0] ?? null,
            customerNm: customerNm?.[0] ?? null,
            cttpc: cttpc?.[0] ?? null,
            // enterpriseNm: enterpriseNm?.[0] ?? null,
            adres: adres?.[0] ?? null,
            number: number?.[0] ?? 1,
            size: size?.[0] ?? 20,
            rceptSeq: rceptSeq?.[0] ?? null,
          },
          null,
        );

        runInAction(() => {
          this.outstandingList = res;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.outstandingList = initial.outstandingList;
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getOutstanding(serviceInfoSeq) {
      try {
        const { data } = await authApi.get(
          `${OUTSTANDING_URL}/${serviceInfoSeq}`,
          null,
          null,
        );

        runInAction(() => {
          this.outstanding = {
            ...initial.outstanding,
            ...data,
            itemSeqs: data.modelList.map((item) => ({
              ...item,
            })),
            useQys: data.modelList.map((item) => item.useQy),
            imageList: data.imageList.filter(
              (image) => image.fileSe !== 'Signature',
            ),
            signFile: data.imageList.filter(
              (image) => image.fileSe === 'Signature',
            )[0],
            originalResveDt: data.resveDt,
            btrps: 0,
          };
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.outstanding = {
            ...initial.outstanding,
          };
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateOutstanding(serviceInfoSeq, params, deleteFileSeq) {
      try {
        let fileResult = true;
        const images = params.imageList.filter((file) => !file?.atchmnflSeq);
        if (images.length > 0) {
          fileResult = await this.fileUpload(serviceInfoSeq, images);
        }

        if (deleteFileSeq.length > 0) {
          fileResult = await this.fileDelete(deleteFileSeq);
        }

        if (fileResult) {
          await authApi.put(`${OUTSTANDING_URL}/${serviceInfoSeq}`, params);
          return true;
        }
        return false;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async completeOutstanding(serviceInfoSeq, params, deleteFileSeq, signFile) {
      try {
        let fileResult = true;
        const images = params.imageList.filter((file) => !file?.atchmnflSeq);
        if (images.length > 0) {
          fileResult = await this.fileUpload(serviceInfoSeq, images);
        }

        if (deleteFileSeq.length > 0) {
          fileResult = await this.fileDelete(deleteFileSeq);
        }

        if (fileResult) {
          await authApi.put(
            `${OUTSTANDING_URL}/${serviceInfoSeq}/complete`,
            params,
          );
          if (signFile) {
            // 서명 파일이 있을경우 업로드
            await this.signFileUpload(serviceInfoSeq, signFile);
          }
          return true;
        }
        return false;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateChangeStatus(params) {
      try {
        await authApi.put(`${OUTSTANDING_URL}/change`, params);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async fileUpload(serviceInfoSeq, files) {
      const formData = new FormData();

      files.map((file) => {
        formData.append('multipartFiles', file);
      });

      try {
        await authApi.fileUpload(
          `${OUTSTANDING_URL}/${serviceInfoSeq}/atchmnfl`,
          formData,
        );
        return true;
      } catch (e) {
        throw e;
      }
    },

    // 서명 파일 등록/수정
    async signFileUpload(serviceInfoSeq, signFile) {
      const formData = new FormData();
      formData.append('multipartFiles', signFile);
      await authApi.fileUpload(
        `${OUTSTANDING_URL}/${serviceInfoSeq}/signature`,
        formData,
      );
    },

    async fileDelete(params) {
      try {
        await authApi.delete(`${OUTSTANDING_URL}/atchmnfl`, params);
        return true;
      } catch (e) {
        throw e;
      }
    },

    // 접수 횟수 조회
    // customerNm, cttpc, zip, adres, detailAdres, legaldongCode, signguCode
    async getSearchRceptCo(params) {
      try {
        const { data } = await authApi.get(
          `${OUTSTANDING_URL}/searchRceptCo`,
          {
            ...params,
          },
          null,
        );
        runInAction(() => {
          this.searchRceptCo = data;
        });
        return data;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 코드값

    // 접수유형
    async getRceptTyCodeList(rceptSe) {
      try {
        const { data } = await authApi.get(
          `${OUTSTANDING_URL}/support/rceptTyCodeList/${rceptSe}`,
          null,
          null,
        );
        runInAction(() => {
          this.rceptTyCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
          this.rceptTyCodeList = [];
        });
        return false;
      }
    },

    // 센터 목록
    async getCenterList() {
      try {
        const { data } = await authApi.get(
          `${OUTSTANDING_URL}/support/centerList`,
          null,
          null,
        );

        runInAction(() => {
          this.centerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 처리자 목록
    async getOpetrMemberList(enterpriseSeq) {
      try {
        if (isEmpty(enterpriseSeq)) {
          runInAction(() => {
            this.opetrMemberList = [];
          });
          return false;
        }

        const { data } = await authApi.get(
          `${OUTSTANDING_URL}/support/opetrMemberList`,
          { enterpriseSeq },
          null,
        );

        runInAction(() => {
          this.opetrMemberList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    setOpetrMemberList(opetrMemberList) {
      runInAction(() => {
        this.opetrMemberList = opetrMemberList;
      });
    },

    // 공임비목록
    async getProcessWorkAmountCodeList(itemSeqs) {
      try {
        if (itemSeqs.length > 0) {
          const { data } = await authApi.get(
            `${OUTSTANDING_URL}/support/workCodeList`,
            {
              itemSeq: itemSeqs.join(','),
            },
            null,
          );
          runInAction(() => {
            this.processWorkAmountCodeList = data;
          });
        } else {
          runInAction(() => {
            this.processWorkAmountCodeList = [];
          });
        }
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
          this.processWorkAmountCodeList = [];
        });
        return false;
      }
    },

    // 추가비용 목록
    async getProcessAditAmountCodeList(itemSeqs) {
      try {
        if (itemSeqs.length > 0) {
          const { data } = await authApi.get(
            `${OUTSTANDING_URL}/support/aditCodeList`,
            {
              itemSeq: itemSeqs.join(','),
            },
            null,
          );
          runInAction(() => {
            this.processAditAmountCodeList = data;
          });
        } else {
          runInAction(() => {
            this.processAditAmountCodeList = [];
          });
        }
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
          this.processAditAmountCodeList = [];
        });
        return false;
      }
    },

    // 처리코드 목록
    async getProcessSttusCodeList() {
      try {
        const { data } = await authApi.get(
          `${OUTSTANDING_URL}/support/processSttusCodeList`,
          null,
          null,
        );
        runInAction(() => {
          this.processSttusCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 접수자 목록
    async getRcepterMemberList() {
      try {
        const { data } = await authApi.get(
          `${OUTSTANDING_URL}/support/rcepterMemberList`,
          null,
          null,
        );
        runInAction(() => {
          this.rcepterMemberList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getSearchItemList(searchKeyword, serviceInfoSeq) {
      try {
        const { data } = await authApi.get(
          `${OUTSTANDING_URL}/searchItemList`,
          { searchKeyword, serviceInfoSeq },
          null,
        );
        runInAction(() => {
          this.searchItemList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getSearchModelList() {
      try {
        const { data } = await authApi.get(
          `${OUTSTANDING_URL}/searchModelList`,
          null,
          null,
        );
        runInAction(() => {
          this.searchModelList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getSearchAreaGrade(enterpriseSeq, legaldongCode) {
      try {
        const { data } = await authApi.get(
          `${OUTSTANDING_URL}/searchAreaGrade`,
          { enterpriseSeq, legaldongCode },
          null,
        );
        runInAction(() => {
          this.ct = data?.ct || 0;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getOpetrMemberResveDtList({ opetrMemberSeq, resveDt }) {
      try {
        const { data } = await authApi.get(
          `${OUTSTANDING_URL}/opetrMemberResveDtList`,
          {
            opetrMemberSeq,
            resveDt,
          },
          null,
        );
        runInAction(() => {
          this.reserveTimeList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 검색폼에서 사용하는 코드값(처리자, 접수자)
    async getEnterpriseList() {
      try {
        const { data } = await authApi.get(
          `${OUTSTANDING_URL}/support/centerList`,
          null,
          null,
        );

        runInAction(() => {
          this.enterpriseList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.enterpriseList = [];
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCustomerCenterList() {
      try {
        const { data } = await authApi.get(
          `${OUTSTANDING_URL}/support/customerList`,
          null,
          null,
        );

        runInAction(() => {
          this.customerCenterList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.customerCenterList = [];
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getRcepterList() {
      try {
        const { data } = await authApi.get(
          `${OUTSTANDING_URL}/support/rcepterList`,
          null,
          null,
        );

        runInAction(() => {
          this.rcepterList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.rcepterList = [];
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getOperationMemberList(enterpriseSeq) {
      try {
        if (enterpriseSeq && enterpriseSeq.length > 0) {
          const { data } = await authApi.get(
            `${OUTSTANDING_URL}/support/opetrMemberGroupList`,
            { enterpriseSeq: enterpriseSeq.join(',') },
            null,
          );

          runInAction(() => {
            this.operationMemberList = data;
          });
        } else {
          runInAction(() => {
            this.operationMemberList = [];
          });
        }
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.operationMemberList = [];
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getReceptionMemberList(enterpriseSeq) {
      try {
        if (enterpriseSeq && enterpriseSeq.length > 0) {
          const { data } = await authApi.get(
            `${OUTSTANDING_URL}/support/rcepterMemberGroupList`,
            { enterpriseSeq: enterpriseSeq.join(',') },
            null,
          );

          runInAction(() => {
            this.receptionMemberList = data;
          });
        } else {
          runInAction(() => {
            this.receptionMemberList = [];
          });
        }
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
          this.receptionMemberList = [];
        });
        return false;
      }
    },

    // 입금확인
    async depositCheck(serviceInfoSeq) {
      try {
        const { data } = await authApi.get(
          `${OUTSTANDING_URL}/deposit-check/${serviceInfoSeq}`,
          null,
          null,
        );

        return data;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 결제 Sample
    // 결제 요청
    async paymentRequest(
      serviceInfoSeq,
      { goodName, price, receiptMoblphonNo },
    ) {
      try {
        const { data } = await authApi.post('/api/payment/request', {
          serviceInfoSeq,
          goodName,
          price,
          receiptMoblphonNo,
        });

        return data;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 카드 승인 번호 조회
    async getPaymentAuthNo(serviceInfoSeq) {
      try {
        const { data } = await authApi.get(
          '/api/payment',
          {
            serviceInfoSeq,
          },
          null,
        );

        return data;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    setOpetrMemberResveDtList(reserveTime) {
      runInAction(() => {
        this.reserveTimeList = reserveTime;
      });
    },

    setPageOptions(value, init) {
      runInAction(() => {
        this.pageOptions = init
          ? {
              ...initial.pageOptions,
              ...value,
            }
          : {
              ...this.pageOptions,
              ...value,
            };
      });
    },

    setItemOptions(value, init) {
      runInAction(() => {
        this.itemOptions = init
          ? {
              ...initial.itemOptions,
              ...value,
            }
          : {
              ...this.itemOptions,
              ...value,
            };
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
