import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

const CENTER_ORDER_URL = '/api/item/center-order';

export const initial = {
  centerOrderList: {
    data: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },

  centerOrderStateList: [],
};

// 센터출고관리
export function createMaterialCenterOrderStore(rootStore) {
  return makeAutoObservable({
    centerOrderList: initial.centerOrderList,
    centerOrderStateList: initial.centerOrderStateList, // 센터 신청 현황 목록

    centerList: [], // 센터 목록
    unDlivyPrvonshCodeList: [], // 미출고 사유 코드 목록

    alertMessage: '',

    async getCenterOrderList(options) {
      try {
        const res = await authApi.get(CENTER_ORDER_URL, { ...options }, null);
        runInAction(() => {
          this.centerOrderList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCenterOrderStateList() {
      try {
        const { data } = await authApi.get(
          `${CENTER_ORDER_URL}/application`,
          null,
          null,
        );

        runInAction(() => {
          this.centerOrderStateList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateCenterOrder({
      confmQy, // 승인 수량
      orderSeq, // 주문/반납내역번호
      orderSttus, // 주문/반납상태(0:신청,1:승인,2:미승인,3:취소)
      unDlivyPrvonshCode, // 미출고 사유 코드
    }) {
      try {
        await authApi.put(`${CENTER_ORDER_URL}/change`, {
          confmQy,
          orderSeq,
          orderSttus,
          unDlivyPrvonshCode,
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteCenterOrders(orderSeqs) {
      try {
        await authApi.delete(CENTER_ORDER_URL, orderSeqs);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },


    async getCenterList() {
      try {
        const { data } = await authApi.get(
          `${CENTER_ORDER_URL}/support/centerList`,
        );

        runInAction(() => {
          this.centerList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getUnDlivyPrvonshCodeList() {
      try {
        const { data } = await authApi.get(
          `${CENTER_ORDER_URL}/support/unDlivyPrvonshCodeList`,
          null,
          null,
        );

        runInAction(() => {
          this.unDlivyPrvonshCodeList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
