import {makeAutoObservable, runInAction} from 'mobx';
import {authApi} from '@helpers/AxiosHelper';

const VISIT_URL = '/api/schedule/visit';

export const initial = {
  visitList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  visit: {},
};

export function createVisitStore(rootStore) {
  return makeAutoObservable({
    visitList: initial.visitList,
    visit: initial.visit,
    categoryCodeList: [],
    brandCodeList: [],

    alertMessage: '',

    async getVisitList(options) {
      try {
        const res = await authApi.get(VISIT_URL, {...options}, null);

        runInAction(() => {
          this.visitList = res;
        });

        return true;
      } catch (e) {
        runInAction(() => {
          this.alertMessage =
              e?.data?.data?.alertMessage ||
              '문제가 발생했습니다. 잠시 후 다시 시도해주세요.';
        });
        return false;
      }
    },

    async getVisit(serviceInfoSeq) {
      try {
        const {data} = await authApi.get(
            `${VISIT_URL}/${serviceInfoSeq}`,
            null,
            null,
        );
        runInAction(() => {
          this.visit = data;
        });

        return true;
      } catch (e) {
        runInAction(() => {
          this.visit = initial.visit;
          this.alertMessage =
              e?.data?.data?.alertMessage ||
              '문제가 발생했습니다. 잠시 후 다시 시도해주세요.';
        });
        return false;
      }
    },

    async getCategoryCodeList() {
      try {
        const {data} = await authApi.get(
            `/api/schedule/support/category`,
            null,
            null,
        );
        runInAction(() => {
          this.categoryCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: {alertMessage},
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getBrandCodeList() {
      try {
        const {data} = await authApi.get(
            `/api/schedule/support/brand`,
            null,
            null,
        );
        runInAction(() => {
          this.brandCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: {alertMessage},
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },

  });
}
