import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

import * as yup from 'yup';
import { onlyNumber, isEmpty } from '@helpers/StringHelper';

const CONSULTATION_URL = '/api/service-info/reception/consultation';

export const initial = {
  consultation: {
    itemSeq: null,
    serialNo: '',
    cnsltRepairCn: '',
    cttpc: '',
    customerNm: '',
    defectSymptmsCode: null,
    inqryRceptCn: '',
    purchsDt: '',
    rceptDt: '',
    rceptTyCode: null,
    zip: '',
    adres: '',
    detailAdres: '',
    legaldongCode: 0,
    signguCode: 0,
    modelNm: null,
  },

  modelList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  modelOptions: {
    categorySeq: null,
    brandCodeSeq: null,
    searchKeyword: null,
    number: 1,
    modelNm: null,
  },
};

export const consultationValidationSchema = yup.object({
  customerNm: yup.string().required('고객명은 필수 입력 값 입니다.'),
  cttpc: yup
    .string()
    .test({
      message: '연락처는 필수 입력 값 입니다.',
      test: (value) => {
        if (!onlyNumber(value.replaceAll('-', ''))) {
          return false;
        }
        return true;
      },
    })
    .required('연락처는 필수 입력 값 입니다.'),
  zip: yup.string().required('주소는 필수 입력 값 입니다.'),
  adres: yup.string().required('주소는 필수 입력 값 입니다.'),
  detailAdres: yup.string().required('주소는 필수 입력 값 입니다.'),
  rceptTyCode: yup.string().required('접수유형은 필수 선택 값 입니다.'),
  modelNm: yup.string().required('모델명은 필수 선택 값 입니다.'),
  // serialNo: yup.string().required('시리얼넘버는 필수 입력 값 입니다.'),
  // purchsDt: yup.string().required('구매일자는 필수 입력 값 입니다.'),
  // defectSymptmsCode: yup.string().required('고장증상은 필수 선택 값 입니다.'),
  inqryRceptCn: yup.string().required('문의내용은 필수 입력 값 입니다.'),
  // cnsltRepairCn: yup.string().required('상담내용은 필수 입력 값 입니다.'),
});

export function createConsultationStore(root) {
  return makeAutoObservable({
    consultation: initial.consultation,

    searchCustomerList: [], // 고객 조회

    searchServiceInfoList: [], // 서비스이력 조회
    serviceLogList: [], // 서비스 이력
    consultLogList: [], //상담 내역

    searchRceptCo: 0, // 접수횟수 조회
    defectSymptmsCodeList: [], // 고장증상 목록
    rceptTyCodeList: [], // 접수유형 목록

    modelList: initial.modelList, // 모델명 조회

    categoryCodeList: [], // 카테고리 목록
    brandCodeList: [], // 브랜드 목록

    modelOptions: initial.modelOptions,

    alertMessage: '',

    async getConsultation({ serviceInfoSeq }) {
      try {
        const { data } = await authApi.get(
          `${CONSULTATION_URL}/${serviceInfoSeq}`,
          null,
          null,
        );
        runInAction(() => {
          this.consultation = { ...data, modelNm: '' };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveConsultation(params) {
      try {
        await authApi.post(CONSULTATION_URL, { ...params });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getSearchCustomerList({ customerNm, cttpc }) {
      try {
        const { data } = await authApi.get(
          `${CONSULTATION_URL}/searchCustomerList`,
          {
            customerNm,
            cttpc,
          },
          null,
        );
        runInAction(() => {
          this.searchCustomerList = data;
          this.consultLogList = [];
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getSearchModelList() {
      try {
        const res = await authApi.get(
          `${CONSULTATION_URL}/searchModelList`,
          {
            ...this.modelOptions,
          },
          null,
        );
        runInAction(() => {
          this.modelList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // customerNm, cttpc, zip, adres, detailAdres, legaldongCode, signguCode
    // 접수 횟수 조회
    async getSearchRceptCo(params) {
      try {
        const { data } = await authApi.get(
          `${CONSULTATION_URL}/searchRceptCo`,
          {
            ...params,
          },
          null,
        );
        runInAction(() => {
          this.searchRceptCo = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
          this.searchRceptCo = 0;
        });
        return false;
      }
    },

    async getSearchServiceInfoList({ customerNm, cttpc }) {
      try {
        const { data } = await authApi.get(
          `${CONSULTATION_URL}/searchServiceInfoList`,
          {
            customerNm,
            cttpc,
          },
          null,
        );
        runInAction(() => {
          this.serviceLogList = data.filter((item) => item.serviceSe === 2);
          this.consultLogList = data.filter((item) => item.serviceSe === 1);
          this.searchServiceInfoList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 고장증상
    async getDefectSymptmsCodeList(itemSeqs) {
      try {
        if (itemSeqs && itemSeqs.length > 0) {
          const { data } = await authApi.get(
            `${CONSULTATION_URL}/support/defectSymptmsCodeList`,
            {
              itemSeq: itemSeqs.join(','),
            },
            null,
          );
          runInAction(() => {
            this.defectSymptmsCodeList = data;
          });
        } else {
          runInAction(() => {
            this.defectSymptmsCodeList = [];
          });
        }
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 접수유형
    async getRceptTyCodeList() {
      try {
        const { data } = await authApi.get(
          `${CONSULTATION_URL}/support/rceptTyCodeList`,
          null,
          null,
        );
        runInAction(() => {
          this.rceptTyCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 카테고리 리스트
    async getCategoryCodeList() {
      try {
        const { data } = await authApi.get(
          `${CONSULTATION_URL}/support/categoryCodeList`,
          null,
          null,
        );
        runInAction(() => {
          this.categoryCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 브랜드 리스트
    async getBrandCodeList() {
      try {
        const { data } = await authApi.get(
          `${CONSULTATION_URL}/support/brandCodeList`,
          null,
          null,
        );
        runInAction(() => {
          this.brandCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    initialData() {
      runInAction(() => {
        this.consultation = initial.consultation;
        this.searchCustomerList = [];
        this.searchServiceInfoList = [];
        this.serviceLogList = [];
        this.consultLogList = [];
      });
    },

    setModelOptions(value, init) {
      runInAction(() => {
        this.modelOptions = init
          ? {
              ...initial.modelOptions,
              ...value,
            }
          : {
              ...this.modelOptions,
              ...value,
            };
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}

export const consultationValidationAlert = (values) => {
  let error = {};

  if (isEmpty(values.customerNm) || values.customerNm === '') {
    error.customerNm = '고객명은 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.cttpc) || values.cttpc === '') {
    error.cttpc = '연락처는 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.zip) || values.zip === '') {
    error.zip = '주소는 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.adres) || values.adres === '') {
    error.adres = '주소는 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.detailAdres) || values.detailAdres === '') {
    error.detailAdres = '주소는 필수 입력 값 입니다.';
    return error;
  }

  if (isEmpty(values.rceptTyCode) || values.rceptTyCode === '') {
    error.rceptTyCode = '접수유형은 필수 선택 값 입니다.';
    return error;
  }

  if (isEmpty(values.modelNm) || values.modelNm === '') {
    error.modelNm = '모델명은 필수 선택 값 입니다.';
    return error;
  }

  if (isEmpty(values.inqryRceptCn) || values.inqryRceptCn === '') {
    error.inqryRceptCn = '문의내용은 필수 입력 값 입니다.';
    return error;
  }

  return error;
};
