import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

import * as yup from 'yup';
import {toHtml} from "@helpers/StringHelper";

const SERVICE_EDU_URL = '/api/community/service-education';
export const initial = {
  list: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  data: {
    bbsSeq: '',
    writerMemberSeq: '',
    isEditable: 0,
    upendFixingAt: 0,
    bbsSj: '',
    bbsCn: '',
    regDt: '',
    atchmnfls: [],
    bbsAnswers: [],
  },

  cautionList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  visitList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  faqList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  pcAccessList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },
};

const serviceEducationType = {
  caution: {
    url: `${SERVICE_EDU_URL}/caution`,
    edcSe: 1,
  },
  visit: {
    url: `${SERVICE_EDU_URL}/visit`,
    edcSe: 2,
  },
  faq: {
    url: `${SERVICE_EDU_URL}/faq`,
    edcSe: 3,
  },
  pcAccess: {
    url: `${SERVICE_EDU_URL}/pc-access`,
    edcSe: 4,
  },
};

export const serviceEduValidationSchema = yup.object({
  bbsSj: yup.string().required('제목은 필수 입력 값 입니다.'),
  bbsCn: yup.string().required('내용은 필수 입력 값 입니다.'),
});

export function createCommunityServiceEduStore() {
  return makeAutoObservable({
    list: initial.list,
    data: initial.data,

    alertMessage: '',

    async getBbsList(type, options) {
      try {
        const res = await authApi.get(
          serviceEducationType[type].url,
          { ...options, edcSe: serviceEducationType[type].edcSe },
          null,
        );

        runInAction(() => {
          this.list = res;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getBbs(bbsSeq) {
      try {
        const { data } = await authApi.get(
          `${SERVICE_EDU_URL}/${bbsSeq}`,
          null,
          null,
        );

        runInAction(() => {
          this.data = {
            ...data,
            bbsCn : toHtml(data.bbsCn)
          };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveBbs(type, { bbsSj, bbsCn, url, changeFileNms }, serviceEduFiles) {
      try {
        const { data } = await authApi.post(serviceEducationType[type].url, {
          bbsSj,
          bbsCn,
          upendFixingAt: 0,
          url,
          changeFileNms,
        });

        if (serviceEduFiles.length > 0) {
          await this.bbsFileUpload(data.bbsSeq, serviceEduFiles);
        }

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateBbs(
      serviceEduType,
      bbsParam,
      { bbsSj, bbsCn, url, changeFileNms },
      serviceEduFiles,
      deleteFileSeq,
    ) {
      try {
        const {
          data: { bbsSeq },
        } = await authApi.patch(`${SERVICE_EDU_URL}/${bbsParam}`, {
          bbsSj,
          bbsCn,
          upendFixingAt: 0,
          url,
          changeFileNms,
        });

        if (serviceEduFiles.length > 0) {
          await this.bbsFileUpload(bbsSeq, serviceEduFiles);
        }
        if (deleteFileSeq.length > 0) {
          await this.bbsFileDelete(bbsParam, deleteFileSeq);
        }
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteBbs(bbsSeq) {
      try {
        await authApi.delete(`${SERVICE_EDU_URL}/${bbsSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveBbsComment(bbsSeq, { bbsAnswerCn }) {
      try {
        await authApi.post(`${SERVICE_EDU_URL}/${bbsSeq}/answer`, {
          bbsAnswerCn,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async bbsFileUpload(bbsSeq, serviceEduFiles) {
      const formData = new FormData();

      serviceEduFiles.map((serviceEduFile) => {
        formData.append('multipartFiles', serviceEduFile);
      });

      await authApi.fileUpload(
        `${SERVICE_EDU_URL}/${bbsSeq}/atchmnfl`,
        formData,
      );
    },

    async bbsFileDelete(bbsSeq, params) {
      await authApi.delete(`/api/community/common/bbs/${bbsSeq}/atchmnfl`, {
        atchmnflSeqs: params,
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return;
    },
  });
}
