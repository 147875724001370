import { makeAutoObservable, runInAction } from 'mobx';
import * as yup from 'yup';
import { authApi } from '@helpers/AxiosHelper';

const CLASSIFICATION_URL = '/api/materialcode/classification';

export const initial = {
  classificationList: [],
  enterpriseList: [],
  categoryList: [],

  classification: {
    codeSeq: '',
    codeId: '',
    codeNm: '',
    parntsCodeSeq: '',
    useAt: '',
  },
};

export const classificationValidationSchema = yup.object({
  codeNm: yup.string().required('이름은 필수 값 입니다.'),
});

export function createClassificationStore(rootStore) {
  return makeAutoObservable({
    classificationList: initial.classificationList,
    enterpriseList: initial.enterpriseList,
    categoryList: initial.categoryList,
    classification: initial.classification,

    alertMessage: '',

    async getClassificationList() {
      try {
        const { data } = await authApi.get(
          `${CLASSIFICATION_URL}/classification`,
          null,
          null,
        );

        runInAction(() => {
          this.classificationList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async saveClassification(codeNm) {
      try {
        await authApi.post(`${CLASSIFICATION_URL}/classification`, {
          codeNm,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async getEnterpriseList() {
      try {
        const { data } = await authApi.get(
          `${CLASSIFICATION_URL}/enterprise`,
          null,
          null,
        );

        runInAction(() => {
          this.enterpriseList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async saveEnterprise(codeNm) {
      try {
        await authApi.post(`${CLASSIFICATION_URL}/enterprise`, {
          codeNm,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async getCategoryList() {
      try {
        const { data } = await authApi.get(
          `${CLASSIFICATION_URL}/category`,
          null,
          null,
        );

        runInAction(() => {
          this.categoryList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async saveCategory(codeNm) {
      try {
        await authApi.post(`${CLASSIFICATION_URL}/category`, {
          codeNm,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async updateCode(parentCodeSeq, { codeNm, codeSeq, useAt }) {
      try {
        await authApi.patch(`${CLASSIFICATION_URL}/${parentCodeSeq}`, {
          codeNm,
          codeSeq,
          useAt,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async deleteCode(codeSeq) {
      try {
        await authApi.delete(`${CLASSIFICATION_URL}/${codeSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
