import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import { getDate } from '@helpers/DateHelper';

const CENTER_SETTLEMENT_URL = '/api/settlement/centerSettlement';

export const initial = {
  settlementList: {
    feeTotalAmount: 0,
    paidTotalAmount: 0,
    data: [],
  },
  settlementDetailList: {
    feeTotalAmount: 0,
    paidTotalAmount: 0,
    data: [],
  },
};

export function createCenterSettlementStore(rootStore) {
  return makeAutoObservable({
    settlementList: initial.settlementList,
    settlementDetailList: initial.settlementDetailList,

    alertMessage: '',

    async getCenterSettlementList(options) {
      try {
        const { data } = await authApi.get(
          CENTER_SETTLEMENT_URL,
          { ...options },
          null,
        );

        runInAction(() => {
          let feeTotalAmount = 0;
          let paidTotalAmount = 0;

          data.map((item) => {
            feeTotalAmount += item.fee;
            paidTotalAmount += item.paidAmount;
          });

          this.settlementList = {
            feeTotalAmount,
            paidTotalAmount,
            data,
          };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.settlementList = initial.settlementList;
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCenterSettlementDetailList({ processDt, rceptSe, supplierSeq }) {
      try {
        const { data } = await authApi.get(
          `${CENTER_SETTLEMENT_URL}/view`,
          { processDt, rceptSe, supplierSeq },
          null,
        );

        runInAction(() => {
          let feeTotalAmount = 0;
          let paidTotalAmount = 0;

          data.map((item) => {
            feeTotalAmount += item.fee;
            paidTotalAmount += item.paidAmount;
          });

          this.settlementDetailList = {
            feeTotalAmount,
            paidTotalAmount,
            data,
          };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.settlementDetailList = initial.settlementDetailList;
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
