import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import { FAQ_TYPE } from '@const/faqType';
import * as yup from 'yup';
import {toHtml} from "@helpers/StringHelper";

const FAQ_URL = '/api/community/faq';

export const initial = {
  faqList: {
    data: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },

  pcAccessList: {
    data: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },

  usageMethodsList: {
    data: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },

  data: {
    bbsSeq: null,
    writerMemberSeq: null,
    bbsSj: null,
    bbsCn: '',
    regDt: null,
    upendFixingAt: 0,
    isEditable: null,
    url: null,
  },

  pageOptions: {
    number: 1,
    searchOption: 0,
    searchKeyword: null,
    faqSe: null, // 1:홈페이지, 2:고객응대, 3:제품문의, 4:정산문의
  },
};

export const faqValidationSchema = yup.object({
  faqSe: yup.string().required('질문 카테고리를 선택해주세요.'),
  bbsSj: yup.string().required('제목은 필수 입력 값 입니다.'),
  bbsCn: yup.string().required('내용은 필수 입력 값 입니다.'),
});

export function createCommunityFaqStore() {
  return makeAutoObservable({
    faqList: initial.faqList,
    pcAccessList: initial.pcAccessList,
    usageMethodsList: initial.usageMethodsList,

    data: initial.data,

    pageOptions: initial.pageOptions,
    alertMessage: '',

    async getFaqList(faqType, faqSe) {
      try {
        const res = await authApi.get(
          FAQ_TYPE[faqType].url,
          { ...this.pageOptions, faqSe },
          null,
        );

        runInAction(() => {
          this.faqList = res;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.faqList = initial.faqList;
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getFaq(bbsSeq) {
      try {
        const { data } = await authApi.get(
          `/api/community/faq/${bbsSeq}`,
          null,
          null,
        );

        runInAction(() => {
          this.data = {
            ...data,
            bbsCn : toHtml(data.bbsCn)
          };
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveFaq(
      faqType,
      { faqSe, bbsSj, bbsCn, upendFixingAt, url, changeFileNms },
    ) {
      try {
        await authApi.post(FAQ_TYPE[faqType].url, {
          faqSe,
          bbsSj,
          bbsCn,
          upendFixingAt,
          url,
          changeFileNms,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateFaq(bbsSeq, params) {
      try {
        await authApi.patch(`/api/community/faq/${bbsSeq}`, {
          ...params,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteFaq(bbsSeq) {
      try {
        await authApi.delete(`/api/community/faq/${bbsSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getFaqPcAccess() {
      try {
        const res = await authApi.get(
          `${FAQ_URL}/pc-access`,
          { ...this.pageOptions, faqSe: null },
          null,
        );
        runInAction(() => {
          this.pcAccessList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveFaqPcAccess({ bbsSj, bbsCn, upendFixingAt, url }) {
      try {
        await authApi.post(`${FAQ_URL}/pc-access`, {
          bbsSj,
          bbsCn,
          upendFixingAt,
          url,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getFaqUsageMethods() {
      try {
        const res = await authApi.get(
          `${FAQ_URL}/usage-methods`,
          { ...this.pageOptions, faqSe: null },
          null,
        );
        runInAction(() => {
          this.pcAccessList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveFaqUsageMethods({ bbsSj, bbsCn, upendFixingAt, url }) {
      try {
        await authApi.post(`${FAQ_URL}/usage-methods`, {
          bbsSj,
          bbsCn,
          upendFixingAt,
          url,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    setPageOptions(value, init) {
      runInAction(() => {
        this.pageOptions = init
          ? {
              ...initial.pageOptions,
              ...value,
            }
          : {
              ...this.pageOptions,
              ...value,
            };
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
