import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';

const AUTHORITY_URL = '/api/authority/system';

export const initial = {
  authoritySystemGroup: {
    authorSystemSeq: 0,
    authorSystemCode: '',
    authorSystemNm: '',
    authorSystemDc: '',
    orderSeq: '',
    groupMemberAuthCount: 0,
  },

  authoritySystemMemberGroup: {
    authorMemberSeq: '',
    memberSeq: '',
    memberNm: '',
    authorSystemCode: '',
    authorSystemNm: '',
  },

  authoritySystemGroupList: [],
  allMemberList: [],
  
  authoritySystemGroupMemberList: {
    // 그룹별 멤버 목록
    data: [],
    pageable: {
      totalCount: 0,
      pageNumber: 1,
      pageSize: 10,
      totalPage: 0,
    },
  },

  authorityByMenu: {
    // 그룹별 메뉴 목록
    menuSeq: 0, // 메뉴 SEQ
    menuNm: '서비스관리', // 메뉴명
    menuLevel: 0, // 메뉴 레벨
    upperMenuSeq: 0, // 상위 메뉴 SEQ
    inqireAuthor: 0, // 조회권한
    updtAuthor: 0, // 수정 권한
  },
  authorityByMenuList: [],
};
export const initialPageOptions = {
  number: 1,
};

export const systemGroupValidationSchema = yup.object({
  authorSystemNm: yup.string().required('그룹명은 필수 값 입니다.'),
  authorSystemCode: yup.string().required('그룹코드는 필수 값 입니다.'),
});

export const systemGroupMemberValidationSchema = yup.object({

});

export function createAuthoritySystemStore(rootStore) {
  return makeAutoObservable({
    authoritySystemGroup: initial.authoritySystemGroup,
    authoritySystemGroupList: initial.authoritySystemGroupList,
    authoritySystemGroupMemberList: initial.authoritySystemGroupMemberList,
    authoritySystemMemberGroup: initial.authoritySystemMemberGroup,
    authorityByMenuList: initial.authorityByMenuList,
    pageOptions: initialPageOptions,
    alertMessage: '',
    selectedSystemSeq: null,
    trigger: null,
    allMemberList: initial.allMemberList,

    async getMemberList() {
      try {
        const { data } = await authApi.get(`${AUTHORITY_URL}/member/allMemberList`);
        runInAction(() => {
          this.allMemberList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    
    async getAuthorSystemDetail(seq) {
      try {
        const res = await authApi.get(`${AUTHORITY_URL}/${seq}`);
        runInAction(() => {
          this.authoritySystemGroup = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getAuthoritySystemGroupList() {
      try {
        const { data } = await authApi.get(`${AUTHORITY_URL}`);
        runInAction(() => {
          this.authoritySystemGroupList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveAuthoritySystemGroup({ authorSystemNm, authorSystemCode, authorSystemDc, orderSeq, authorSystemSeq }) {
      try {
        await authApi.post(`${AUTHORITY_URL}/save`, { authorSystemNm, authorSystemCode, authorSystemDc, orderSeq, authorSystemSeq });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveAuthoritySystemGroupMember({ authorMemberSeq='', authorSystemSeq, memberSeq }) {
      try {
        await authApi.post(`${AUTHORITY_URL}/member/save`, { authorMemberSeq, authorSystemSeq, memberSeq });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getAuthoritySystemGroupMemberList(authorSystemSeq) {
      authorSystemSeq = authorSystemSeq ?? 0; 
      try {
        const res = await authApi.get(
          `${AUTHORITY_URL}/member/${authorSystemSeq}`,
          { ...this.pageOptions },
          null,
        );

        runInAction(() => {
          this.authoritySystemGroupMemberList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteAuthoritySystemGroup(authorSystemSeq) {
      try {
        await authApi.delete(`${AUTHORITY_URL}/delete/${authorSystemSeq}`);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateauthoritySystemGroupMember({ memberSeq, authorGroupSeq }) {
      try {
        await authApi.patch(`${AUTHORITY_URL}/member/${memberSeq}`, {
          authorGroupSeq,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteAuthoritySystemMember(authorMemberSeq) {
      try {
        await authApi.delete(`${AUTHORITY_URL}/member/delete/${authorMemberSeq}`);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    setDetailPageOptions(value, init) {
      runInAction(() => {
        this.pageOptions = init
          ? {
              ...initialPageOptions,
              ...value,
            }
          : {
              ...this.pageOptions,
              ...value,
            };
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },

    transAuthorityByMenuArr(authorityByMenuList) {
      const menuArr = [];
      for (let i = 0; i < authorityByMenuList.length; i++) {
        menuArr.push(authorityByMenuList[i]);
        if (authorityByMenuList[i].childMenus.length > 0) {
          for (let j = 0; j < authorityByMenuList[i].childMenus.length; j++) {
            menuArr.push(authorityByMenuList[i].childMenus[j]);
            if (authorityByMenuList[i].childMenus[j].childMenus.length > 0) {
              for (
                let k = 0;
                k < authorityByMenuList[i].childMenus[j].childMenus.length;
                k++
              ) {
                menuArr.push(
                  authorityByMenuList[i].childMenus[j].childMenus[k],
                );
              }
            }
          }
        }
      }
      return menuArr;
    },
  });
}