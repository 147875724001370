import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';
import { onlyNumber } from '@helpers/StringHelper';

const ADDITIONAL_COST_URL = '/api/additional-cost';

export const initial = {
  additionalCostList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 0,
      totalPage: 0,
    },
  },

  additionalCost: {
    codeSeq: '',
    codeId: '',
    codeNm: '',
    categoryCodeSeq: '',
    categoryCodeNm: '',
    etcCn: null,
    useAt: 1,
  },
};

export const additionalCostValidationSchema = yup.object({
  // 제품명, 브랜드, 카테고리, 공급처, 공급가, 소비자가
  codeId: yup.string().required('코드ID는 필수 입력 값 입니다.'),
  categoryCodeSeq: yup.string().required('카테고리는 필수 선택 값 입니다.'),
  codeNm: yup.string().required('공임비는 필수 입력 값 입니다.'),
  // codeNm: yup
  //   .string()
  //   .test({
  //     message: '공임비는 0보다 큰 필수 입력 값 입니다.',
  //     test: (value) => {
  //       const codeNm = onlyNumber(value?.replaceAll(',', ''));
  //       if (!codeNm) {
  //         return false;
  //       }
  //
  //       if (codeNm == 0) {
  //         return false;
  //       }
  //       return true;
  //     },
  //   })
  //   .required('공임비는 필수 입력 값 입니다.'),
  // etcCn: yup.string().required('금액은 필수 값 입니다.'),
  etcCn: yup
    .string()
    .test({
      message: '금액은 0보다 큰 필수 입력 값 입니다.',
      test: (value) => {
        const etcCn = onlyNumber(value?.replaceAll(',', ''));
        if (!etcCn) {
          return false;
        }

        if (etcCn == 0) {
          return false;
        }
        return true;
      },
    })
    .required('금액은 필수 입력 값 입니다.'),
  useAt: yup.string().required('사용여부는 필수 선택 값 입니다.'),
});

export function createAdditionalCostStore(rootStore) {
  return makeAutoObservable({
    additionalCostList: initial.additionalCostList,
    additionalCost: initial.additionalCost,
    categoryList: [],
    alertMessage: '',

    async getAdditionalCostList(options) {
      try {
        const res = await authApi.get(
          ADDITIONAL_COST_URL,
          { ...options },
          null,
        );

        runInAction(() => {
          this.additionalCostList = res;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getAdditionalCost(codeSeq) {
      try {
        const { data } = await authApi.get(
          `${ADDITIONAL_COST_URL}/${codeSeq}`,
          null,
          null,
        );

        runInAction(() => {
          this.additionalCost = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveAdditionalCost({ categoryCodeSeq, codeId, codeNm, etcCn }) {
      try {
        await authApi.post(ADDITIONAL_COST_URL, {
          categoryCodeSeq,
          codeId,
          codeNm,
          etcCn,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async deleteAdditionalCost(codeSeq) {
      try {
        await authApi.delete(`${ADDITIONAL_COST_URL}/${codeSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async updateAdditionalCost(
      codeSeq,
      { categoryCodeSeq, codeId, codeNm, etcCn, useAt },
    ) {
      try {
        await authApi.patch(`${ADDITIONAL_COST_URL}/${codeSeq}`, {
          categoryCodeSeq,
          codeId,
          codeNm,
          etcCn,
          useAt,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async getCategoryList() {
      try {
        const { data } = await authApi.get(
          `${ADDITIONAL_COST_URL}/support/category`,
          null,
          null,
        );
        runInAction(() => {
          this.categoryList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async getCodeId(categoryCodeSeq) {
      try {
        const {
          data: { lastCodeId },
        } = await authApi.get(
          `${ADDITIONAL_COST_URL}/support/code-id`,
          { categoryCodeSeq },
          null,
        );
        return lastCodeId;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return null;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
