import {format, parse, differenceInDays, differenceInMonths, differenceInMinutes} from 'date-fns';
import { isEmpty } from '@helpers/StringHelper';
// import { ko } from 'date-fns/locale';

export const DISPLAY_FORMAT = 'yyyy-MM-dd';
export const PARAM_FORMAT = 'yyyy-MM-dd';

/**
 * 오늘 일자 반환(문자열 객체)
 * 기본 포맷 : yyyy-MM-dd
 * @param formatter
 * @returns {string}
 */
export const getToday = (formatter = DISPLAY_FORMAT) =>
  format(new Date(), formatter);

/**
 * 오늘 일자 및 현재 시간 반환
 * @returns {string} - yyyy-MM-dd HH:mm:ss
 */
export const getTodayDateTime = () => format(new Date(), 'yyyy-MM-dd HH:mm:ss');

/**
 * 문자열 일자 date 객체 반환(yyyy-MM-dd)
 * @param date
 * @returns {Date}
 */
export const stringToDate = (date) => parse(date, DISPLAY_FORMAT, new Date());

/**
 * 년도 정보 반환
 * @param year 더(+)할 년도
 * @param formatter 포맷
 * @returns {string}
 */
export const getYear = (year, formatter = 'yyyy') => {
  const now = new Date();
  return format(
    year ? new Date(now.setFullYear(now.getFullYear() + year)) : now,
    formatter,
  );
};

/**
 * 월 정보 반환
 * @param month 더(+)할 월
 * @param formatter 포맷
 * @returns {string}
 */
export const getMonth = (month, formatter = 'MM') => {
  const now = new Date();
  const firstDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  return format(
    month
      ? new Date(firstDate.setMonth(firstDate.getMonth() + month))
      : firstDate,
    formatter,
  );
};

/**
 * 일 정보 반환
 * @param day 더(+)할 일
 * @param formatter 포맷
 * @returns {string}
 */
export const getDay = (day, formatter = 'dd') => {
  const now = new Date();
  return format(
    day ? new Date(now.setDate(now.getDate() + day)) : now,
    formatter,
  );
};

/**
 * 오늘 날짜와 date차이 일수
 * 오늘 날짜 - date = 일수
 * @param date
 * @returns {number} 일수
 */
export const getTodayDiff = (date) => {
  const today = new Date();
  return differenceInDays(today, date);
};

/**
 * 두 날짜 차이 일수
 * endDate - startDate = 일수
 * @param startDate
 * @param endDate
 * @returns {number} 일수
 */
export const calDateDiff = (startDate, endDate) => {
  let d1 = startDate;
  let d2 = endDate;
  if (typeof d1 === 'string') {
    d1 = new Date(startDate);
  }
  if (typeof d2 === 'string') {
    d2 = new Date(endDate);
  }
  return differenceInDays(d2, d1);
};

export const getMonthDiff = (startDate, endDate) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  return differenceInMonths(date1, date2);
};

export const getDayDiff = (startDate, endDate) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  return differenceInDays(date1, date2);
}

export const getMinutesDiff = (startDate, endDate) =>{
  let d1 = startDate;
  let d2 = endDate;
  if (typeof d1 === 'string') {
    d1 = new Date(startDate);
  }
  if (typeof d2 === 'string') {
    d2 = new Date(endDate);
  }
  return differenceInMinutes(d2, d1);
}

/**
 * 현재 분 + minutes
 * @param minutes
 * @returns {Date}
 */
export const addMinutes = (minutes) => {
  const date = new Date();
  date.setMinutes(date.getMinutes() + minutes);
  return date;
};

const leftPad = (value) => {
  if (value >= 10) {
    return value;
  }

  return `0${value}`;
};

export const getDate = (source, delimiter = '-') => {
  if (isEmpty(source)) {
    return null;
  }
  const date = new Date(source);
  const year = date.getFullYear();
  const month = leftPad(date.getMonth() + 1);
  const day = leftPad(date.getDate());

  return [year, month, day].join(delimiter);
};

export const getDateTime = (
  source,
  dateDelimiter = '-',
  hourDelimiter = ':',
) => {
  if (isEmpty(source)) {
    return null;
  }

  const date = new Date(source);
  const year = date.getFullYear();
  const month = leftPad(date.getMonth() + 1);
  const day = leftPad(date.getDate());
  const hours = leftPad(date.getHours());
  const minutes = leftPad(date.getMinutes());
  const seconds = leftPad(date.getSeconds());

  return `${[year, month, day].join(dateDelimiter)} ${[
    hours,
    minutes,
    seconds,
  ].join(hourDelimiter)}`;
};

export const getTime = (source, delimiter = ':') => {
  if (isEmpty(source)) {
    return null;
  }

  const date = new Date(source);
  const hours = leftPad(date.getHours());
  const minutes = leftPad(date.getMinutes());
  const seconds = leftPad(date.getSeconds());

  return [hours, minutes, seconds].join(delimiter);
};

export const getHours = (source) => {
  if (isEmpty(source)) {
    return null;
  }
  const date = new Date(source);
  const hours = leftPad(date.getHours());
  return hours;
};

export const getMinutes = (source) => {
  if (isEmpty(source)) {
    return null;
  }

  const date = new Date(source);
  const minutes = leftPad(date.getMinutes());
  return minutes;
};
