import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

const CENTER_RETURN_ORDER_URL = '/api/item/center-return-order';

export const initial = {
  centerReturnOrderList: {
    data: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },

  centerReturnOrderStatusList: [],
};

// 센터반납관리
export function createMaterialCenterReturnOrderStore(rootStore) {
  return makeAutoObservable({
    centerReturnOrderList: initial.centerReturnOrderList,
    centerReturnOrderStatusList: initial.centerReturnOrderStatusList,

    centerList: [], // 센터 목록

    alertMessage: '',

    async getCenterReturnOrderList(options) {
      try {
        const res = await authApi.get(
          CENTER_RETURN_ORDER_URL,
          { ...options },
          null,
        );

        runInAction(() => {
          this.centerReturnOrderList = res;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCenterReturnOrderStatusList(options) {
      try {
        const { data } = await authApi.get(
          `${CENTER_RETURN_ORDER_URL}/application`,
          { ...options },
          null,
        );

        runInAction(() => {
          this.centerReturnOrderStatusList = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateCenterReturnOrder({ orderSeq, orderSttus }) {
      try {
        await authApi.put(`${CENTER_RETURN_ORDER_URL}/change`, {
          orderSeq,
          orderSttus,
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteCenterReturnOrders(orderSeqs) {
      try {
        await authApi.delete(CENTER_RETURN_ORDER_URL, orderSeqs);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCenterList() {
      try {
        const { data } = await authApi.get(
          `${CENTER_RETURN_ORDER_URL}/support/centerList`,
          null,
          null,
        );

        runInAction(() => {
          this.centerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
