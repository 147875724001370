import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import { isEmpty } from '@helpers/StringHelper';

import * as yup from 'yup';

const COMPLETE_URL = '/api/service-info/reception-log/complete';

export const initial = {
  completeList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 20,
      pageNumber: 1,
      totalPage: 0,
    },
  },

  complete: {
    rceptSeq: '',
  },
};

export function createCompleteStore(rootStore) {
  return makeAutoObservable({
    completeList: initial.completeList,
    complete: initial.complete,

    customerCenterList: [], // 고객사

    async getCompleteList({
      supplierEnterpriseSeq,
      rcepterEnterpriseSeq,
      rcepterMemberNm,
      rceptSe,
      cmptncEnterpriseSeq,
      opetrMemberSeq,
      opetrMemberNm,
      serviceSttus,
      rceptDtSt,
      rceptDtEd,
      processDtSt,
      processDtEd,
      modelNm,
      customerNm,
      cttpc,
      enterpriseNm,
      adres,
      setleSe,
      number,
      size,
      rceptSeq,
    }) {
      try {
        const res = await authApi.get(
          COMPLETE_URL,
          {
            supplierEnterpriseSeq: supplierEnterpriseSeq
              ? supplierEnterpriseSeq?.join(',')
              : null,
            rcepterEnterpriseSeq:
              typeof rcepterEnterpriseSeq === 'object'
                ? rcepterEnterpriseSeq?.join(',')
                : rcepterEnterpriseSeq,
            rcepterMemberNm: rcepterMemberNm?.[0] ?? null,
            rceptSe: rceptSe ? rceptSe.join(',') : null,
            cmptncEnterpriseSeq: cmptncEnterpriseSeq
              ? cmptncEnterpriseSeq.join(',')
              : null,
            opetrMemberSeq: opetrMemberSeq ? opetrMemberSeq.join(',') : null,
            opetrMemberNm: opetrMemberNm ? opetrMemberNm.join(',') : null,
            serviceSttus: serviceSttus ? serviceSttus.join(',') : null,
            rceptDtSt: rceptDtSt?.[0] ?? null,
            rceptDtEd: rceptDtEd?.[0] ?? null,
            processDtSt: processDtSt?.[0] ?? null,
            processDtEd: processDtEd?.[0] ?? null,
            modelNm: modelNm?.[0] ?? null,
            customerNm: customerNm?.[0] ?? null,
            cttpc: cttpc?.[0] ?? null,
            enterpriseNm: enterpriseNm?.[0] ?? null,
            adres: adres?.[0] ?? null,
            setleSe: setleSe?.[0] ?? null,
            number: number?.[0] ?? 1,
            size: size?.[0] ?? 20,
            rceptSeq: rceptSeq?.[0] ?? null,
          },
          null,
        );

        runInAction(() => {
          this.completeList = res;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.completeList = initial.completeList;
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getComplete(serviceInfoSeq) {
      try {
        const { data } = await authApi.get(
          `${COMPLETE_URL}/${serviceInfoSeq}`,
          null,
          null,
        );

        runInAction(() => {
          this.complete = data;
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateCompleteCancel(params) {
      try {
        await authApi.put(`${COMPLETE_URL}/cancel`, params);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    /**
     * 모바일에서 전화 문자 버튼 클릭시 미컨택인 건은 컨택으로 변경
     * @param params
     * @returns {Promise<boolean>}
     */
    async updateContact(serviceInfoSeq) {
      try {
        await authApi.put(`${COMPLETE_URL}/${serviceInfoSeq}/contact`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateDeposit(serviceInfoSeq) {
      try {
        await authApi.put(`${COMPLETE_URL}/deposit`, { serviceInfoSeq });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCustomerCenterList() {
      try {
        const { data } = await authApi.get(
          `${COMPLETE_URL}/support/customerList`,
          null,
          null,
        );

        runInAction(() => {
          this.customerCenterList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.customerCenterList = [];
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
