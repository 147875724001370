import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';

const ENTERPRISE_CENTER_URL = '/api/enterprise/center';

export const initial = {
  centerList: {
    // 센터 목록
    data: [],
    pageable: {
      totalCount: 0,
      pageNumber: 0,
      pageSize: 10,
      totalPage: 0,
    },
  },
  center: {
    // 센터 목록 기본 Object
    enterpriseSeq: null, // 업체 번호
    enterpriseSe: null, // 업체구분(1:센터, 2:고객사)
    enterpriseNm: '', // 업체명
    rprsntvNm: '', // 대표자명
    centerHdchMemberSeq: '', // 센터소장 회원번호
    bizrno: '', // 사업자 등록 번호
    jurirno: '', // 법인 등록 번호
    ctmmnySe: null, // 고객사 구분(1:고객사, 2:제조사, 3:공급처)
    centerSe: null, // 센터 구분(1:직영센터, 2:발주처 센터)
    rprsntvEmail: '', // 대표자 이메일
    zip: '', // 우편번호
    adres: '', // 주소
    detailAdres: '', // 상세 주소
    signguCode: '', // 시군구 코드
    legaldongCode: '', // 법정동 코드
    dlvyPaprZip: '', // 배송지 우편번호
    dlvyPaprAdres: '', // 배송지 주소
    dlvyPaprDetailAdres: '', // 배송지 상세 주소
    dlvyPaprSignguCode: '', // 배송지 시군구 코드
    dlvyPaprLegaldongCode: '', // 배송지법정동코드
    telno: '', // 전화번호
    fxnum: '', // 팩스번호
    chargerNm: '', // 담당자명
    chargerCttpc: '', // 담당자 연락처
    estblDt: '', // 개설 날짜
    endDt: '', // 종료 날짜
    partclrMatter: '', // 특이사항
    btrps: null, // 출장비
    evlScore: null, // 평가점수
    member: null, // 센터장 회원 정보
    customerList: [], // 고객사 리스트
    memberList: [], //임직원 리스트
    brandCodeList: [], // 브랜드 리스트
  },
  customer: {
    // 고객사 목록 기본 Object
    enterpriseSeq: 0,
    enterpriseNm: 0,
  },
  hdchMember: {
    // 센터 소장 목록 기본 Object
    memberSeq: null,
    nm: null,
    moblphonNo: null,
    email: null,
    psitnEnterpriseName: null,
    psitnTeamCode: null,
    psitnTeamName: null,
    chrgJobCode: null,
    chrgJobName: null,
    clsfCode: null,
    clsfName: null,
    rspofcCode: null,
    rspofcName: null,
    ecnyDt: null,
    zip: null,
    adres: null,
    partclrMatter: null,
  },

  member: {
    // 임직원 목록 기본 Object
    memberSeq: 1,
    nm: '',
    moblphonNo: null,
    email: null,
    psitnEnterpriseName: null,
    psitnTeamCode: null,
    psitnTeamName: null,
    chrgJobCode: null,
    chrgJobName: null,
    clsfCode: null,
    clsfName: null,
    rspofcCode: null,
    rspofcName: null,
    ecnyDt: '',
    zip: null,
    adres: null,
    partclrMatter: null,
  },
};

export const centerValidationSchema = yup.object({
  enterpriseNm: yup.string().required('센터명은 필수 값 입니다.'),
  rprsntvNm: yup.string().required('대표자명은 필수 값 입니다.'),
  rprsntvEmail: yup
    .string()
    .matches(
      /^[0-9A-Z]([-_\.]?[0-9A-Z])*@[0-9A-Z]([-_\.]?[0-9A-Z])*\.[A-Z]{2,6}$/i,
      '이메일 형식을 확인해주세요',
    )
    .nullable(),
  // centerHdchMemberSeq: yup.number().required('센터소장명은 필수 값 입니다.'),
  centerSe: yup.number().required('센터구분은 필수 값 입니다.'),
  bizrno: yup.string().required('사업자번호는 필수 값 입니다.'),
  zip: yup.string().required('주소는 필수 값 입니다.'),
  adres: yup.string().required('주소는 필수 값 입니다.'),
  detailAdres: yup.string().required('주소는 필수 값 입니다.'),
  telno: yup.string().required('전화번호는 필수 값 입니다.'),
  estblDt: yup.string().required('개설일은 필수 값 입니다.'),
  brandCodeList: yup.array().min(1, '브랜드는 필수 선택 값 입니다.'),
});

export function createCenterStore(rootStore) {
  return makeAutoObservable({
    centerList: initial.centerList,
    center: initial.center,

    centerHdchList: [],
    customerList: [],

    brandCodeList: [],

    alertMessage: '',

    async getCenterList(options) {
      try {
        const res = await authApi.get(
          ENTERPRISE_CENTER_URL,
          {
            ...options,
          },
          null,
        );
        runInAction(() => {
          this.centerList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async getCenter(enterpriseSeq) {
      try {
        const { data } = await authApi.get(
          `${ENTERPRISE_CENTER_URL}/${enterpriseSeq}`,
          null,
          null,
        );

        runInAction(() => {
          this.center = {
            ...data,
            brandCodeList: data.brandList.map((item) => item.brandCodeSeq),
          };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveCenter(center) {
      try {
        await authApi.post(ENTERPRISE_CENTER_URL, { ...center });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateCenter(values, enterpriseSeq) {
      try {
        await authApi.put(`${ENTERPRISE_CENTER_URL}/${enterpriseSeq}`, {
          ...values,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteCenter(enterpriseSeq) {
      try {
        await authApi.delete(`${ENTERPRISE_CENTER_URL}/${enterpriseSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCenterHdchList(enterpriseSeq) {
      try {
        // 센터소장 목록 조회
        const { data } = await authApi.get(
          `${ENTERPRISE_CENTER_URL}/centerHdchList`,
          { enterpriseSeq },
          null,
        );
        runInAction(() => {
          this.centerHdchList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCustomerList() {
      try {
        // 고객사 목록 조회
        const { data } = await authApi.get(
          `${ENTERPRISE_CENTER_URL}/customerList`,
          null,
          null,
        );
        runInAction(() => {
          this.customerList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getBrandCodeList() {
      try {
        const { data } = await authApi.get(
          `${ENTERPRISE_CENTER_URL}/support/brandCodeList`,
          null,
          null,
        );
        runInAction(() => {
          this.brandCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.brandCodeList = [];
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
  });
}
