import { lazy } from 'react';

/**
 * Layout 목록
 */
const MainLayout = lazy(() =>
    import(/* webpackChunkName: "MinimalLayout" */ '@templates/MainLayout'),
);

/**
 * Routing Page 목록
 */
const PaymentSample = lazy(() =>
  import(
    /* webpackChunkName: "LoginPage" */ '@pages/common/sample/payment'
  ),
);

const routes = [
    {
        name: '결제테스트',
        path: '/sample/payment',
        component: PaymentSample,
        layout: MainLayout,
        isAuth: true,
        isPc: true,
        isMobile: true,
        isBookmarked: false,
        permissions: [],
    },
];

export default routes;
