import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';
import { onlyNumber } from '@helpers/StringHelper';

const CUSTOMER_MANAGE_URL = '/api/service-info/customer';

export const initial = {
  customerList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
    },
  },
  customer: {
    serviceInfoSeq: null,

    // 변경될
    customerNm: '',
    cttpc: '',
    zip: '',
    adres: '',
    detailAdres: '',
    signguCode: '',
    legaldongCode: '',

    // 변경 전
    preCustomerNm: '',
    preCttpc: '',
    preZip: '',
    preAdres: '',
    preDetailAdres: '',
    preSignguCode: '',
    preLegaldongCode: '',
  },
};

export const customerManageValidationSchema = yup.object({
  customerNm: yup.string().required('고객명은 필수 입력 값 입니다.'),
  cttpc: yup
    .string()
    .test({
      message: '연락처는 필수 입력 값 입니다.',
      test: (value) => {
        if (!onlyNumber(value.replaceAll('-', ''))) {
          return false;
        }
        return true;
      },
    })
    .required('전화번호는 필수 입력 값 입니다.'),
  zip: yup.string().required('주소는 필수 입력 값 입니다.'),
  adres: yup.string().required('주소는 필수 입력 값 입니다.'),
  detailAdres: yup.string().required('주소는 필수 입력 값 입니다.'),
});

export function createCustomerManageStore(root) {
  return makeAutoObservable({
    customerList: initial.customerList,
    customer: initial.customer,

    alertMessage: '',

    async getCustomerList(options) {
      try {
        const res = await authApi.get(
          CUSTOMER_MANAGE_URL,
          { ...options },
          null,
        );

        runInAction(() => {
          this.customerList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCustomer(serviceInfoSeq) {
      try {
        const { data } = await authApi.get(
          `${CUSTOMER_MANAGE_URL}/${serviceInfoSeq}`,
          null,
          null,
        );

        runInAction(() => {
          this.customer = {
            ...data,
            preCustomerNm: data?.customerNm,
            preCttpc: data?.cttpc,
            preZip: data?.zip,
            preAdres: data?.adres,
            preDetailAdres: data?.detailAdres,
            preSignguCode: data?.signguCode,
            preLegaldongCode: data?.legaldongCode,
          };
        });

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateCustomer(params) {
      try {
        await authApi.put(CUSTOMER_MANAGE_URL, { ...params });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
