import { lazy } from 'react';

/**
 * Layout 목록
 */
const MainLayout = lazy(() =>
  import(/* webpackChunkName: "MainLayout" */ '@templates/MainLayout'),
);

/**
 * Routing Page 목록
 */

// 직원관리
const EmployeePage = lazy(() =>
  import(/* webpackChunkName: "EmployeePage" */ '@pages/admin/employee'),
);

const getRoutes = () => {
  const routes = [
    {
      name: '직원관리',
      path: '/employee',
      component: EmployeePage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
  ];

  return routes;
};

export default getRoutes();
