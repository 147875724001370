import { makeAutoObservable, runInAction } from 'mobx';
import {
  getLocalStorageWithExpireTime,
  removeLocalStorage,
  setLocalStorageWithExpireTime,
  setSessionStorage,
} from '@helpers/StorageHelper';
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN_EXPIRES_IN,
  REFRESH_TOKEN,
} from '@const/env';
import { Cookies } from 'react-cookie';
import { defaultApi } from '@helpers/AxiosHelper';

const LOGIN_URL = '/api/login';

const cookies = new Cookies();
export const initial = {
  auth: {
    accessToken: null,
    refreshToken: null,
    accessTokenExpiresDateTime: null,
  },
};

export function createLoginStore(rootStore) {
  return makeAutoObservable({
    auth: initial.auth,
    alertMessage: '',

    async login(id, password) {
      try {
        removeLocalStorage(REFRESH_TOKEN);
        const {
          data: { accessToken, refreshToken },
        } = await defaultApi.post(LOGIN_URL, { id, password });

        // accessToken을 sessionStorage에 저장
        setSessionStorage(ACCESS_TOKEN, accessToken);
        // refreshToken을 localStorage에 저장
        this.refreshTokenSave(refreshToken);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async tokenReissue() {
      try {
        const {
          data: { accessToken, refreshToken },
        } = await defaultApi.post(`${LOGIN_URL}/re-issue`, {
          refreshToken: getLocalStorageWithExpireTime(REFRESH_TOKEN),
        });
        runInAction(() => {
          this.auth = {
            accessToken,
            refreshToken,
          };
          // accessToken을 sessionStorage에 저장
          setSessionStorage(ACCESS_TOKEN, accessToken);
          // refreshToken을 localStorage에 저장
          this.refreshTokenSave(refreshToken);
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },

    refreshTokenSave(refreshToken) {
      setLocalStorageWithExpireTime(
        REFRESH_TOKEN,
        refreshToken,
        REFRESH_TOKEN_EXPIRES_IN * 1000,
      );
    },
  });
}
