import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

export const initial = {
  centerList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageNumber: 0,
      pageSize: 10,
      totalPage: 0,
    },
  },
  detailCenterList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageNumber: 1,
      pageSize: 10,
      totalPage: 0,
    },
  },
  center: {
    enterpriseSeq: '',
    enterpriseSe: '',
    enterpriseNm: '',
    rprsntvNm: '',
    centerHdchMemberSeq: '',
    bizrno: '',
    jurirno: '',
    ctmmnySe: '',
    centerSe: '',
    rprsntvEmail: '',
    zip: '',
    adres: '',
    detailAdres: '',
    signguCode: '',
    dlvyPaprZip: '',
    dlvyPaprAdres: '',
    dlvyPaprDetailAdres: '',
    dlvyPaprSignguCode: '',
    telno: '',
    fxnum: '',
    chargerNm: '',
    chargerCttpc: '',
    estblDt: '',
    endDt: '',
    partclrMatter: '',
    btrps: '',
    evlScore: '',
    member: '',
    customerList: [],
    memberList: [],
  },

  detailPageOptions: {
    enterpriseSeq: null,
    number: 1,
    size: 10,
  },
};

export function createProductStore(rootStore) {
  return makeAutoObservable({
    centerList: initial.centerList,

    center: initial.center,
    detailCenterList: initial.detailCenterList,

    detailPageOptions: initial.detailPageOptions,
    centerMemberList: [],
    alertMessage: '',

    async getCenterList(options) {
      try {
        const res = await authApi.get(
          '/api/asign/prdlst',
          {
            ...options,
          },
          null,
        );

        runInAction(() => {
          this.centerList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        this.alertMessage = alertMessage;
        return false;
      }
    },

    async getCenterInfo(enterpriseSeq) {
      try {
        const { data } = await authApi.get(
          `/api/asign/prdlst/enterprise/${enterpriseSeq}`,
          null,
          null,
        );
        runInAction(() => {
          this.center = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        this.alertMessage = alertMessage;
        return false;
      }
    },

    async getCenter(enterpriseSeq) {
      try {
        const res = await authApi.get(
          `/api/asign/prdlst/${enterpriseSeq}`,
          {
            ...this.detailPageOptions,
          },
          null,
        );
        runInAction(() => {
          this.detailCenterList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        this.alertMessage = alertMessage;
        return false;
      }
    },

    async getCenterMemberList(enterpriseSeq) {
      try {
        const { data } = await authApi.get(
          `/api/asign/prdlst/${enterpriseSeq}/prdlstCenterMemberList`,
          null,
          null,
        );
        runInAction(() => {
          this.centerMemberList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        this.alertMessage = alertMessage;
        return false;
      }
    },

    async updateCenter(params) {
      try {
        await authApi.put('/api/asign/prdlst', { prdlstList: params });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        this.alertMessage = alertMessage;
        return false;
      }
    },

    setDetailPageOptions(value, init) {
      runInAction(() => {
        this.detailPageOptions = init
          ? {
              ...initial.detailPageOptions,
              ...value,
            }
          : {
              ...this.detailPageOptions,
              ...value,
            };
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
