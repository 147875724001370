import { lazy } from 'react';

/**
 * Layout 목록
 */
const MinimalLayout = lazy(() =>
  import(/* webpackChunkName: "MinimalLayout" */ '@templates/MinimalLayout'),
);

/**
 * Routing Page 목록
 */
// 로그인
const LoginPage = lazy(() =>
  import(/* webpackChunkName: "LoginPage" */ '@pages/common/auth/LoginPage'),
);

// 아이디/비밀번호 찾기
const ForgotAccountPage = lazy(() =>
  import(
    /* webpackChunkName: "LoginPage" */ '@pages/common/auth/forgot-account'
  ),
);

// 로그인
const SignUpPage = lazy(() =>
  import(/* webpackChunkName: "SignUpPage" */ '@pages/common/auth/signup'),
);

// const FindIdForm = lazy(() =>
//   import(/* webpackChunkName: "LoginPage" */ '@pages/common/auth/FindIdForm'),
// );

// const FindPasswordForm = lazy(() =>
//   import(
//     /* webpackChunkName: "LoginPage" */ '@pages/common/auth/FindPasswordForm'
//   ),
// );

const routes = [
  {
    name: '로그인',
    path: '/login',
    component: LoginPage,
    layout: MinimalLayout,
    isAuth: false,
    isPc: true,
    isMobile: true,
    isBookmarked: false,
    permissions: [],
  },
  {
    name: '계정 찾기',
    path: '/common/auth/forgot-account/:tabName',
    component: ForgotAccountPage,
    layout: MinimalLayout,
    isAuth: false,
    isPc: true,
    isMobile: true,
    isBookmarked: false,
    permissions: [],
  },

  {
    name: '회원가입',
    path: '/signup',
    component: SignUpPage,
    layout: MinimalLayout,
    isAuth: false,
    isPc: true,
    isMobile: true,
    isBookmarked: false,
    permissions: [],
  },
];

export default routes;
