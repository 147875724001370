export const isMobile = () => {
  const agent = navigator.userAgent;
  if (
    agent.match(
      /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i,
    ) != null ||
    agent.match(/LG|SAMSUNG|Samsung/) != null
  ) {
    return true;
  }
  return false;
};

export const isIOS = () => {
  const useAgent = navigator.userAgent.toLowerCase();
  if (
    useAgent.indexOf('iphone') > -1 ||
    useAgent.indexOf('ipad') > -1 ||
    useAgent.indexOf('ipod') > -1
  ) {
    return true;
  }
  return false;
};

export const isMobileApp = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  // meisterkorapp
  if (userAgent.match(/meisterkorapp/i) != null) {
    return true;
  }
  return false;
};

export const isAndroidApp = () => {
  if (isMobileApp()) {
    const useAgent = navigator.userAgent.toLowerCase();
    if (useAgent.indexOf('android') > -1) {
      return true;
    }
    return false;
  }
  return false;
};

export const isIOSApp = () => {
  if (isMobileApp()) {
    const useAgent = navigator.userAgent.toLowerCase();
    if (
      useAgent.indexOf('iphone') > -1 ||
      useAgent.indexOf('ipad') > -1 ||
      useAgent.indexOf('ipod') > -1
    ) {
      return true;
    }
    return false;
  }
  return false;
};

// 모바일 가로모드 여부
export const isLandScapeMode = () =>
  window.matchMedia('(orientation: landscape)').matches;
