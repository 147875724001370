import { makeAutoObservable, runInAction } from 'mobx';
import * as yup from 'yup';
import { authApi } from '@helpers/AxiosHelper';
import { onlyNumber } from '@helpers/StringHelper';

export const initial = {
  menuList: [],
  mobileMenuList: [],
  menu: {
    menuSeq: 0,
    upperMenuSeq: 0,
    menuNm: '',
    menuLevel: 0,
    childMenus: [],
  },

  menuDetail: {
    menuSeq: '',
    upperMenuSeq: '',
    menuLevel: '',
    iconNm: '',
    sortOrdr: '',
    mobileSortOrdr: '',
    menuNm: '',
    mobileMenuNm: '',
    isDisplayMobile: '',
    isGenerativeBbs: '',
    url: null,
    regId: '',
    regDt: '',
    updId: '',
    updDt: '',
  },
};

// export const menuValidationSchema = yup.object({
//   menuSeq: yup.string().required('메뉴 ID는 필수 값 입니다.'),
//   upperMenuSeq: yup.string().required('상위 메뉴 ID는 필수 값 입니다.'),
//   iconNm: yup.string().required('아이콘명은 필수 값 입니다.'),
//   sortOrdr: yup
//     .string()
//     .test({
//       message: '정렬순서값은 0보다 커야하고 필수 값 입니다.',
//       test: (value) => {
//         if (!onlyNumber(value.replaceAll('-', ''))) {
//           return false;
//         }
//         if (onlyNumber(value.replaceAll('-', '')) <= 0) {
//           return false;
//         }
//         return true;
//       },
//     })
//     .required('정렬순서는 필수 값 입니다.'),
//   mobileSortOrdr: yup
//     .string()
//     .test({
//       message: '모바일 정렬순서값은 0보다 커야하고 필수 값 입니다.',
//       test: (value) => {
//         if (!onlyNumber(value.replaceAll('-', ''))) {
//           return false;
//         }
//         if (onlyNumber(value.replaceAll('-', '')) <= 0) {
//           return false;
//         }
//         return true;
//       },
//     })
//     .required('모바일 정렬순서는 필수 값 입니다.'),
//   menuNm: yup.string().required('메뉴명은 필수 값 입니다.'),
//   mobileMenuNm: yup.string().required('모바일 메뉴명은 필수 값 입니다.'),
//   isDisplayMobile: yup.string().required('모바일 여부를 선택해주세요.'),
//   isGenerativeBbs: yup.string().required('생성형 게시판 여부를 선택해주세요.'),
//   url: yup.string().required('URL은 필수 값 입니다.'),
// });

export const pcMenuValidationSchema = yup.object({
  menuSeq: yup.string().required('메뉴 ID는 필수 값 입니다.'),
  upperMenuSeq: yup.string().required('상위 메뉴 ID는 필수 값 입니다.'),
  sortOrdr: yup
    .string()
    .test({
      message: '정렬순서값은 0보다 커야하고 필수 값 입니다.',
      test: (value) => {
        if (!onlyNumber(value.replaceAll('-', ''))) {
          return false;
        }
        if (onlyNumber(value.replaceAll('-', '')) <= 0) {
          return false;
        }
        return true;
      },
    })
    .required('정렬순서는 필수 값 입니다.'),
  menuNm: yup.string().required('메뉴명은 필수 값 입니다.'),
  isDisplayMobile: yup.string().required('모바일 여부를 선택해주세요.'),
  isGenerativeBbs: yup.string().required('생성형 게시판 여부를 선택해주세요.'),
  // url: yup.string().required('URL은 필수 값 입니다.'),
});

export const mobileMenuValidationSchema = yup.object({
  mobileSortOrdr: yup
    .string()
    .test({
      message: '모바일 정렬순서값은 0보다 커야하고 필수 값 입니다.',
      test: (value) => {
        if (!onlyNumber(value.replaceAll('-', ''))) {
          return false;
        }
        if (onlyNumber(value.replaceAll('-', '')) <= 0) {
          return false;
        }
        return true;
      },
    })
    .required('모바일 정렬순서는 필수 값 입니다.'),
  mobileMenuNm: yup.string().required('모바일 메뉴명은 필수 값 입니다.'),
  // url: yup.string().required('URL은 필수 값 입니다.'),
});

export function createMenuStore(rootStore) {
  return makeAutoObservable({
    menuList: initial.menuList,
    mobileMenuList: initial.mobileMenuList,
    menuDetail: initial.menuDetail,
    menuDetailInitial: initial.menuDetail,
    lastMenuSeq: '',

    alertMessage: '',

    async getMenuList() {
      try {
        const { data } = await authApi.get('/api/menu/manager', null, null);
        runInAction(() => {
          this.menuList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getMobileMenuList() {
      try {
        const { data } = await authApi.get(
          '/api/menu/manager/mobile',
          null,
          null,
        );
        runInAction(() => {
          this.mobileMenuList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getLastMenuSeq() {
      try {
        const { data } = await authApi.get(
          '/api/menu/manager/support/generated-menu-id',
          null,
          null,
        );
        runInAction(() => {
          this.lastMenuSeq = data.toBeCreatedMenuSeq;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveMenu(params) {
      try {
        await authApi.post('/api/menu/manager', params);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getMenuDetail(menuSeq) {
      try {
        const { data } = await authApi.get(
          `/api/menu/manager/${menuSeq}`,
          null,
          null,
        );
        runInAction(() => {
          this.menuDetail = {
            ...data,
          };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateMenu(menuSeq, params) {
      try {
        await authApi.patch(`/api/menu/manager/${menuSeq}`, params);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteMenu(menuSeq) {
      try {
        await authApi.delete(`/api/menu/manager/${menuSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },

    transMenuArr(menuList) {
      const menuArr = [];
      for (let i = 0; i < menuList.length; i++) {
        menuArr.push(menuList[i]);
        if (menuList[i].childMenus.length > 0) {
          for (let j = 0; j < menuList[i].childMenus.length; j++) {
            menuArr.push(menuList[i].childMenus[j]);
            if (menuList[i].childMenus[j].childMenus.length > 0) {
              for (
                let k = 0;
                k < menuList[i].childMenus[j].childMenus.length;
                k++
              ) {
                menuArr.push(menuList[i].childMenus[j].childMenus[k]);
              }
            }
          }
        }
      }
      return menuArr;
    },
  });
}
