// router module import
// 정산/통계 관리
// import settlementStatistics from '@router/module/admin/settlementStatistics';
// 정산/통계 관리
import statistics from '@router/module/admin/statistics';
// 자재/코드 관리
import materialCode from '@router/module/admin/materialCode';
// 업체 관리
import enterprise from '@router/module/admin/enterprise';
// 직원 관리
import employee from '@router/module/admin/employee';
// 배정 관리
import assignment from '@router/module/admin/assignment';
// 알림 관리
import alarm from '@router/module/admin/alarm';
// 권한 관리
import authority from '@router/module/admin/authority';
// 메뉴 관리
import menu from '@router/module/admin/menu';

const routes = [
    ...statistics,
    ...materialCode,
    ...enterprise,
    ...employee,
    ...assignment,
    ...alarm,
    ...authority,
    ...menu,
];

export default routes;
