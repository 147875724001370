const FAQ_URL = '/api/community/faq';

export const FAQ_TYPE = {
  faq: {
    name: 'faq',
    title: '자주묻는 질문',
    url: `${FAQ_URL}/faq`,
    tabs: [
      {
        seq: 0,
        label: '전체',
        faqSe: null,
      },
      {
        seq: 1,
        label: '홈페이지',
        faqSe: 1,
      },
      {
        seq: 2,
        label: '고객응대',
        faqSe: 2,
      },
      {
        seq: 3,
        label: '제품문의',
        faqSe: 3,
      },
      {
        seq: 4,
        label: '정산문의',
        faqSe: 4,
      },
      {
        seq: 5,
        label: '기타',
        faqSe: 5,
      },
    ],
  },
  'usage-methods': {
    name: 'usage-methods',
    title: '전산이용방법',
    url: `${FAQ_URL}/usage-methods`,
    tabs: [
      {
        seq: 0,
        label: '전체',
        faqSe: null,
      },
      {
        seq: 1,
        label: '전산처리',
        faqSe: 1,
      },
      {
        seq: 2,
        label: '기타',
        faqSe: 2,
      },
    ],
  },
  'pc-access': {
    name: 'pc-access',
    title: 'PC 접근',
    url: `${FAQ_URL}/pc-access`,
    tabs: [],
  },
};
