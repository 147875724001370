import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

const SETTING_URL = '/api/mypage/setting/alarm';

export const initial = {
  settings: {
    smsReceptAt: 0,
    pushReceptAt: 0,
  },
};

export function createSettingStore(root) {
  return makeAutoObservable({
    settings: initial.settings,
    alertMessage: '',

    async getAlarm() {
      try {
        const { data } = await authApi.get(SETTING_URL, null, null);
        runInAction(() => {
          this.settings = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateSetting(type, receptAt) {
      try {
        await authApi.put(`${SETTING_URL}/${type}`, { receptAt });
        runInAction(() => {
          this.settings = {
            ...this.settings,
            [`${type}ReceptAt`]: receptAt,
          };
        });
        return this.settings;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
