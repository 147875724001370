import React from 'react';

import { makeAutoObservable, runInAction } from 'mobx';

/*
 ******************************************
 ****************** COMMON *****************
 ******************************************
 */
import createGlobalStore from '@stores/common/GlobalStore';

import {
  createLoginStore,
  createSignupStore,
  createForgotAccountStore,
} from '@stores/common/auth';

import { createSettingStore } from '@stores/user/setting';

// 생성형 게시판
import { createGenerativeBbsStore } from '@stores/common/generative-bbs';

// dashboard
import { createDashboardStore } from '@stores/common/main';

import {
  createPersonalInfoStore,
  createMyPageInquiryStore,
  createMyPageAlarmStore,
} from '@stores/common/mypage';

/*
 ******************************************
 ****************** USER *****************
 ******************************************
 */
// 서비스 관리
import {
  createInstallationStore,
  createConsultationStore,
  createOutStandingStore,
  createCompleteStore,
  createAllReceptionStore,
  createCustomerManageStore,
} from 'src/stores/user/service-info';

// 스케쥴관리
import {
  createVisitStore,
  createCalendarStore,
  createRouteStore,
} from '@stores/user/schedule';

// 자재관리
import {
  createMaterialInventoryStore,
  createMaterialOrderStore,
  createMaterialOrderLogStore,
  createMaterialOrderCreateStore,
  createMaterialOrderOfeStore,
  createMaterialOrderActualStore,
  createMaterialOrderReturnCreateStore,
  createMaterialOrderReturnStore,
  createMaterialCenterOrderStore,
  createMaterialCenterReturnOrderStore,
} from 'src/stores/user/material-management';

// 커뮤니티
import {
  createCommunityNoticeStore,
  createCommunityReferenceStore,
  createCommunityServiceEduStore,
  createCommunityManualStore,
  createCommunityFaqStore,
  createCommunityQnaStore,
} from '@stores/user/community';

// 정산
import {
  createCustomerSettlementStore,
  createCenterSettlementStore,
} from '@stores/user/settlement';

/*
 ******************************************
 ****************** ADMIN *****************
 ******************************************
 */

// 정산/통계
import {
  createSettlementStore,
  createFeeStore,
  createReceptionStore,
  createServiceInfoStore,
  createItemStore,
  createModelDefectStore,
} from '@stores/admin/statistics';

// 자재/코드 관리
import {
  createClassificationStore,
  createModelStore,
  createMaterialStore,
  createCodeStore,
  createAdditionalCostStore,
  createAreaGradeStore,
} from '@stores/admin/material-code';

// 업체관리
import {
  createCenterStore,
  createCustomerStore,
} from 'src/stores/admin/enterprise';

// 직원관리
import { createEmployeeManageStore } from '@stores/admin/employee';

// 배정관리
import { createAreaStore, createProductStore } from '@stores/admin/assignment';

// 알림관리
import { createSmsAlarmStore, createPushAlarmStore } from '@stores/admin/alarm';

// 권한관리
import { createAuthorityStore, createAuthoritySystemStore } from '@stores/admin/authority';

// 메뉴관리
import { createMenuStore, createGenerateBoardStore } from '@stores/admin/menu';

const createRootStore = () => {
  const store = makeAutoObservable({
    // actions
    setNodeStores(nodeStores) {
      runInAction(() => {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const storeName in nodeStores) {
          this[storeName] = nodeStores[storeName];
        }
      });
    },
  });
  return store;
};

export const rootStore = createRootStore();

rootStore.setNodeStores({
  /**
   * COMMON
   */
  globalStore: createGlobalStore(rootStore),
  loginStore: createLoginStore(rootStore),
  signupStore: createSignupStore(rootStore),
  forgotAccountStore: createForgotAccountStore(rootStore),
  settingStore: createSettingStore(rootStore),

  // 생성형 게시판
  generativeBbsStore: createGenerativeBbsStore(rootStore),

  // dashboard
  dashboardStore: createDashboardStore(rootStore),

  // 마이페이지
  // 마이페이지 > 정보수정, 비밀번호 수정
  personalInfoStore: createPersonalInfoStore(rootStore),
  // 마이페이지 > 1:1문의 내용
  myPageInquiryStore: createMyPageInquiryStore(rootStore),
  // 마이페이지 > 알림내역
  myPageAlarmStore: createMyPageAlarmStore(rootStore),

  /**
   * USER
   */
  // 서비스 관리
  // 서비스 관리 > 상담접수
  consultationStore: createConsultationStore(rootStore),
  // 서비스 관리 > 설치접수
  installationStore: createInstallationStore(rootStore),
  // 서비스 관리 > 접수내역(설치/AS)
  // 서비스 관리 > 접수내역(설치/AS) > 미결서비스조회
  outstandingStore: createOutStandingStore(rootStore),
  // 서비스 관리 > 접수내역(설치/AS) > A/S처리완료조회
  completeStore: createCompleteStore(rootStore),
  // 서비스 관리 > 접수내역(설치/AS) > 전체접수조회
  allReceptionStore: createAllReceptionStore(rootStore),
  // 서비스 관리 > 고객관리
  customerManageStore: createCustomerManageStore(rootStore),

  // 스케줄관리
  // 스케줄관리 > 방문일정리스트
  visitStore: createVisitStore(rootStore),
  // 스케줄관리 > 캘린더
  calendarStore: createCalendarStore(rootStore),
  // 스케줄관리 > 동선보기
  routeStore: createRouteStore(rootStore),

  // 자재관리
  // 자재관리 > 보유재고 리스트
  inventoryStore: createMaterialInventoryStore(rootStore),
  // 자재관리 > 주문/반납
  orderStore: createMaterialOrderStore(rootStore),
  // 자재관리 > 주문/반납 내역
  orderLogStore: createMaterialOrderLogStore(rootStore),

  // 자재관리 > 발주신청
  orderCreateStore: createMaterialOrderCreateStore(rootStore),
  // 자재관리 > 발주서관리
  orderOfeStore: createMaterialOrderOfeStore(rootStore),
  // 자재관리 > 실입고목록
  orderActualStore: createMaterialOrderActualStore(rootStore),
  // 자재관리 > 자재반납신청
  orderReturnCreateStore: createMaterialOrderReturnCreateStore(rootStore),
  // 자재관리 > 자재반납관리
  orderReturnStore: createMaterialOrderReturnStore(rootStore),
  // 자재관리 > 센터출고관리
  centerOrderStore: createMaterialCenterOrderStore(rootStore),
  // 자재관리 > 센터반납관리
  centerReturnOrderStore: createMaterialCenterReturnOrderStore(rootStore),

  // 커뮤니티
  // 커뮤니티 > 공지사항
  noticeStore: createCommunityNoticeStore(rootStore),
  // 커뮤니티 > 자료실
  referenceStore: createCommunityReferenceStore(rootStore),
  // 커뮤니티 > 서비스교육
  serviceEduStore: createCommunityServiceEduStore(rootStore),
  // 커뮤니티 > 제품메뉴얼
  manualStore: createCommunityManualStore(rootStore),
  // 커뮤니티 > FAQ
  faqStore: createCommunityFaqStore(rootStore),
  // 커뮤니티 > 문의하기
  qnaStore: createCommunityQnaStore(rootStore),

  // 정산
  customerSettlementStore: createCustomerSettlementStore(rootStore),
  centerSettlementStore: createCenterSettlementStore(rootStore),

  /**
   * ADMIN
   */
  // 관리자메뉴 > 정산/통계 > 정산자료
  settlementStore: createSettlementStore(rootStore),
  // 관리자메뉴 > 정산/통계 > 수수료관리
  feeStore: createFeeStore(rootStore),
  // 관리자메뉴 > 정산/통계 > 접수현황통계
  receptionStore: createReceptionStore(rootStore),
  // 관리자메뉴 > 정산/통계 > 완료/컨택률 통계
  serviceInfoStore: createServiceInfoStore(rootStore),
  // 관리자메뉴 > 정산/통계 > 자재사용 통계
  itemStore: createItemStore(rootStore),
  // 관리자메뉴 > 정산/통계 > 모델별부품고장통계
  modelDefectStore: createModelDefectStore(rootStore),

  // 관리자메뉴 > 자재/코드 관리 > 분류/업체/카테고리
  classificationStore: createClassificationStore(rootStore),
  // 관리자메뉴 > 자재/코드 관리 > 제품리스트관리
  modelStore: createModelStore(rootStore),
  // 관리자메뉴 > 자재/코드 관리 > 자재관리
  materialStore: createMaterialStore(rootStore),
  // 관리자메뉴 > 자재/코드 관리 > 코드관리
  codeStore: createCodeStore(rootStore),
  // 관리자메뉴 > 자재/코드 관리 > 공임비관리
  additionalCostStore: createAdditionalCostStore(rootStore),
  // 관리자메뉴 > 자재/코드 관리 > 급지비용관리
  areaGradeStore: createAreaGradeStore(rootStore),

  // 관리자메뉴 > 업체관리 > 고객사관리
  customerStore: createCustomerStore(rootStore),
  // 관리자메뉴 > 업체관리 > 대리점(센터)관리
  centerStore: createCenterStore(rootStore),

  // 관리자메뉴 > 직원관리
  employeeManageStore: createEmployeeManageStore(rootStore),

  // 관리자메뉴 > 배정관리 > 지역배정
  areaStore: createAreaStore(rootStore),
  // 관리자메뉴 > 배정관리 > 품목배정
  productStore: createProductStore(rootStore),

  // 관리자메뉴 > 알림관리 > SMS발송관리
  smsAlarmStore: createSmsAlarmStore(rootStore),
  // 관리자메뉴 > 알림관리 > PUSH알림관리
  pushAlarmStore: createPushAlarmStore(rootStore),

  // 관리자메뉴 > 사용자유형 권한관리
  authorityStore: createAuthorityStore(rootStore),
  // 관리자메뉴 > 권한관리
  authoritySystemStore: createAuthoritySystemStore(rootStore),

  // 관리자메뉴 > 메뉴관리 > 메뉴등록관리
  menuStore: createMenuStore(rootStore),
  // 관리자메뉴 > 메뉴관리 > 생성형게시판추가
  generateBoardStore: createGenerateBoardStore(rootStore),
});
const StoreContext = React.createContext();

// eslint-disable-next-line react/prop-types
export function StoreProvider({ children }) {
  return (
    <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
  );
}

export function useMobxStores() {
  return React.useContext(StoreContext);
}
