import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

const DASHBOARD_URL = '/api/main';

export const initial = {
  summary: {
    // 상단 summary
    completedMonthCount: 0, // 처리완료
    receivedTodayCount: 0, // 금일접수건
    cumulativePendingCasesCount: 0, // 누적미결건
  },

  noticeList: [],

  contact: {
    ctRate: 0,
    contactCount: 0,
    unContactRate: 0,
    unContactCount: 0,
  },

  todayScheduleList: {
    count : 0,
    data : [],
  },

  todaySummaryList: [],

  todaySummaryPageOptions: {
    sortField: 1,
    sortOrder: 2,
  },
};

export function createDashboardStore(rootStore) {
  return makeAutoObservable({
    summary: initial.summary,
    noticeList: initial.noticeList,
    contact: initial.contact,
    todayScheduleList: initial.todayScheduleList,
    todaySummaryList: initial.todaySummaryList,
    todaySummaryPageOptions: initial.todaySummaryPageOptions,

    alertMessage: '',

    async getSummary() {
      try {
        const { data } = await authApi.get(
          `${DASHBOARD_URL}/statistics/summary`,
          null,
          null,
        );

        runInAction(() => {
          this.summary = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getNoticeList() {
      try {
        const { data } = await authApi.get(
          `${DASHBOARD_URL}/notice`,
          null,
          null,
        );

        runInAction(() => {
          this.noticeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getContact() {
      try {
        const { data } = await authApi.get(
          `${DASHBOARD_URL}/statistics/contact`,
          null,
          null,
        );

        runInAction(() => {
          this.contact = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getTodayScheduleList() {
      try {
        const { data } = await authApi.get(
          `${DASHBOARD_URL}/schedule/today`,
          null,
          null,
        );
        runInAction(() => {
          this.todayScheduleList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getTodaySummaryList() {
      try {
        const { data } = await authApi.get(
          `${DASHBOARD_URL}/statistics/summary/today`,
          { ...this.todaySummaryPageOptions },
          null,
        );

        runInAction(() => {
          this.todaySummaryList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    setTodaySummaryPageOptions(value, init) {
      runInAction(() => {
        this.todaySummaryPageOptions = init
          ? {
              ...initial.todaySummaryPageOptions,
              ...value,
            }
          : {
              ...this.todaySummaryPageOptions,
              ...value,
            };
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
