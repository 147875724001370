import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';
import { onlyNumber } from '@helpers/StringHelper';

const ENTERPRISE_CUSTOMER_URL = '/api/enterprise/customer';

export const initial = {
  customerList: {
    data: [],
    pageable: {
      totalCount: 1,
      pageNumber: 1, // 페이지 번호
      pageSize: 10,
      totalPage: 1,
    },
  },
  customer: {
    enterpriseSeq: '', // 업체 번호
    enterpriseSe: '', // 업체 구분 (1:센터, 2:고객사)
    enterpriseNm: '', // 업체명
    rprsntvNm: '', // 대표자명
    centerHdchMemberSeq: '', // 센터소장 회원번호
    bizrno: '', // 사업자 등록 번호
    jurirno: '', // 법인 등록 번호
    ctmmnySe: [], // 고객사 구분(1:고객사, 2:제조사, 3:공급처)
    ctmmnySeList: [], // 고객사 구분(1:고객사, 2:제조사, 3:공급처)
    centerSe: '', // 센터 구분(1:직영센터, 2:발주처센터)
    rprsntvEmail: '', // 대표자 이메일
    zip: '', // 우편번호
    adres: '', // 주소
    detailAdres: '', // 상세주소
    signguCode: '',
    dlvyPaprZip: '', // 배송지 우편번호
    dlvyPaprAdres: '', // 배송지 주소
    dlvyPaprDetailAdres: '', // 배송지 상세 주소
    dlvyPaprSignguCode: '',
    telno: '', // 전화번호
    fxNum: '', // 팩스번호
    chargerNm: '', // 담당자명
    chargerCttpc: '', // 담당자 연락처
    estblDt: '', // 개설 날짜
    endDt: '', // 종료 날짜
    partclrMatter: '', // 특이사항
    brandCodeList: [], // 브랜드
    evlScore: '', // 평가점수
  },
};

export const customerValidationSchema = yup.object({
  enterpriseNm: yup.string().required('고객사명은 필수 값 입니다.'),
  rprsntvNm: yup.string().required('대표자명은 필수 값 입니다.'),
  bizrno: yup.string().required('사업자번호는 필수 값 입니다.'),
  jurirno: yup.string().required('법인번호는 필수 값 입니다.'),
  // ctmmnySe: yup.string().required('고객사 구분 값은 필수 값 입니다.'),
  ctmmnySeList: yup.array().min(1, '고객사 구분 필수 선택 값 입니다.'),
  rprsntvEmail: yup
    .string()
    .matches(
      /^[0-9A-Z]([-_\.]?[0-9A-Z])*@[0-9A-Z]([-_\.]?[0-9A-Z])*\.[A-Z]{2,6}$/i,
      '이메일 형식을 확인해주세요',
    )
    .nullable(),
  zip: yup.string().required('주소는 필수 값 입니다.'),
  adres: yup.string().required('주소는 필수 값 입니다.'),
  detailAdres: yup.string().required('주소는 필수 값 입니다.'),
  // btrps: yup.string().test({
  //   message: '출장비는 필수 입력 값 입니다.',
  //   test: (value) => {
  //     const btrps = onlyNumber(value?.replaceAll(',', ''));
  //     if (!btrps) {
  //       return false;
  //     }
  //     return true;
  //   },
  // }),
  brandCodeList: yup.array().min(1, '브랜드는 필수 선택 값 입니다.'),
});

export function createCustomerStore(rootStore) {
  return makeAutoObservable({
    customer: initial.customer,
    customerList: initial.customerList,

    brandCodeList: [],

    alertMessage: '',

    async getCustomerList(options) {
      try {
        const res = await authApi.get(
          ENTERPRISE_CUSTOMER_URL,
          {
            ...options,
          },
          null,
        );
        runInAction(() => {
          this.customerList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async getCustomer(enterpriseSeq) {
      try {
        const { data } = await authApi.get(
          `${ENTERPRISE_CUSTOMER_URL}/${enterpriseSeq}`,
          null,
          null,
        );
        runInAction(() => {
          // this.customer = data;
          this.customer = {
            ...data,
            ctmmnySeList: data.ctmmnySe.split(','),
            brandCodeList: data.brandList.map((item) => item.brandCodeSeq),
          };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateCustomer(enterpriseSeq, values, file, deleteFileSeq) {
      try {
        await authApi.put(
          `${ENTERPRISE_CUSTOMER_URL}/${enterpriseSeq}`,
          values,
        );

        // 파일 있을 경우 업로드
        if (file.bizFile || file.contractFile) {
          await this.customerFileUpload(enterpriseSeq, file);
        }

        if (deleteFileSeq.length > 0) {
          await this.fileDelete(deleteFileSeq);
        }

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteCustomer(enterpriseSeq) {
      try {
        await authApi.delete(
          `${ENTERPRISE_CUSTOMER_URL}/${enterpriseSeq}`,
          null,
        );
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveCustomer(customer, file) {
      try {
        const { data: enterpriseSeq } = await authApi.post(
          ENTERPRISE_CUSTOMER_URL,
          customer,
        );

        // 파일 있을 경우 업로드
        if (file.bizFile || file.contractFile) {
          await this.customerFileUpload(enterpriseSeq, file);
        }
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getBrandCodeList() {
      try {
        const { data } = await authApi.get(
          `${ENTERPRISE_CUSTOMER_URL}/support/brandCodeList`,
          null,
          null,
        );
        runInAction(() => {
          this.brandCodeList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.brandCodeList = [];
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async customerFileUpload(enterpriseSeq, params) {
      try {
        const formData = new FormData();
        if (params.bizFile && !params.bizFile.atchmnflSeqs) {
          formData.append('file1', params.bizFile);
        }
        if (params.contractFile && !params.contractFile.atchmnflSeqs) {
          formData.append('file2', params.contractFile);
        }
        await authApi.fileUpload(
          `${ENTERPRISE_CUSTOMER_URL}/${enterpriseSeq}/atchmnfl`,
          formData,
        );
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async fileDelete(params) {
      await authApi.delete(`${ENTERPRISE_CUSTOMER_URL}/atchmnfl`, params);
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
