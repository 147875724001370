import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';

import * as yup from 'yup';

const GENERATIVE_BBS_URL = '/api/generative-bbs/post';

export const initial = {
  generativeBbsInfo: null,
  generativeBbsList: {
    generativeBbs: null,
    feed: [],
    general: [],
    pageable: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
    },
  },

  generalBbs: {
    generativeBbsPostSeq: null,
    subject: null,
    content: null,
    regDt: null,
    isEditable: false,
    atchmnfls: [],
    comments: [],
  },
};

export const generativeBbsValidationSchema = yup.object({});

export function createGenerativeBbsStore(rootStore) {
  return makeAutoObservable({
    generativeBbsInfo: initial.generativeBbsInfo,

    generativeBbsList: initial.generativeBbsList,

    generalBbs: initial.generalBbs,

    alertMessage: '',

    async getGenerativeBbsInfo(generativeBbsSeq) {
      try {
        const { data } = await authApi.get(
          `/api/generative-bbs/${generativeBbsSeq}`,
          null,
          null,
        );
        runInAction(() => {
          this.generativeBbsInfo = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCommonGenerativeBbsList(generativeBbsSeq, options) {
      try {
        const { data } = await authApi.get(
          `${GENERATIVE_BBS_URL}/${generativeBbsSeq}`,
          { ...options },
          null,
        );
        runInAction(() => {
          this.generativeBbsList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 생성형게시판(일반형) 게시글 생성
    async saveGenerativeBbsGeneral(
      generativeBbsSeq,
      { content, subject },
      files,
    ) {
      try {
        const { data } = await authApi.post(
          `${GENERATIVE_BBS_URL}/general/${generativeBbsSeq}`,
          { subject, content },
        );

        if (files.length > 0) {
          await this.generativeBbsGeneralFileUpload(
            data.generativeBbsPostSeq,
            files,
          );
        }

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 생성형게시판(피드형) 게시글 생성
    async saveGenerativeBbsFeed(generativeBbsSeq, content) {
      try {
        await authApi.post(`${GENERATIVE_BBS_URL}/feed/${generativeBbsSeq}`, {
          content,
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    //  생성형 게시판(공용) 게시글 삭제
    async deleteGenerativeBbs(generativeBbsPostSeq) {
      try {
        await authApi.delete(
          `${GENERATIVE_BBS_URL}/${generativeBbsPostSeq}`,
          null,
        );
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 생성형 게시판(공용) 댓글 생성
    async saveCommonGenerativeBbsComment(generativeBbsPostSeq, content) {
      try {
        await authApi.post(
          `${GENERATIVE_BBS_URL}/${generativeBbsPostSeq}/comments`,
          {
            content,
          },
        );
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async deleteCommonGenerativeBbsComment(
      generativeBbsPostSeq,
      generativeBbsCommentSeq,
    ) {
      try {
        await authApi.delete(
          `${GENERATIVE_BBS_URL}/comments/${generativeBbsCommentSeq}`,
          null,
        );
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 생성형게시판(피드형) 게시글 좋아요
    async likeGenerativeBbsFeed(generativeBbsPostSeq) {
      try {
        await authApi.post(
          `${GENERATIVE_BBS_URL}/${generativeBbsPostSeq}/like`,
          null,
        );
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 생성형게시판(피드형) 댓글 좋아요
    async likeCommentGenerativeBbsFeed(
      generativeBbsPostSeq,
      generativeBbsCommentSeq,
    ) {
      try {
        await authApi.post(
          `${GENERATIVE_BBS_URL}/${generativeBbsPostSeq}/comments/${generativeBbsCommentSeq}/like`,
          null,
        );
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 생성형 게시판(일반형) 상세
    async getGenerativeBbsGeneral(generativeBbsSeq) {
      try {
        const { data } = await authApi.get(
          `${GENERATIVE_BBS_URL}/general/${generativeBbsSeq}`,
          null,
          null,
        );

        runInAction(() => {
          this.generalBbs = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 생성형게시판(일반형) 수정
    async updateGenerativeBbsGeneral(
      generativeBbsPostSeq,
      { subject, content },
      bbsFiles,
      deleteFileSeq,
    ) {
      try {
        await authApi.patch(
          `${GENERATIVE_BBS_URL}/general/${generativeBbsPostSeq}`,
          { subject, content },
        );

        if (bbsFiles.length > 0) {
          await this.generativeBbsGeneralFileUpload(
            generativeBbsPostSeq,
            bbsFiles,
          );
        }

        if (deleteFileSeq.length > 0) {
          await this.generativeBbsGeneralFileDelete(
            generativeBbsPostSeq,
            deleteFileSeq,
          );
        }

        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 생성형게시판(일반형) 첨부파일 업로드
    async generativeBbsGeneralFileUpload(generativeBbsPostSeq, files) {
      const formData = new FormData();
      files.map((file) => {
        formData.append('multipartFiles', file);
      });

      await authApi.fileUpload(
        `${GENERATIVE_BBS_URL}/general/${generativeBbsPostSeq}/atchmnfl`,
        formData,
      );
    },

    async generativeBbsGeneralFileDelete(generativeBbsPostSeq, deleteFileSeq) {
      await authApi.delete(
        `${GENERATIVE_BBS_URL}/general/${generativeBbsPostSeq}/atchmnfl`,
        {
          atchmnflSeqs: deleteFileSeq,
        },
      );
      return true;
    },

    setGeneralBbs(bbs) {
      runInAction(() => {
        this.generalBbs = bbs;
      });
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;

      runInAction(() => {
        this.alertMessage = '';
      });

      return alertMessage;
    },
  });
}
