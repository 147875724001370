import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';
import { numberStringWithComma } from '@helpers/StringHelper';

const MODEL_URL = '/api/materialcode/model';

export const initial = {
  modelList: {
    data: [],
    pageable: {
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 1,
    },
  },
  model: {
    actvtySe: '',
    atchmnfl: {
      fileLink: '',
      orginlFileNm: '',
    },
    brandCodeNm: '',
    brandCodeSeq: '',
    categoryCodeNm: '',
    categoryCodeSeq: '',
    btrps: 0,
    suplyUntpc: 0,
    cnsmrPc: 0,
    instlUntpc: 0,
    asPc: 0,
    enterpriseNm: '',
    enterpriseSeq: '',
    goodsSleSe: '',
    itemSeq: '',
    modelNm: '',
    partclrMatter: '',
    prdNm: '',
    cmtDt: '',
    assrncPd: '',
    works: [],
    workList: [],
    adits: [],
    aditList: [],
    defectSymptmss: [],
    defectSymptmsList: [],
  },
};

export const modelValidationSchema = yup.object({
  // 모델명, 제품명, 브랜드, 카테고리, 공급처, 공급가, 소비자가
  modelNm: yup.string().required('모델 명은 필수 값 입니다.'),
  prdNm: yup.string().required('제품 명은 필수 값 입니다.'),
  brandCodeSeq: yup.string().required('브랜드는 필수 선택 값 입니다.'),
  categoryCodeSeq: yup.string().required('카테고리는 필수 선택 값 입니다.'),
  enterpriseSeq: yup.string().required('공급처는 필수 선택 값 입니다.'),
  // suplyUntpc: yup.number().min(1, '공급가는 필수 값 입니다.').required('공급가는 필수 값 입니다.'),
  // cnsmrPc: yup.string().required('소비자가는 필수 값 입니다.'),
  // works: yup.array().min(1, '공임비 필수 선택 값 입니다.'),
  // adits: yup.array().min(1, '추가비용은 필수 선택 값 입니다.'),
  // defectSymptmss: yup.array().min(1, '고장증상은 필수 선택 값 입니다.'),
});

export function createModelStore(rootStore) {
  return makeAutoObservable({
    modelList: initial.modelList,
    model: initial.model,

    // 코드
    brandList: [],
    categoryList: [],
    supplierList: [],
    workList: [],
    aditList: [],
    defectSymptmsList: [],

    alertMessage: '',

    async getModelList(options) {
      try {
        const res = await authApi.get(MODEL_URL, { ...options }, null);

        runInAction(() => {
          this.modelList = res;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async saveModel(params, file) {
      try {
        const {
          data: { itemSeq },
        } = await authApi.post(MODEL_URL, params);

        if (file) {
          await this.modelFileUpload(itemSeq, file);
        }
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getModel(itemSeq) {
      try {
        const { data } = await authApi.get(
          `${MODEL_URL}/${itemSeq}`,
          null,
          null,
        );
        runInAction(() => {
          this.model = {
            ...data,
            works: data.workList?.map((code) => code.codeSeq) || [],
            adits: data.aditList?.map((code) => code.codeSeq) || [],
            defectSymptmss:
              data.defectSymptmsList?.map((code) => code.codeSeq) || [],
          };
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async updateModel(itemSeq, params, file) {
      try {
        await authApi.patch(`${MODEL_URL}/${itemSeq}`, params);

        if (file) {
          await this.modelFileUpload(itemSeq, file);
        }
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async deleteModel(itemSeq) {
      try {
        await authApi.delete(`${MODEL_URL}/${itemSeq}`, null);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async modelFileUpload(itemSeq, params) {
      try {
        const formData = new FormData();
        formData.append('multipartFile', params);
        await authApi.fileUpload(`${MODEL_URL}/${itemSeq}/image`, formData);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getBrandList() {
      try {
        const { data } = await authApi.get(
          `${MODEL_URL}/support/brand`,
          null,
          null,
        );
        runInAction(() => {
          this.brandList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getCategoryList() {
      try {
        const { data } = await authApi.get(
          `${MODEL_URL}/support/category`,
          null,
          null,
        );
        runInAction(() => {
          this.categoryList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getSupplierList() {
      try {
        const { data } = await authApi.get(
          `${MODEL_URL}/support/supplier`,
          null,
          null,
        );
        runInAction(() => {
          this.supplierList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getWorkList() {
      try {
        const { data } = await authApi.get(
          `${MODEL_URL}/support/work`,
          null,
          null,
        );
        runInAction(() => {
          this.workList = data
            ?.filter((code) => !isNaN(parseInt(code.etcCn)))
            ?.map((code) => ({
              ...code,
              codeNm: `${code.codeNm}(${
                numberStringWithComma(code.etcCn) || 0
              }원)`,
            }));
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getAditList() {
      try {
        const { data } = await authApi.get(
          `${MODEL_URL}/support/adit`,
          null,
          null,
        );
        runInAction(() => {
          this.aditList = data
            ?.filter((code) => !isNaN(parseInt(code.etcCn)))
            ?.map((code) => ({
              ...code,
              codeNm: `${code.codeNm}(${
                numberStringWithComma(code.etcCn) || 0
              }원)`,
            }));
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async getDefectSymptmsList() {
      try {
        const { data } = await authApi.get(
          `${MODEL_URL}/support/defectSymptms`,
          null,
          null,
        );
        runInAction(() => {
          this.defectSymptmsList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    async uploadModelExcel(excelFile) {
      try {
        const formData = new FormData();
        formData.append('file', excelFile);

        await authApi.fileUploadPost(`${MODEL_URL}/excel-upload`, formData);
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
