import { lazy } from 'react';

/**
 * Layout 목록
 */
const MainLayout = lazy(() =>
  import(/* webpackChunkName: "MinimalLayout" */ '@templates/MainLayout'),
);

const TabLayout = lazy(() =>
  import(/* webpackChunkName: "TabLayout" */ '@templates/TabLayout'),
);

/**
 * Routing Page 목록
 */

// 공지사항
// 리스트
const NoticeListPage = lazy(() =>
  import(
    /* webpackChunkName: "NoticeListPage" */ '@pages/user/community/notice/NoticeListPage'
  ),
);

// 등록/수정
const NoticeFormPage = lazy(() =>
  import(
    /* webpackChunkName: "NoticeFormPage" */ '@pages/user/community/notice/NoticeFormPage'
  ),
);
// 보기
const NoticeViewPage = lazy(() =>
  import(
    /* webpackChunkName: "NoticeViewPage" */ '@pages/user/community/notice/NoticeViewPage'
  ),
);
// 자료실
// 리스트
const ReferenceListPage = lazy(() =>
  import(
    /* webpackChunkName: "ReferenceListPage" */ 'src/pages/user/community/reference'
  ),
);

// 등록/수정
const ReferenceFormPage = lazy(() =>
  import(
    /* webpackChunkName: "ReferenceFormPage" */ '@pages/user/community/reference/ReferenceFormPage'
  ),
);
// 보기
const ReferenceViewPage = lazy(() =>
  import(
    /* webpackChunkName: "ReferenceViewPage" */ '@pages/user/community/reference/ReferenceViewPage'
  ),
);

// 서비스교육
// 리스트
const ServiceEducationListPage = lazy(() =>
  import(
    /* webpackChunkName: "ServiceEducationListPage" */ '@pages/user/community/service-education'
  ),
);
// 등록/수정
const ServiceEducationFormPage = lazy(() =>
  import(
    /* webpackChunkName: "ServiceEducationFormPage" */ '@pages/user/community/service-education/ServiceEducationFormPage'
  ),
);
// 보기
const ServiceEducationViewPage = lazy(() =>
  import(
    /* webpackChunkName: "ServiceEducationViewPage" */ '@pages/user/community/service-education/ServiceEducationViewPage'
  ),
);
// 제품 메뉴얼
// 리스트
const ProductManualListPage = lazy(() =>
  import(
    /* webpackChunkName: "ProductManualListPage" */ '@pages/user/community/product-manual'
  ),
);

// 등록
const ProductManualFormPage = lazy(() =>
  import(
    /* webpackChunkName: "ProductManualFormPage" */ '@pages/user/community/product-manual/ProductManualFormPage'
  ),
);

// FAQ-자주묻는질문
// 리스트
const FaqTabPage = lazy(() =>
  import(/* webpackChunkName: "FaqTabPage" */ '@pages/user/community/faq/faq'),
);
// 등록
const FaqFormPage = lazy(() =>
  import(
    /* webpackChunkName: "FaqFormPage" */ '@pages/user/community/faq/faq/FaqFormPage'
  ),
);

// 리스트
const QnaListPage = lazy(() =>
  import(/* webpackChunkName: "QnaListPage" */ '@pages/user/community/qna'),
);

// 등록/수정
const QnaFormPage = lazy(() =>
  import(
    /* webpackChunkName: "QnaFormPage" */ '@pages/user/community/qna/QnaFormPage'
  ),
);
// 보기
const QnaViewPage = lazy(() =>
  import(
    /* webpackChunkName: "QnaViewPage" */ '@pages/user/community/qna/QnaViewPage'
  ),
);

const getRoutes = () => {
  const routes = [
    {
      name: '공지사항 리스트',
      path: '/community/notice',
      component: NoticeListPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '공지사항 등록',
      path: '/community/notice/form',
      component: NoticeFormPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '공지사항 수정',
      path: '/community/notice/form/:bbsSeq',
      component: NoticeFormPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '공지사항 보기',
      path: '/community/notice/view/:bbsSeq',
      component: NoticeViewPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },

    {
      name: '자료실 리스트',
      path: '/community/reference',
      component: ReferenceListPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '자료실 등록',
      path: '/community/reference/form',
      component: ReferenceFormPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '자료실 수정',
      path: '/community/reference/form/:bbsSeq',
      component: ReferenceFormPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '자료실 보기',
      path: '/community/reference/view/:bbsSeq',
      component: ReferenceViewPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },

    {
      name: '서비스교육 리스트',
      path: '/community/service-education/:serviceEduType',
      component: ServiceEducationListPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '서비스교육 등록',
      path: '/community/service-education/:serviceEduType/form',
      component: ServiceEducationFormPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '서비스교육 수정',
      path: '/community/service-education/:serviceEduType/form/:bbsSeq',
      component: ServiceEducationFormPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },

    {
      name: '서비스교육 보기',
      path: '/community/service-education/:serviceEduType/view/:bbsSeq',
      component: ServiceEducationViewPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },

    {
      name: '제품메뉴얼 리스트',
      path: '/community/product-manual',
      component: ProductManualListPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },

    {
      name: '제품메뉴얼 등록',
      path: '/community/product-manual/form',
      component: ProductManualFormPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '제품메뉴얼 수정',
      path: '/community/product-manual/form/:bbsSeq',
      component: ProductManualFormPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '자주하는질문 리스트',
      path: '/community/faq/:faqType',
      component: FaqTabPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '자주하는질문 등록',
      path: '/community/faq/:faqType/form',
      component: FaqFormPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '자주하는질문 수정',
      path: '/community/faq/:faqType/form/:bbsSeq',
      component: FaqFormPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '문의하기 리스트',
      path: '/community/qna',
      component: QnaListPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '문의하기 등록',
      path: '/community/qna/form',
      component: QnaFormPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
    {
      name: '문의하기 수정',
      path: '/community/qna/form/:bbsSeq',
      component: QnaFormPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },

    {
      name: '문의하기 보기',
      path: '/community/qna/view/:bbsSeq',
      component: QnaViewPage,
      layout: MainLayout,
      isAuth: true,
      isPc: true,
      isMobile: true,
      isBookmarked: true,
      permissions: [],
    },
  ];

  return routes;
};

export default getRoutes();
