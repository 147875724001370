import { makeAutoObservable, runInAction } from 'mobx';
import { defaultApi } from '@helpers/AxiosHelper';

export function createForgotAccountStore(rootStore) {
  return makeAutoObservable({
    findIdData: null,
    findPasswordData: null,

    alertMessage: '',

    async findId({ nm, moblPhonNo }) {
      try {
        const { data } = await defaultApi.post('/api/login/find-id', {
          nm,
          moblPhonNo,
        });

        runInAction(() => {
          this.findIdData = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async findPassword({ nm, id }) {
      try {
        const { data } = await defaultApi.post('/api/login/find-password', {
          id,
          nm,
        });

        runInAction(() => {
          this.findPasswordData = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
