import { makeAutoObservable, runInAction } from 'mobx';
import { defaultApi } from '@helpers/AxiosHelper';
import * as yup from 'yup';
import { onlyNumber } from '@helpers/StringHelper';
import { MEMBER_ID_REGEX, MEMBER_PASSWORD_REGEX } from '@const/env';

const SIGNUP_URL = '/api/login/member';

export const initial = {
  member: {
    id: null,
    password: null,
    passwordConfirm: null,
    nm: null,
    moblphonNo: null,
    email: null,
    enterpriseSeq: null,
    zip: null,
    adres: null,
    detailAdres: null,
    legaldongCode: null,
    signguCode: null,
  },
};

export const signupValidationSchema = yup.object({
  // 아이디, 비밀번호, 성명, 핸드폰번호 소속센터, 소속팀, 담당업무,
  id: yup
    .string()
    .matches(
      MEMBER_ID_REGEX,
      '아이디는 영어, 숫자 조합 6자리 이상이어야 합니다.',
    )
    .required('아이디는 필수 입력 값 입니다.'),
  password: yup
    .string()
    .matches(
      MEMBER_PASSWORD_REGEX,
      '비밀번호는 특수문자/문자/숫자 포함 형태의 8~15자리 이어야 합니다.',
    )
    .oneOf([yup.ref('passwordConfirm'), null], '비밀번호를 다시 확인해주세요.')
    .required('비밀번호는 필수 값 입니다.'),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], '비밀번호를 다시 확인해주세요.')
    .required('비밀번호 확인은 필수 값 입니다.'),
  nm: yup.string().required('성명은 필수 입력 값 입니다.'),
  moblphonNo: yup
    .string()
    .test({
      message: '핸드폰 번호를 확인해주세요.',
      test: (value) => {
        if (!onlyNumber(value.replaceAll('-', ''))) {
          return false;
        }
        return true;
      },
    })
    .required('핸드폰 번호는 필수 입력 값입니다.'),
  email: yup
    .string()
    .matches(
      /^[0-9A-Z]([-_\.]?[0-9A-Z])*@[0-9A-Z]([-_\.]?[0-9A-Z])*\.[A-Z]{2,6}$/i,
      '이메일 형식을 확인해주세요',
    )
    .nullable(),
  enterpriseSeq: yup.number().required('소속센터는 필수 선택 값 입니다.'),
});

export function createSignupStore(rootStore) {
  return makeAutoObservable({
    member: initial.member,
    // 소속센터
    enterpriseList: [],
    // 소속팀
    psitnTeamList: [],
    // 담당업무
    chrgJobList: [],
    // 직급
    clsfList: [],
    // 직책
    rspofcList: [],

    alerMessage: '',

    // 아이디 중복 체크
    async getSearchId(id) {
      try {
        const res = await defaultApi.post(`${SIGNUP_URL}/search-id`, { id });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    // 회원가입
    async signup(params) {
      try {
        await defaultApi.post(SIGNUP_URL, { ...params });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },
    async getEnterpriseList() {
      try {
        const { data } = await defaultApi.get(
          `${SIGNUP_URL}/support/enterprise`,
          null,
          null,
        );
        runInAction(() => {
          this.enterpriseList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        runInAction(() => {
          this.alertMessage = alertMessage;
        });
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
