import { lazy } from 'react';

/**
 * Layout 목록
 */
const TabLayout = lazy(() =>
  import(/* webpackChunkName: "TabLayout" */ '@templates/TabLayout'),
);

/**
 * Routing Page 목록
 */

// 알림관리 > SMS발송관리
const AlarmManagementPage = lazy(() =>
  import(
    /* webpackChunkName: "AlarmManagementPage" */ '@pages/admin/alarm/sms'
  ),
);

// 알림관리 > PUSH발송관리
const PushManagementPage = lazy(() =>
  import(
    /* webpackChunkName: "PushManagementPage" */ '@pages/admin/alarm/push'
  ),
);

const getRoutes = () => {
  const routes = [
    {
      name: 'SMS발송관리',
      path: '/alarm/sms',
      component: AlarmManagementPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },

    {
      name: 'PUSH발송관리',
      path: '/alarm/push',
      component: PushManagementPage,
      layout: TabLayout,
      isAuth: true,
      isPc: true,
      isMobile: false,
      isBookmarked: true,
      permissions: [],
    },
  ];

  return routes;
};

export default getRoutes();
